<template>
  <div>
    <LensItemsPageHeader :imageName="'report'" :page="'Reports PDF'" @refech-all="reload" />
    <div class="container px-2 py-4 mx-auto">
      <StorageListTable :tableData="reportList" @tableData:download-file="downloadFile($event)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import StorageListTable from "@core/tables/StorageListTable.vue";
import { onMounted, reactive, computed, onUnmounted, ref } from "vue";

import { useFileStorageStore } from "@modules/admin/store/fileStorageStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";

import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import { useRouter } from "vue-router";
import { sleep } from "@/@use/libs/helpers";
import { FileConfig } from "@/index";

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const file = useFileStorageStore();

const folder = import.meta.env.VITE_CONTAINER_REPORTS_BURSTS || "reports-bursts";

const locationId = computed(() => router.currentRoute.value.params.locationId);
const lensItemId = computed(() => lens.selectedLensItems?.lens_id);
const cosmosId = computed(() => router.currentRoute.value.params.cosmosId);
const moduleId = computed(() => router.currentRoute.value.params.module);

const fileConfig = reactive({
  id: "",
  activation_date: "",
  clientName: "",
  appType: "",
  locationPoint: `${locationId.value}/${locationId.value}-${moduleId.value}`,
  folder: folder,
}) as FileConfig;

const reportList = computed(() => {
  return file.reports
});

/**
 * Définit la configuration du fichier.
 * Cette fonction récupère les informations nécessaires à partir des groupes de lens et les assigne à la configuration du fichier.
 * Les étapes de la fonction sont les suivantes :
 * 1. Récupérer tous les groupes de lens pour l'utilisateur.
 * 2. Définir toutes les lens disponibles.
 * 3. Assigner l'ID de la lens sélectionnée à la configuration du fichier.
 * 4. Assigner la date d'activation dulensGroups sélectionné à la configuration du fichier.
 * 5. Assigner le nom du client (tenant_id) en minuscules à la configuration du fichier.
 * 6. Assigner le type d'application dulensGroups sélectionné à la configuration du fichier.
 *
 * @returns {Promise<void>} Une Promise résolue une fois que la configuration du fichier est définie.
 */
const setFileConfig = async () => {
  await lensGroup.fetchUserAllLensGroup();
  lens.setAllLens();
  fileConfig.id = lens.selectedLensItems?.lens_id as string;
  fileConfig.activation_date = lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime as string;
  fileConfig.clientName = lensGroup.selectedLensGroup?.lensGroup_data.metadata.tenant_id?.toLowerCase() as string;
  fileConfig.appType = lensGroup.selectedLensGroup?.lensGroup_data.metadata.application_type as string;
};

const reload = async () => {
  /**
   * Cette fonction est utilisée dans le fichier BurstsReports.vue pour effectuer une série d'étapes.
   * Elle effectue les actions suivantes :
   * 1. Appelle la fonction setFileConfig() de manière asynchrone.
   * 2. Met à jour les éléments sélectionnés de la lens en utilisant les valeurs de cosmosId et locationId.
   * 3. Met à jour les groupes de lens sélectionnés en utilisant la valeur de locationId.
   * 4. Vérifie si les éléments de lens sélectionnés et lelensGroups sélectionné existent.
   * 5. Attend pendant 200 millisecondes.
   * 6. Met à jour l'ID de configuration de fichier avec la valeur de lensItemId.
   * 7. Appelle la fonction fetchLensReports() de manière asynchrone pour récupérer les rapports de lens.
   *
   * @returns {Promise<void>} Une Promise qui se résout lorsque toutes les étapes sont terminées.
   */
  await setFileConfig();
  lens.setSelectedLensItems({ cosmosId: cosmosId.value, locationId: locationId.value });
  lensGroup.setSelectedLensGroups(locationId.value as string);
  if (lens.selectedLensItems && lensGroup.selectedLensGroup) {
    sleep(200);
    fileConfig.id = lensItemId.value as string;
    await file.fetchLensReports(fileConfig);
  }
};

const downloadFile = async ($event: any) => {
  await file.fetchFileItem($event);
};

onMounted(async () => {
  await reload();
  if (fileConfig?.id) {
    await file.fetchLensReports(fileConfig);
  }
});

onUnmounted(() => lens.clear());
</script>

<style scoped></style>
