import * as _ from "lodash";

import { setformType, titleCase, valueOrNone } from "./helpers";

import { FieldObject } from '@/index';
import { v4 as uuidv4 } from "uuid";

// transforme un objet brute en element de type InputField pouvant être directement utilisé
/**
 * Crée un tableau de champs d'entrée à partir d'un objet clé-valeur.
 *
 * @param obj - L'objet à partir duquel créer les champs d'entrée.
 * @param isEmpty - Indique si les valeurs des champs doivent être vides.
 * @returns Un tableau d'objets représentant les champs d'entrée.
 */
export const createInputFieldFromObjectKeyValue = (
  obj: Object | Partial<Object>,
  isEmpty = false
): FieldObject[]=> {
  let inputFields = [];
  for (const [key, value] of Object.entries(obj)) {
    const val = isEmpty === true ? valueOrNone(null) : valueOrNone(value);

    inputFields.push({
      id: uuidv4(),
      key: key,
      value: val,
      title: titleCase(key),
      type: setformType(value),
    });
  }

  return inputFields;
};

/**
 * Crée un champ de saisie à partir d'un objet en sélectionnant les clés spécifiées.
 *
 * @param obj - L'objet source à partir duquel créer les champs de saisie.
 * @param selections - Les clés à sélectionner de l'objet source.
 * @returns Un tableau d'objets FieldObject représentant les champs de saisie créés.
 */
export const createInputFieldFromObjectByPickKeyValue = (
  obj: Object,
  selections: string[] | any
): FieldObject[] => {
  const newObj = _.pick(obj, selections);
  return createInputFieldFromObjectKeyValue(newObj);
};

export const createEmptyInputFieldFromObjectByPickKeyValue = createInputFieldFromObjectByPickKeyValue;
