import { Ref, computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { LensGroup } from "@/index";
import { defineStore } from "pinia";
import entityStateManager from "@use/entity";
import { helpers } from "@use/libs/helpers";
import { splitDs } from "@core/charts/dataFormatters/fnUtils";
import { useAPI } from "@use/useAPI";
import { useAuthStore } from "@modules/auth/authStore";
import { useLensStore } from "./lensStore";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { useUiStore } from "@/@use/uiStore";

const settingRoutes = [`admin-lens-settings-recipient-id`, `admin-lens-settings-lens-group-id`, `admin-lens-settings-planning-id`];

export const useLensGroupStore = defineStore(
  "lensGroup",
  () => {
    const auth = useAuthStore();
    const ui = useUiStore();
    const API = useAPI();
    const router = useRouter();
    const route = useRoute();
    const lens = useLensStore();
    const metadata = useMetadataStore();
    const manager = entityStateManager("location_id");

    const lensGroups = ref<LensGroup[]>();
    const allUserLensGroups = ref<LensGroup[]>();
    const selectedLocationId = ref<string | null>();
    const dataEntities = ref(manager.initialState);

    // create a comment
    /**
     * Calcule et retourne toutes les positions des appareils.
     *
     * @returns Un tableau d'objets représentant les positions des appareils.
     * Chaque objet contient les propriétés suivantes :
     *   - name: Le nom dulensGroups.
     *   - description: La description dulensGroups.
     *   - latlong: Les coordonnées latlong dulensGroups sous forme d'un tableau de nombres.
     *   - id: L'identifiant dulensGroups.
     */
    const allDevicePositions = computed(() =>
      lensGroups.value?.map((lg) => {
        return {
          name: lg.lensGroup_name,
          description: lg.lensGroup_description,
          latlong:
            splitDs(lg.lensGroup_latlong)
              // @ts-ignore
              .map((point: string) => parseFloat(point)) || null,
          id: lg.lensGroup_metadata_id,
        };
      })
    );

    /**
     * Représente lelensGroups sélectionné.
     *
     * @remarks
     * Cette propriété est une valeur calculée qui retourne lelensGroups correspondant à l'ID de localisation sélectionné.
     * Si unlensGroups correspondant est trouvé, la métadonnée sélectionnée est mise à jour à l'aide de la fonction setSelectedMetadata.
     *
     * @returns LelensGroups sélectionné.
     */
    const selectedLensGroup = computed({
      get() {
        const res = lensGroups.value?.find((group) => group?.location_id === selectedLocationId.value);
        if (res) {
          metadata.setSelectedMetadata(res);
        }
        return res;
      },
      set(resetValue) {
        // Note: the return value is the final value after edit
        return resetValue;
      },
    });

    const clear = () => {
      selectedLensGroup.value = undefined;
    };

    const setSelectedLensGroups = (locationId: string) => {
      selectedLocationId.value = locationId;
    };

    /**
     * Récupère tous les groupes de lens de l'utilisateur actuel.
     * Cette fonction effectue les étapes suivantes:
     * 1. Active l'état de chargement de l'interface utilisateur.
     * 2. Récupère les informations de l'utilisateur actuel à partir du stockage local.
     * 3. Effectue une requête API pour obtenir les groupes de lens associés à l'ID de l'utilisateur.
     * 4. Met à jour les entités de données avec les valeurs reçues de la requête.
     * 5. Trie les groupes de lens dans l'ordre croissant.
     * 6. Appelle la fonction "setAllLens" pour mettre à jour les lens.
     * 7. Redirige vers la page d'accueil si l'utilisateur n'est pas connecté.
     * 8. Désactive l'état de chargement de l'interface utilisateur.
     */
    const fetchUserAllLensGroup = async () => {
      ui.setLoadingState(true);
      await auth.getMe();

      const _user = localStorage.getItem("user") || false;
      if (_user) {
        const parsedUser = JSON.parse(_user);
        const response = (await API.getData(`lensGroups/byUserId/${parsedUser?.id}`)) as Ref<any>;

        if (response?.value) {
          if (Array.isArray(response.value)) {
            dataEntities.value = manager.setAllEntities(response.value);
            lensGroups.value = helpers.sortAsc(manager.entitiesToArray(dataEntities.value));

            lens.setAllLens();
          }
        }
      } else {
        router?.push({ name: "home" });
      }
      ui.setLoadingState(false);
    };
    const fetchAllUserLensGroup = async () => {
      ui.setLoadingState(true);
      await auth.getMe();

      const _user = localStorage.getItem("user") || false;
      if (_user) {
        const response = (await API.getData(`lensGroups`)) as Ref<any>;
        if (response?.value) {
          if (Array.isArray(response.value)) {
            // Add all metadata
          }
        }
      } else {
        router?.push({ name: "home" });
      }
      ui.setLoadingState(false);
    };

    /**
     * Récupère les groupes de lens du magasin.
     * Si les identifiants des entités sont disponibles et qu'un utilisateur est connecté,
     * les groupes de lens sont triés par ordre croissant et assignés à la variable `lensGroups`.
     * Sinon, la fonction `fetchUserAllLensGroup` est appelée pour récupérer tous les groupes de lens de l'utilisateur.
     */
    const getStoreLensGroup = async () => {
      const _user = localStorage.getItem("user") || false;

      if (dataEntities.value.ids && dataEntities.value.ids.length > 0 && _user) {
        lensGroups.value = helpers.sortAsc(manager.entitiesToArray(dataEntities.value));
      } else {
        await fetchUserAllLensGroup();
      }
    };

    const createFromMetadataLocationPoint = async (locationId: string) => {
      return await API.getData(`lensGroups/createFromMetadataLocationPoint/${locationId}`);
    };

    const generateFromMetadataLocationDocument = async (options: any) => {
      return await API.postData(`lensGroups/generateFromMetadataLocationDocument`, options);
    };

    onMounted(async () => {
      if (route.meta.requiresAuth) {
        await fetchUserAllLensGroup();
      }
    });

    router?.beforeEach(async (to, from, next) => {
      if (to.name === "adminIndex") {
        // lens.clear();
        getStoreLensGroup();
        if (from.name === "admin-lens-settings-recipient-id" || from.name === "admin-lens-settings-lens-group-id") {
          await fetchUserAllLensGroup();
        }
        next();
      }
      if (from.name === "adminIndex") {
        // lens.clear();
        getStoreLensGroup();
        if (to.name === "admin-lens-settings-recipient-id" || to.name === "admin-lens-settings-lens-group-id") {
          await fetchUserAllLensGroup();
        }
        next();
      }
      if (settingRoutes.includes(to.name as string)) {
        setSelectedLensGroups(to.params.locationId as string);

        next();
      }
      if (to.name === "admin-lens-settings-device-id") {
        setSelectedLensGroups(to.params.locationId as string);
        await metadata.fetchSelectedMetadataB2F(to.params.locationId as string);
        next();
      }
      if (to.name === "configuration-metadata") {
        await fetchAllUserLensGroup();
        next();
      } else {
        next();
      }
    });

    return {
      allUserLensGroups,
      lensGroups,
      dataEntities,
      selectedLensGroup,
      setSelectedLensGroups,
      fetchUserAllLensGroup,
      allDevicePositions,
      clear,
      createFromMetadataLocationPoint,
      generateFromMetadataLocationDocument,
    };
  },
  {
    persist: true,
  }
);

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/#getting-started-with-pinia
