<template>
  <div>
    <div v-if="props.chartObj.id">
      <v-chart class="chart" :option="{ ...chartOptions, ...dataSet }" :key="props.chartObj.id" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";

import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { ChartEntity, PhysicalQuantity } from "@/index";
import { burstChartConfig } from "../settings/burstChartSettings";

const metadata = useMetadataStore();

const props = defineProps<{
  chartObj: ChartEntity;
}>();

const physicalQuantity = computed(() => metadata.physicalQuantities);
/**
 * Récupère la quantité physique en fonction du type spécifié.
 *
 * @param {string} type - Le type de la quantité physique (par défaut: "velocity").
 * @returns {PhysicalQuantity | undefined} - La quantité physique correspondante ou undefined si elle n'est pas trouvée.
 */
const getPhysicalByType = (type = "velocity"): PhysicalQuantity | undefined => {
  return physicalQuantity.value?.find((ph: PhysicalQuantity) => ph.type === type);
};

const dataSet = reactive({
  dataset: {
    dimensions: props.chartObj.chartData.columns,
    source: props.chartObj.chartData.rows,
  },
});

/**
 * Retourne les options du graphique en rafale.
 *
 * @returns {Object} Les options du graphique en rafale.
 */
const chartOptions = computed(() => {
  const physicalQ = getPhysicalByType(props.chartObj.chart.physical_quantity);
  if (physicalQ) {
    return burstChartConfig({
      physicalQ,
      keys: props.chartObj.key,
      chart: props.chartObj.chart,
      info: props.chartObj.info,
      type: "burst",
      variables: props.chartObj.variables,
      usePhysicalQuantity: true,
    });
  }
});

//--------------------------------------------------------------------------
// @ts-ignore
import { use } from "echarts/core";
// @ts-ignore
import { CanvasRenderer } from "echarts/renderers";
// @ts-ignore
import {
  BarChart,
  EffectScatterChart,
  LineChart,
  LinesChart,
  MapChart,
  PieChart,
  RadarChart,
  ScatterChart,
  // @ts-ignore
} from "echarts/charts";

// @ts-ignore
import {
  DataZoomComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  PolarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  // @ts-ignore
} from "echarts/components";

// @ts-ignore
import VChart from "vue-echarts";

use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent,
]);
</script>

<style scoped>
.chart {
  height: 650px;
  min-width: 764px;
}
</style>
