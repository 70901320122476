import * as _ from "lodash";

import { LensVariable, MultipleStreamData } from "@/index";

import { formatStatDataForLineMultiplesAxes } from "../formatStatDataForLineMultiplesAxes";
import { genericChartOptionsFormatter } from "./genericChartOptionsFormatter";
import { genericRootVariablesOptionsFormatter } from "./genericVariablesOptionsFormatter";
import { sortStringWithDecimals } from "@/@use/libs/sortStringWithDecimals";

/**
 * Formatte les données pour les types de lignes génériques combinées.
 *
 * @param data Les données à formater.
 * @param customGenericConfig La configuration personnalisée pour les types génériques.
 * @returns Un tableau contenant les données formatées pour chaque type de ligne générique combinée.
 */
export const formatDataForCombinedGenericLineTypes = (data: MultipleStreamData, customGenericConfig: any) => {
  if (!_.isEmpty(data)) {
    const { lens_variables, lens_charts, lens_parameters_notification, ...rest } = data;
    // on boucle sur les chartes pour trouver les élements qui sont groupées

    return lens_charts
      .filter((ch: any) => ch.is_generic === true && ch.is_combined_graph === true)
      .map((chart) => {
        let vcNames = [] as string[];

        const formattedChart = genericChartOptionsFormatter(chart, customGenericConfig);
        vcNames = genericRootVariablesOptionsFormatter(data, customGenericConfig);
        /*
                const formattedChart = genericChartOptionsFormatter(chart, data.data_name, customGenericConfig);
                const formattedLimites = genericNotificationLimitsOptionsFormatter(data, customGenericConfig.customLimites);
                */

        // et on met le tout la fonction de regroupement
        return formatStatDataForLineMultiplesAxes(rest, sortStringWithDecimals(vcNames), {
          variables: lens_variables,
          chart: formattedChart,
          limites: customGenericConfig.customLimits,
        });
      });
  }
};
