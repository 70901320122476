<template>
  <div class="flex flex-col" v-if="rows ">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2  inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-sky-100">
              <tr>
                <th
                  scope="col"
                  v-for="(col, index) in props?.columns"
                  :key="col.key"
                  class="px-2 py-1 whitespace-nowrap text-sm text-gray-600 font-bold text-center divide-x"
                  @click="col.sortable ? col.id && doSort(col) : false"
                  :class="col.hidden ? 'hidden' : ''"
                  :style="col.width ? `min-width:${col.width}` : ''"
                >
                  {{ col.label }}
                  <div v-if="col.columns" class="text-center" :style="makeGridWrapper(col.columns.length)">
                    <th
                      scope="col"
                      v-for="(innerCol, index) in col?.columns"
                      :key="innerCol?.key"
                      class="px-2 py-1 whitespace-nowrap text-xs font-semi text-gray-500"
                      @click="innerCol?.sortable ? innerCol.id && doSort(innerCol) : false"
                    >
                      {{ innerCol.label }}
                      <TableHeaderSorting
                        v-if="innerCol.sortable"
                        :setting="setting"
                        :col="innerCol"
                      />
                    </th>
                  </div>
                  <TableHeaderSorting
                    v-if="col.sortable"
                    :setting="setting"
                    :col="col"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(row, index) in localRows" :key="row[setting.mainKey]">
                <td v-for="col in props.columns" :class="col.hidden ? 'hidden' : ''">

                    <div v-if="col.columns" :style="makeGridWrapper(col.columns.length)">
                      <td
                        v-for="innerCol in col.columns"
                        :key="innerCol.id && row[innerCol.id]"
                        class="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-800 text-center"
                      >
                        <TableBodyCell
                          :setting="setting"
                          :col="innerCol"
                          :row="row"
                        />
                      </td>
                    </div>

                    <td
                      v-else
                      :key="col.id && row[col.id]"
                      class="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-800 text-center flex justify-center"
                    >
                      <TableBodyCell :setting="setting" :col="col" :row="row"  @[eventName(col)]="innerEvents($event)"/>
                    </td>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 mt-8" v-if="setting.maxPage > 1">
      <Pagination
        :setting="setting"
        @pagination:prevPage="prevPage"
        @pagination:movePage="movePage($event)"
        @pagination:nextPage="nextPage"
      />
    </div></div>
  <EmptyListFeedback  v-else  :title="'Empty table'" :info="'Please wait/or refetch the data'"/>
</template>

<script setup lang="ts">
import { watchEffect, computed, reactive, VNode } from "vue";
import useTable from "./useTable";
import Pagination from "./Pagination.vue";
import TableHeaderSorting from "./TableHeaderSorting.vue";
import TableBodyCell from "./TableBodyCell.vue";
import EmptyListFeedback from '@core/shared/EmptyListFeedback.vue'
import { ColumnTable, RowTable, TableSetting } from "@/index";
import * as _ from 'lodash'

const props = defineProps<{
  columns: ColumnTable[] | RowTable;
  rows?: any[];
  mainKey?: string;
  options?: any
}>();

const emit = defineEmits(['sort-item', "table:event"])

const table = useTable();
// @ts-ignore
table.init({columns: props.columns, rows: props.rows, mainKey: props.mainKey, options: props.options})

const eventName = (col:ColumnTable) => col.eventName || "table:event";

// table.columns = props.columns;
// table.rows = props.rows;

const makeGridWrapper = ( gridCount: number) => `display: grid;grid-template-columns: repeat(${gridCount}, minmax(70px, 0.4fr));`

const setting : TableSetting = reactive({
  // Total number of transactions
  total: computed(() => props.rows?.length || 100),
  // current page number
  page: 1,
  // Display count per page
  pageSize: computed(() =>props.options?.pageSize || 20),
  //Maximum number of pages
  maxPage: computed(() => {
    if (setting.total <= 0) {
      return 0;
    }
    let maxPage = Math.floor(setting.total / setting.pageSize);
    let mod = setting.total % setting.pageSize;
    if (mod > 0) {
      maxPage++;
    }
    return maxPage;
  }),
  // The starting value of the page number
  offset: computed(() => {
    return (setting.page - 1) * setting.pageSize + 1;
  }),
  limit: computed(() => {
    let limit = setting.page * setting.pageSize;
    return setting.total >= limit ? limit : setting.total;
  }),
  paging: computed(() => {
    let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
    if (setting.maxPage - setting.page <= 2) {
      startPage = setting.maxPage - 4;
    }
    startPage = startPage <= 0 ? 1 : startPage;
    let pages = [];
    for (let i = startPage; i <= setting.maxPage; i++) {
      if (pages.length < 5) {
        pages.push(i);
      }
    }
    return pages;
  }),
  order: props.options?.mainOrder || 'id',
  sort: "desc",
  mainKey: props.mainKey || "name"
});

const localRows = computed(() => {
  if (!props.rows) return false;

  // sort rows
  let _rows = props.rows;

  // refs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/compare
  // const collator = new Intl.Collator(undefined, {
  //   numeric: true,
  //   sensitivity: "base",
  // });
  //const sortOrder = setting.sort === "desc" ? -1 : 1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _rows = _.orderBy(_rows, setting.order, setting.sort as any)
  // _rows?.sort((a: any, b: any): number => {
  //   return collator.compare(a[setting.order], b[setting.order]) * sortOrder;
  // });

  // return sorted and offset rows
  let result = [];
  for (let index = setting.offset - 1; index < setting.limit; index++) {
    if (_rows[index]) {
      result.push(_rows[index]);
    }
  }
  return result;
});

const doSort = (col: any) => {
  const order = col.sortId ? col.sortId : col.id;

  emit('sort-item', order)
  let sort = "asc";
  if (order == setting.order) {
    // When sorting items
    if (setting.sort == "asc") {
      sort = "desc";
    }
  }
  setting.order = order;
  setting.sort = sort;
};

const prevPage = () => {
  if (setting.page == 1) {
    return false;
  }
  setting.page--;
};

const movePage = (page: number) => {
  setting.page = page;
};

const nextPage = () => {
  if (setting.page >= setting.maxPage) {
    return false;
  }
  setting.page++;
};

const innerEvents = (event: any) => {
  console.log("innerEvents",event)
}
</script>

<style scoped>
.divide-x {
  border-right-width: 0px;
  border-left-width: 1px;
  }

</style>
