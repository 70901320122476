<template>
  <div>
    <div class="flex flex-row">
      <button
        @click="convertToCSV"
        class="px-4 py-2 my-6 text-md font-semibold text-white transition-colors duration-300 bg-indigo-500 rounded-md shadow hover:bg-indigo-600 focus:outline-none focus:ring-indigo-200 focus:ring-4"
      >
        {{ $t("common.exportCSV") }}
      </button>
      <!--
        <button
        @click="saveFilter"
        class="px-4 py-2 my-6 text-md font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4 ml-8"
        >
        {{ $t("common.saveFilter") }}
      </button>
    -->
    </div>
    <TableData :columns="burstsColumns" :rows="tableData" :options="options" @sort-item="sortItem($event)" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, watch, h } from "vue";
import * as _ from "lodash";
import { storeToRefs } from "pinia";

import { requestModeler } from "@/config/requestModeler";
import TableData from "@core/components/tables/elements/TableData.vue";
import useTable from "@core/components/tables/elements/useTable";
import { burstListTableColumns } from "./burstListTableColumns";

import { useConfigStore } from "@/config/configStore";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { helpers } from "@use/libs/helpers";
import { useDateTimeStore } from "@use/useDatetime";
import { useBurstDataStore } from "@modules/admin/store/useBurstDataStore";

const emit = defineEmits(["tableData:select-bursts-id"]);
const props = defineProps<{ tableData: any[] }>();

const DECIMALS_PPV = 3;
const DECIMALS = 1;

const datetime = useDateTimeStore();
const configs = useConfigStore().enumerationData;
const lens = useLensStore();
const graph = useGraphStore();
const lensGroup = useLensGroupStore();

const toDecimalPPV = (cell: any, row: any) => helpers.toDecimals(cell, DECIMALS_PPV);
const toDecimal = (cell: any, row: any) => helpers.toDecimals(cell, DECIMALS);
const toDate = (cell: any, row: any) => datetime.helpers.to24HourFormat(cell);

const options = {
  mainOrder: "datetime",
  mainOrderType: "date",
};
const table = useTable();
const { updatedData } = storeToRefs(table);

const localIgnoredList = computed(() =>
  updatedData.value.map((el: any) => ({
    name: el.name,
    datetime: el.datetime,
    checked: el.visible,
  }))
);

const fetchDataFromCosmosById = (id: string) => {
  const lensInfos = lens.selectedLensItems;
  const formatter = lensInfos?.data_type;
  const partitionKey = lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id;
  if (partitionKey) {
    const burstDetails = lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_DETAILS);
    const paramsList = [{ key: configs.cosmosQueryParamsEnum.ID, value: id }];
    // @ts-ignore
    const query = requestModeler(paramsList, burstDetails?.query_content);

    graph.setSelectedLensBurstGraphCurrent({
      query,
      lensInfos,
      partitionKey,
      // @ts-ignore
      formatter,
    });
  }
};
const sortItem = ($event: any) => {
  console.log($event);
};
const burstsColumns = burstListTableColumns({
  fetchDataFromCosmosById,
  toDecimalPPV,
  toDecimal,
  toDate,
});

const CSVColsNames = [
  { value: "ID", key: "name" },
  { value: "Deb. Enreg.", key: "rawDatetime" },
  { value: "Localisation", key: "localisation" },

  { value: "PPV-TRANS - PPV [mm/s]", key: "x_ppv" },
  { value: "PPV-TRANS - Freq [Hz]", key: "x_freq" },

  { value: "PPV-LONG - PPV [mm/s]", key: "y_ppv" },
  { value: "PPV-LONG - Freq [Hz]", key: "y_freq" },

  { value: "PPV-VERT - PPV [mm/s]", key: "z_ppv" },
  { value: "PPV-VERT - Freq [Hz]", key: "z_freq" },

  { value: "PVS [mm/s]", key: "pvs_ppv" },
  { value: "PVS time [s]", key: "pvs_time" },
];

const burstListIgnore = (lensId: string, localIgnoredList: any[]) => {
  return {
    id: `${lensId}-burst-list-log-ignore`,
    partitionKey: "burst-list-log-ignore",
    data_burst_list_ignore: localIgnoredList,
  };
};

const convertToCSV = () => {
  const localisation = `${lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id}/${lensGroup.selectedLensGroup?.lensGroup_name}`;

  const colNames = CSVColsNames;
  const arr = props.tableData?.filter((el) => el.visible === true);

  const headers = colNames.map((name) => name.value).join(" , ");

  const csvContent = arr
    .map((it) => {
      return colNames
        .map((col) => {
          if (col.key === "rawDatetime") {
            return datetime.helpers.toDate(it[col.key]);
          } else if (col.key === "localisation") {
            return localisation;
          } else return it[col.key];
        })
        .join(" , ");
    })
    .join(" \n ");

  const result = [headers, csvContent].join(" \n ");
  var element = document.createElement("a");
  element.href = "data:text/csv;charset=utf-8," + encodeURI(result);
  element.target = "_blank";
  element.download = `${lens.selectedLensItems?.lens_id}-${new Date().toISOString()}.csv`;
  element.click();
};

const saveFilter = () => {
  const id = lens.selectedLensItems?.lens_id;
  if (id) {
    const data = burstListIgnore(id, localIgnoredList.value);
    useBurstDataStore().saveBurstListIgnore(data);
  }
};

onMounted(() => {
  if (props.tableData) {
    table.setInitialSwitchData(props.tableData, "visible");
  }
});
</script>
