import * as _ from "lodash";

export const metadataGeneric = ({
  location_id = "error",
  latlong = "50.535141|4.973460",
  location_name = "Micromega Device 1",
  group_name = "Micromega Device 1",
  timezone_python = "Europe/Brussels",
  timezone_javascript = "Europe/Brussels",
  group_description = "Rue trou du sart 10 5380 Fernelmont",
  device_sn = "20610011",
  client_name = "democlient",
}) => {
  if (location_id)
    return {
      id: `${location_id}-metadata-F2B`,
      doc_type: "F2B",
      operator: "Name FirstName",
      device_sn,
      latlong,
      tenant_id: client_name,
      group_name,
      contract_no: "NONE",
      location_id,
      tenant_name: "unallocated",
      _attachments: "attachments/",
      affiliate_id: "",
      partitionKey: "metadata",
      location_name,
      organisations: _.uniq(["micromega", client_name]),
      affiliate_name: "",
      timezone_azure: "Romance Standard Time",
      timezone_python,
      group_description,
      application_type: "generic",
      report_logo_left: "logoMMD.png",
      report_logo_right: "logoAffiliate.png",
      time_format_azure: "dd-MM-yyyy HH:mm:ss",
      activation_datetime: "2022-07-01T09:28:13.461Z",
      timezone_javascript,
      display_report_generator: false,
      display_report_generator_pdf: false,
      lens_items: [
        {
          type: "monitor_processing",
          message: "",
          location_id,
          lens_variables: [],
          lens_status: "active",
          lens_queries: [],
          lens_parameters_process: [],
          lens_parameters_notification: [],
          lens_name: "Bursts",
          lens_metadata_id: `${location_id}-metadata-F2B`,
          lens_id: `${location_id}-generic-burst-2`,
          lens_description: "Bursts",
          lens_charts: [],
          data_type: "generic-bursts",
          data_summary: "",
          burst_list_log_group_by: "",
          anomaly_notification_enable: false,
          anomaly_disapearing: false,
          anomaly_appearing: false,
        },
        {
          type: "monitor_processing",
          message:
            "#datetime #end #location_id / #location_name #end Transition from  #previous_state to #state_now #end Vibration Peak: #data_summary_user_unit #user_unit #end Serial number: #serial_number #end NOTE: #end #threshold_1 #end #threshold_2 #end #threshold_3 #end",
          location_id,
          lens_variables: [],
          lens_status: "active",
          lens_queries: [],
          lens_parameters_process: [],
          lens_parameters_notification: [],
          lens_name: "Statistics",
          lens_metadata_id: `${location_id}-metadata-F2B`,
          lens_id: `${location_id}-generic-stat-4`,
          lens_description: "Statistics ",
          lens_base_chart_type: ["stats_timeseries_multi_axes", "stats_timeseries_multi_axes"],
          data_type: "generic-statistics",
          data_summary: "",
          burst_list_log_group_by: "",
          anomaly_notification_enable: true,
          anomaly_disapearing: false,
          anomaly_appearing: true,
          lens_charts: [
            {
              type: "stats_timeseries_multi_axes",
              title: "generic",
              lens_id: `${location_id}-generic-stat-4`,
              x_label: "x",
              x_range: [0, 0],
              y_label: "y",
              y_range: [0, 10],
              is_generic: true,
              is_markline: true,
              chart_number: 0,
              is_combined_graph: false,
              physical_quantity: "acceleration",
            },
            {
              type: "stats_timeseries_multi_axes",
              title: "generic",
              lens_id: `${location_id}-generic-stat-4`,
              x_label: "x",
              x_range: [0, 0],
              y_label: "y",
              y_range: [0, 10],
              is_generic: true,
              is_markline: false,
              chart_number: 1,
              is_combined_graph: true,
              physical_quantity: "acceleration",
            },
          ],
        },
      ],
      recipients: [

      ],
      physical_quantities: [
        {
          type: "acceleration",
          external_unit: "g",
          internal_unit: "g",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 3,
        },
        {
          type: "velocity",
          external_unit: "mm/s",
          internal_unit: "mm/s",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 3,
        },
        {
          type: "time",
          external_unit: "s",
          internal_unit: "s",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "frequency",
          external_unit: "Hz",
          internal_unit: "Hz",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "windspeed",
          external_unit: "m/s",
          internal_unit: "m/s",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 2,
        },
        {
          type: "winddir",
          external_unit: "deg",
          internal_unit: "deg",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
        {
          type: "displacement",
          external_unit: "mm",
          internal_unit: "mm",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 3,
        },
        {
          type: "pressure",
          external_unit: "Pa",
          internal_unit: "Pa",
          f_to_external_unit: "1*x+0",
          f_to_internal_unit: "(x-0)/1",
          user_decimal_digits: 1,
        },
          {
          "type": "overpressure",
          "external_unit": "Pa",
          "internal_unit": "Pa",
          "f_to_external_unit": "1*x+0",
          "f_to_internal_unit": "(x-0)/1",
          "user_decimal_digits": 1
       },
       {
          "type": "ratio_percent",
          "external_unit": "%",
          "internal_unit": "%",
          "f_to_external_unit": "1*x+0",
          "f_to_internal_unit": "(x-0)/1",
          "user_decimal_digits": 1
       },
       {
          "type": "particulate_concentration",
          "external_unit": "mg/m3",
          "internal_unit": "mg/m3",
          "f_to_external_unit": "1*x+0",
          "f_to_internal_unit": "(x-0)/1",
          "user_decimal_digits": 2
       }
      ],
      lens_variables: [
        {
          vc_name: "VC_0",
          disp_name: "VC_0",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
        {
          vc_name: "VC_1",
          disp_name: "VC_1",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
        {
          vc_name: "VC_2",
          disp_name: "VC_2",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
        {
          vc_name: "VC_3",
          disp_name: "VC_3",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
        {
          vc_name: "VC_4",
          disp_name: "VC_4",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
        {
          vc_name: "VC_5",
          disp_name: "VC_5",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
        {
          vc_name: "VC_6",
          disp_name: "VC_6",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
        {
          vc_name: "VC_7",
          disp_name: "VC_7",
          sensor_sn: "NONE",
          physical_quantity: "acceleration",
          sensor_type: "NONE",
        },
      ],
    };
};
