import * as _ from "lodash";

import { BurstListIgnore, BurstListItem } from "@/index";
import { Ref, reactive, ref } from "vue";

import { defineStore } from "pinia";
import entityStateManager from "@use/entity";
import { mergeListWith } from "@/@use/libs/mergeListWith";
import moment from "moment";
import { queryBurstListLogNotes } from "./cosmosQueries";
import { sleep } from "@/@use/libs/helpers";
import { useAPI } from "@use/useAPI";
import { useDateTimeStore } from "@use/useDatetime";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useUiStore } from "@/@use/uiStore";

export const useBurstDataStore = defineStore(
  "burstData",
  () => {
    const API = useAPI();
    const lensGroup = useLensGroupStore();
    const datetime = useDateTimeStore();
    const ui = useUiStore();

    const manager = entityStateManager("id");
    const dataEntities = ref(manager.initialState);

    const burstTable = ref();
    const burstGenericTable = ref();
    const burstGeneric = ref();

    const meta = reactive<{
      lastFetchDate: string;
      isAll: boolean;
    }>({ lastFetchDate: "", isAll: true });

    const tz = lensGroup.selectedLensGroup?.lensGroup_data.metadata.timezone_javascript;

    /**
     * Remappe les données d'un élément Burst.
     *
     * @param burstListItem - L'élément Burst à remapper.
     * @param ignoredList - La liste des éléments ignorés.
     * @returns Les données remappées de l'élément Burst.
     */
    const reMapData = (burstListItem: BurstListItem, ignoredList: BurstListItem[]) => {
      if (burstListItem) {
        try {
          const isIgnored = ignoredList.find((x) => x.name === burstListItem.name);
          let visible;
          if (!_.isEmpty(isIgnored) && isIgnored?.checked === false) {
            // @ts-ignore
            visible = false
          } else {
            visible = true;
          }

          const dt = burstListItem.datetime && tz ? moment(burstListItem.datetime).tz(tz).format(datetime.time24hFormat) : burstListItem.datetime;
          const time = new Date(burstListItem.datetime).getTime() / 1000;
          return {
            // datetime: dt,
            displayedDt: dt,
            visible,
            time,
          };
        } catch (error) {
          console.log(error);
        }
      }
    };

    /**
     * Modifie les données de la table sélectionnée en appliquant une remise en correspondance des données.
     *
     * @param table - Le tableau de données de la table.
     * @param ignored - Les éléments à ignorer lors de la remise en correspondance des données.
     * @returns Le tableau de données modifié avec la remise en correspondance des données.
     */
    const setSelectedLensTableData = (table: BurstListItem[], ignored: BurstListIgnore) => {
      if (Array.isArray(table)) {
        const ignoredList = Array.isArray(ignored) && ignored.length > 0 ? ignored : [];

        const res = _.reverse(
          _.map(table, (el) => {
            return {
              ...el,
              ...reMapData(el, ignoredList),
            };
          })
        );
        return res;
      }
    };

    /**
     * Récupère les données de la table Burst sélectionnée.
     *
     * @param partitionKey - La partitionKey.
     * @param query - La requête.
     * @param id - L'identifiant.
     */
    const fetchSelectedLensBurstTable = async ({ partitionKey, query, id }: { partitionKey: string; query: string; id: string }) => {
      let _ignored, _tableData;
      const partitionKeyIgnore = `${partitionKey}-ignore`;
      const idIgnore = `${id}`;

      let ignoredQuery = query.replace(id, idIgnore);
      ignoredQuery = ignoredQuery.replace("data_burst_list", `data_burst_list_ignore`);
      ignoredQuery = ignoredQuery.replace("burst-list-log", `burst-list-log-ignore`);
      ui.setLoadingState(true);
      const now = new Date().toISOString();
      // Move it to statistics
      // const doFetch = () => {
      //   const hasId = manager.hasEntity(dataEntities.value, id);
      //   const lessAnHour = moment(now).isSameOrAfter(
      //     meta.lastFetchDate,
      //     "hour"
      //   );
      //   return !hasId || !lessAnHour
      // };
      if (true) {
        _ignored = (await API.fetchFromCosmosWithquery({
          partitionKey: partitionKeyIgnore,
          cosmosQuery: ignoredQuery,
        })) as Ref<any>;

        _tableData = (await API.fetchFromCosmosWithquery({
          partitionKey,
          cosmosQuery: query,
        })) as Ref<any>;

        if (_tableData?.value) {

          burstTable.value = setSelectedLensTableData(_tableData?.value, _ignored?.value);

          const { ids, ...val } = manager.setAllEntities([{ id, list: burstTable.value }]);
          // @ts-ignore
          dataEntities.value = {
            entities: { ...dataEntities.value.entities, ...val.entities },
            ids: [...dataEntities.value.ids, ...ids],
          };
        }
      } else {
        burstTable.value = manager.getEntityById(dataEntities.value, id)?.list;
      }
      ui.setLoadingState(false);
    };

    /*
    {
      "id": "E13-generic-burst_0-2-month-06-burst-list-log-note",
    "partitionKey": "burst-list-log-note",
   "notes": [ {
      "name": "E13-generic-burst_0-2-2023-06-14T08:08:18.000000Z",
     "burst_id": "0",
            "message": "Message 1"
      }]
    }
*/

    /**
     * Crée une requête de liste de journaux à partir d'une requête d'entrée.
     *
     * @param inputQuery La requête d'entrée.
     * @returns La requête de liste de journaux générée.
     */
    const createListLogQuery = (inputQuery: string) => {
      // Extraire E1-, E2- ou E13- (ou tout autre numéro après E)
      const match = inputQuery.match(/STARTSWITH\(c\.id, '(E\d+-)/);

      if (!match) {
        return "Aucun motif E*- trouvé dans la requête.";
      }

      const extractedValue = match[1];

      // Stocker la requête de sortie dans une variable avec un placeholder (000)
      let outputQuery = `SELECT VALUE notes FROM c JOIN notes IN c.notes WHERE c.partitionKey="burst-list-log-note" AND STARTSWITH(c.id, '000generic-burst') AND ENDSWITH(c.id, 'burst-list-log-note')`;

      // Remplacer le placeholder par la valeur extraite
      outputQuery = outputQuery.replace("000", extractedValue);

      return outputQuery;
    };

    /**
     * Récupère les données de la table générique Burst pour la lens sélectionnée.
     *
     * @param partitionKey - La partitionKey pour la requête.
     * @param query - La requête à exécuter.
     * @param id - L'identifiant de la requête.
     */
    const fetchSelectedLensBurstGenericTable = async ({ partitionKey, query, id }: { partitionKey: string; query: string; id: string }) => {
      const burstListLog = mergeListWith("name", { message: "" });

      const listLogNoteQuery = createListLogQuery(query);
      const _tableData = (await API.fetchFromCosmosWithquery({
        partitionKey,
        cosmosQuery: query,
      })) as Ref<any>;

      const _tableDataNote = (await API.fetchFromCosmosWithquery({
        partitionKey: "burst-list-log-note",
        cosmosQuery: listLogNoteQuery,
      })) as Ref<any>;

      // const a = burstListLog(_tableData.value, _tableDataNote.value).filter((el) => el.datetime);
      // console.log(a)
      burstGenericTable.value = burstListLog(_tableData.value, _tableDataNote.value)
        ?.filter((el) => el.datetime)
        ?.map((el) => {
          const logId = ""; //`${generateAndExtractBurstLogId(el.name, el.datetime)}-note`;
          return {
            ...el,
            logId,
          };
        });
    };

    /**
     * Enregistre une note de journal Burst.
     *
     * @param burstLogNote - Les informations de la note de journal Burst à enregistrer.
     * @returns Une Promise qui se résout avec la réponse de l'enregistrement.
     */
    const saveBurstLogNote = async (burstLogNote: any) => {
      try {
        ui.setLoadingState(true);
        const listLogNoteQuery = queryBurstListLogNotes(burstLogNote.logId);
        const noteDoc = await API.fetchFromCosmosWithquery({
          partitionKey: "burst-list-log-note",
          cosmosQuery: listLogNoteQuery,
        });

        if (noteDoc?.value.length > 0) {
          const _doc = noteDoc?.value[0];
          const hasNote = _doc.notes.find((n: any) => n.name === burstLogNote.name);

          const _note = hasNote ? hasNote : { name: burstLogNote.name, message: burstLogNote.message, burst_id: burstLogNote.burst_id };

          const newNote = {
            ..._note,
            message: burstLogNote.message,
          };

          const _notes = hasNote ? _doc.notes?.filter((n: any) => n.name !== burstLogNote.name)?.concat(newNote) : [..._doc.notes, newNote];

          const newDoc = {
            ..._doc,
            notes: _notes,
          };

          const res = await API.postData("configs/upsertCosmosData", newDoc);

          await sleep(200);
          ui.setLoadingState(false);
          return res;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const saveBurstListIgnore = async (ignore: any) => {
      try {
        ui.setLoadingState(true);
        const res = await API.postData("configs/upsertCosmosData", ignore);
        await sleep(200);
        ui.setLoadingState(false);
        return res;
      } catch (error) {
        console.error(error);
      }
    };

    return {
      fetchSelectedLensBurstTable,
      fetchSelectedLensBurstGenericTable,
      burstTable,
      burstGenericTable,
      dataEntities,
      saveBurstListIgnore,
      saveBurstLogNote,
    };
  },
  {
    persist: false,
  }
);
