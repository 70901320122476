// @ts-ignore
import { createI18n } from "vue-i18n";
// @ts-ignore
import en from "./en-EN";
// @ts-ignore
import fr from "./fr-FR";

export const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: "en",
  messages: { en, fr },
});


// https://medium.com/js-dojo/manage-vue-i18n-with-typescript-958b2f69846f
