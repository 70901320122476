/**
 * Options for generic CSV metadata.
 * @param location_id - The location ID. Default value is "error".
 * @returns The generic CSV options object.
 */
export const genericCSVOption = (location_id = "error") => ({
  id: `${location_id}-metadata-generic-csv-statistics-options`,
  lens_id: `${location_id}-generic-stat-4`,
  partitionKey: "metadata",
  options: [
    {
      id: "1a8e5120-d3cc-4ff6-a736-a1df02ab3efa",
      order: 0,
      allDataNames: ["avg_data"],
      customVariables: {
        avg_data: ["VC_0", "VC_1"],
      },
      customCharts: {},
    },
  ],
});
