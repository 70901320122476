<template>
  <div class="container mx-auto items-center px-5 pb-24">
    <template v-for="groupName in splitBurstListChart">
      <component
        v-if="groupName"
        :key="applicationType"
        :is="getCurrentChart"
        :lensItem="lensItem"
        :tableData="tableData"
        :groupBy="decomposeAndSplitScatter(groupName)"
      ></component>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from "vue";
import BurstDin4150ListLogChart from "@core/charts/normeGraphs/BurstDin4150ListLogChart.vue";
import BurstNP2074LogChartVue from "@core/charts/normeGraphs/BurstNP2074LogChart.vue";
// @ts-ignore
import { splitDs } from "@core/charts/dataFormatters/fnUtils";
import { LensItemsGroup, LensItemsMetadata } from "@/index";

const props = defineProps<{
  lensItem: LensItemsGroup;
  tableData: any[];
  applicationType: any;
  lensLogGroup: string;
}>();

/**
 * Renvoie le graphique actuel en fonction du type d'application.
 *
 * @returns {Component} Le composant du graphique correspondant au type d'application.
 */
const getCurrentChart = computed(() => {
  if (props.applicationType.toLowerCase() === "np2074") return BurstNP2074LogChartVue;
  if (props.applicationType.toLowerCase() === "din4150-3-zc") return BurstDin4150ListLogChart;
  if (props.applicationType.toLowerCase() === "generic") return BurstDin4150ListLogChart;
});

// @ts-ignore
const splitBurstListChart: string[] = computed(() => {
  return splitDs(props.lensLogGroup);
});

/**
 * Décompose et divise une chaîne de caractères contenant des scatters.
 * @param {string} scatters - La chaîne de caractères contenant les scatters.
 * @returns {Array<Array<string>>} - Un tableau de tableaux de chaînes de caractères représentant les scatters.
 */
const decomposeAndSplitScatter = (scatters:string) => {
  if (scatters) {
    return scatters.split(",").map((sc) => sc.replace(/[\[\]']+/g, "").split(";"));
  }
};
</script>
