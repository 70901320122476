import * as _ from 'lodash'

export const toDecimals = (point: number, decimal = 2) => parseFloat(point.toFixed(decimal))
/**
 * Retourne les options de tooltip pour le graphique de la liste des logs de burst.
 * @param unit L'unité de mesure (par défaut: 'mm/s').
 * @returns Les options de tooltip.
 */
export const burstListLogTooltip = (unit = 'mm/s') => ({
  // trigger: 'axis',
  showDelay: 0,
  formatter: function (params: any) {
    const colorSpan = (color: string) =>
      `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>`

    if (params.value.length > 1) {
      return `${colorSpan(params.color)} ${params.seriesName} <br/>
      freq: ${toDecimals(params.value[0], 3)} Hz<br/>
      ppv: ${toDecimals(params.value[1], 3)} ${unit}<br/>`
    }
  },
  axisPointer: {
    show: true,
    type: 'cross',
    lineStyle: {
      type: 'dashed',
      width: 1,
    },
  },
})

/**
 * Définit les paramètres de l'axe X pour le graphique de la liste des rafales.
 * @returns Les paramètres de l'axe X.
 */
export const burstListLogXAxis = () => ({
  minorTick: {
    show: true,
    splitNumber: 2,
    minInterval: 10,
  },
  minorSplitLine: {
    show: true,
  },
  axisLabel: {
    formatter: '{value} Hz',
  },
  max: function () {
    return 100
  },
  min: function () {
    return 0
  },
})

/**
 * Retourne les paramètres de l'axe Y pour le graphique de la liste des rafales.
 * @param gain_to_user_unit Le gain pour convertir les valeurs en unités utilisateur. Par défaut: 1.
 * @param offset_to_user_unit Le décalage pour convertir les valeurs en unités utilisateur. Par défaut: 0.
 * @param unit L'unité à afficher sur l'axe Y. Par défaut: 'mm/s'.
 * @returns Les paramètres de l'axe Y pour le graphique de la liste des rafales.
 */
export const burstListLogYAxis = (gain_to_user_unit = 1, offset_to_user_unit = 0, unit = 'mm/s') => ({
  splitNumber: 6,
  axisLabel: {
    formatter: function (value: any) {
      return `${value} ${unit}`
    },
  },
  minorTick: {
    show: true,
    splitNumber: 5,
  },
  minorSplitLine: {
    show: true,
  },
  max: function () {
    const res = (60 - offset_to_user_unit) / gain_to_user_unit
    return toDecimals(res)
  },
  min: function () {
    const res = (0 - offset_to_user_unit) / gain_to_user_unit
    return toDecimals(res)
  },
})
