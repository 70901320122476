<template>
  <div class="flex flex-col h-screen">
    <Disclosure as="nav" class="bg-blue-custom flex-grow-0 shadow-sm" v-slot="{ open }">
      <div class="container px-4 py-4 mx-auto">
        <div class="flex  h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <router-link :to="{ name: 'home' }">
                <img class="block lg:hidden h-8 w-auto" src="/img/logo/recovib_full_white.svg" alt="Recovib" />
                <img class="hidden lg:block h-8 w-auto" src="/img/logo/recovib_full_white.svg" alt="Recovib" />
              </router-link>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center" v-if="false">
            <router-link
              :to="{ name: 'home' }"
              class="inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {{ $t("auth.login") }}
            </router-link>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <DisclosureButton
              class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>
    </Disclosure>

    <div class="h-screen overflow-hidden">
      <main><slot /></main>
    </div>
    <Footer></Footer>
  </div>
</template>

<script setup lang="ts">
import Footer from "@core/components/banner/Footer.vue";
import { Disclosure, DisclosureButton } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { useRoute } from "vue-router";
import { useUiStore } from "@use/uiStore";

const ui = useUiStore();
const route = useRoute();
</script>
<style scoped>
.bg-blue-custom {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity));
}
</style>
