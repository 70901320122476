import * as _ from "lodash";

import { LensChart, LensParametersNotification, LensVariable, Limit, StatDataForLineMultiplesAxe } from "@/index";

import { formatStreamListToObject } from "../fnUtils";

/**
 * Formate les données statistiques pour les graphiques à plusieurs axes de ligne.
 *
 * @param data - Les données à formater.
 * @param vcNames - Les noms des canaux.
 * @param variables - Les variables de l'objectif.
 * @param chart - Le graphique.
 * @param limites - Les limites des paramètres.
 * @returns Les données formatées pour les graphiques à plusieurs axes de ligne.
 */
export const formatStatDataForLineMultiplesAxes = (
  data: any,
  vcNames: string[],
  { variables, chart, limites }: { variables: LensVariable[]; chart: LensChart; limites: LensParametersNotification[] }
): StatDataForLineMultiplesAxe | undefined => {
  const { id, timezone, ...rest } = data;
  const merged = [] as any[];

  let errorMessage = "";
  // Fonction de parcours pour avoir l'intégralité des données suivant la time

  if (vcNames && vcNames.length > 0) {
    const datas = (idx: number) => {
      return vcNames.map((k, _) => {
        // errorMessage = data[k] ? "" : "Channel with data not found"
        data[k] ? "" : console.error("undefined", data);
        return data[k] ? data[k][idx] : undefined;
      });
    };

    rest[vcNames[0]]?.forEach((_: any, i: number) => {
      if (datas(i)) {
        // @ts-ignore
        merged.push(formatStreamListToObject(datas(i)));
      }
    });

    /* si on est sur une stat générique on récupère la physical quantity du premier lens variable
     */
    if (chart.is_generic) {
      const pQ = variables.find((v) => v.vc_name === vcNames[0].split("__")[0])?.physical_quantity as string;
      chart.physical_quantity = pQ;
    }
    //   On retourne toutes données utiles
    return {
      id,
      info: {
        name: "undefined",
        timezone,
        frequencyPerSecond: data.adc_fs,
        xUnit: "s",
      },
      chartData: {
        columns: _.uniq(["time", ...vcNames]),
        rows: merged,
      },
      key: vcNames,
      variables,
      chart,
      limites,
    };
  }
};
