/**
 * Fusionne plusieurs listes d'objets en utilisant une clé commune.
 *
 * @param key La clé utilisée pour fusionner les objets.
 * @param defaultObj L'objet par défaut à fusionner avec les objets de la liste.
 * @returns Un tableau contenant les objets fusionnés.
 */
export const  mergeListWith= (key: string, defaultObj = {}) =>  (...lists: any[]) => {
  const mergedObjects: any [] = [];
  const seenValues = new Map();

  // Pour chaque liste d'objets
  lists.forEach((list) => {
    list.forEach((obj: any) => {
      // Si la clé existe dans l'objet
      if (obj.hasOwnProperty(key)) {
        const keyValue = obj[key];

        // Si la valeur de la clé a déjà été vue
        if (seenValues.has(keyValue)) {
          const existingObj = seenValues.get(keyValue);
          seenValues.set(keyValue, { ...existingObj, ...obj });
        } else {
          seenValues.set(keyValue, { ...defaultObj, ...obj });
        }
      }
    });
  });

  return [...mergedObjects, ...seenValues.values()];
}
