<template>
  <div>
    <TableData :columns="columns" :rows="tableData" :options="options" />
  </div>
</template>

<script setup lang="ts">
import { ref, h, computed } from "vue";
import TableData from "@core/components/tables/elements/TableData.vue";
import moment from "moment";
import * as _ from "lodash";
import { RowTable } from "@/index";
import { i18n } from "@/locales";

const props = defineProps<{ tableData: any[] }>();
const emit = defineEmits(["tableData:download-file"]);

// TODO: à voir si on supprime
const options = {
  mainOrder: "properties.createdOn",
  mainOrderType: "date",
};

const columns = [
  {
    id: "name",
    label: i18n.global.t("tables.name"),
    sortable: true,
    isKey: true,
    formatter: (cell: any, row: any) => _.last(_.split(cell, "/")),
  },
  {
    id: "createdOn",
    label: i18n.global.t("tables.createdOn"),
    sortable: true,
    isKey: true,
    sortItemType: "date",
    sortItemKey: "createdOn",
    formatter: (cell: string) =>  moment(cell).format("D MMM, yyyy HH:mm:ss")
    //  (cell: any, row: any) => moment(cell.createdOn).format("D MMM, yyyy HH:mm:ss"),
  },
  {
    label:  i18n.global.t("tables.download"),
    id: "action",
    isKey: false,
    render: (_: never, row: string) => {
      return h(
        "button",
        {
          className: "m-auto block px-4 py-1 bg-green-200 rounded shadow-xs cursor-pointer hover:bg-gray-500 hover:text-gray-100 text-xs",
          onClick: () => emit("tableData:download-file", row),
        },
        `Download`
      );
    },
  },
] as RowTable;
</script>
