/**
 * Crée un objet de logs pour une alarme générique de type "burst".
 *
 * @param location_id - L'identifiant de l'emplacement (par défaut: "error").
 * @returns Un objet contenant les logs de l'alarme générique de type "burst".
 */
export const genericBurstLogs = (location_id = "error") => ({
  id: `${location_id}-generic-burst-alarm-logs`,
  partitionKey: "metadata",
  logs: [],
});
/**
 * Crée un objet contenant les règles d'alarme générique pour une alarme de type "burst".
 *
 * @param location_id L'identifiant de l'emplacement (par défaut: "error").
 * @returns L'objet contenant les règles d'alarme générique.
 */
export const genericBurstRules = (location_id = "error") => ({
  id: `${location_id}-generic-burst-alarm-rules`,
  partitionKey: "metadata",
  rules: [],
});
/**
 * Fonction qui retourne les règles d'alarme génériques pour le module DIN41503.
 *
 * @param location_id L'identifiant de l'emplacement (par défaut: "error").
 * @param moduleId L'identifiant du module (par défaut: "din41503-1").
 * @returns Les règles d'alarme génériques pour le module DIN41503.
 */
export const genericBurstModuleDin41503Rules = (location_id = "error", moduleId = "din41503-1") => ({
  id: `${location_id}-module-${moduleId}-alarm-rules`,
  location_id: `${location_id}`,
  partitionKey: "metadata",
  rules: [],
});
