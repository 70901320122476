export const settingRoutes = [
  {
    path: `/admin/lens/settings/recipient/:locationId`,
    name: `admin-lens-settings-recipient-id`,
    component: () => import("./NewRecipientSetting.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/admin/lens/settings/lens-group/:locationId`,
    name: `admin-lens-settings-lens-group-id`,
    component: () => import("./LensGroupEditSetting.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/admin/lens/settings/device/:locationId`,
    name: `admin-lens-settings-device-id`,
    component: () => import("./DeviceSetting.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/admin/lens/settings/planning/:locationId`,
    name: `admin-lens-settings-planning-id`,
    component: () => import("./PlanningEditSetting.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
];
