export type Entity = {
  id: number;
};

export type DataEntity = {
  ids: string[];
  hasDetailsIds: string[];
  entities: any;
  details: any;
};

import { reactive } from "vue";

/**
 * Compare et trouve les éléments d'un premier tableau qui satisfont une condition donnée en utilisant un callback.
 * @param firstArray - Le premier tableau à comparer.
 * @param secondArray - Le deuxième tableau à comparer.
 * @returns Une nouvelle tableau contenant les éléments du premier tableau qui satisfont la condition donnée.
 */
export const compareAndFind = (firstArray: any[], secondArray: any[]) => {
  return (callback: (a: any, b: any) => boolean) => {
    return firstArray?.filter((firstElem) => {
      return secondArray?.find((secondElem) => {
        return callback(firstElem, secondElem);
      });
    });
  };
};

/**
 * Compare et fusionne deux tableaux en utilisant une fonction de rappel personnalisée.
 *
 * @param firstArray Le premier tableau à comparer et fusionner.
 * @param secondArray Le deuxième tableau à comparer et fusionner.
 * @param nodes Le nom de la propriété dans laquelle les éléments du deuxième tableau seront stockés dans le tableau combiné. Par défaut, "nodes".
 * @param key Le nom de la clé utilisée pour identifier les éléments dans le tableau combiné. Par défaut, "_key".
 * @returns Un nouveau tableau contenant les éléments combinés et filtrés.
 */
export const compareAndMergeWith = (firstArray: any[], secondArray: any[], nodes = "nodes", key = "_key") => {
  return (callback: (a: any, b: any) => boolean) => {
    const combinedData = [] as any[];
    firstArray?.filter((firstElem) => {
      const secondArrayToPush = [] as any[];
      return secondArray?.filter((secondElem) => {
        if (callback(firstElem, secondElem) === true) {
          secondArrayToPush.push(secondElem);
          combinedData.push({
            ...firstElem,
            ...{ [nodes]: secondArrayToPush },
          });
        }
      });
    });

    const map = new Map();
    const results = [];
    for (const item of combinedData) {
      if (!map.has(item[key])) {
        map.set(item[key], true); // set any value to Map
        results.push({ ...item });
      }
    }

    return results;
  };
};

/**
 * Ce module exporte une fonction qui crée un gestionnaire d'entités.
 * Un gestionnaire d'entités permet de gérer une collection d'entités en utilisant des opérations telles que l'ajout, la mise à jour et la suppression.
 *
 * @param key - La clé par défaut utilisée pour identifier les entités (par défaut: "id").
 * @param sorterFn - La fonction de tri personnalisée utilisée pour trier les entités (par défaut: null).
 * @returns Un objet contenant les méthodes pour gérer les entités.
 */
export default (key = "id", sorterFn = null) => {
  const _key = key;
  let localState = reactive<DataEntity>({
    ids: [],
    hasDetailsIds: [],
    entities: {},
    details: {},
  });

  const initialState = (): DataEntity => localState;

  let sortCb = (a: Entity, b: Entity): number => {
    return a.id - b.id;
  };

  if (sorterFn) {
    sortCb = sorterFn;
  }

  const listToObjectWithId = (list: any[] = [], key: string = _key): DataEntity => {
    const ids = list?.map((i) => i[key]);
    const objectFromList = list?.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, {});
    localState = {
      entities: objectFromList,
      ids,
      hasDetailsIds: [],
      details: {},
    };
    return localState;
  };

  const upsertObject = (modifier: Entity, id: never) => {
    return (dataEntityToEdit: DataEntity): DataEntity => {
      const { ids, entities, ...previousState } = dataEntityToEdit;
      const editedObject = { ...entities[id], ...modifier };
      const newIds = ids?.includes(id) ? ids : ids?.concat(id);
      localState = {
        ...{
          entities: { ...dataEntityToEdit.entities, ...{ [id]: editedObject } },
        },
        ids: newIds,
        ...previousState,
      };
      return localState;
    };
  };

  const deleteObject = (id: never) => {
    return (dataEntityToEdit: any): DataEntity => {
      const { ids } = dataEntityToEdit;

      const newIds = ids?.includes(id) ? ids.filter((i: any) => i !== id) : ids;
      delete dataEntityToEdit.entities[id];
      localState = {
        ...dataEntityToEdit,
        ids: newIds,
      };

      return localState;
    };
  };

  const addManyObject = (objectsToAdd: any[], key: string = _key) => {
    return (listToEdit: DataEntity): DataEntity => {
      const { ids, ...previousState } = listToObjectWithId(objectsToAdd, key);

      const newIds = [...ids, ...listToEdit.ids] as any;
      const newEntities = {
        entities: { ...listToEdit.entities, ...previousState.entities },
      };
      localState = {
        ...previousState,
        ...newEntities,
        ids: newIds,
      };
      return localState;
    };
  };

  const upsertDetailsOfObject = (modifier: any, key = _key) => {
    return (listToEdit: DataEntity) => {
      const { hasDetailsIds, details, ...previousState } = listToEdit;
      const editedObject = { ...details[key], ...modifier };

      const modifierKey = modifier[key];

      const newIds = hasDetailsIds?.includes(key as never) ? hasDetailsIds : hasDetailsIds?.concat(modifierKey);
      localState = {
        ...{
          details: {
            ...listToEdit.details,
            ...{ [modifierKey]: editedObject },
          },
        },
        hasDetailsIds: newIds,
        ...previousState,
      };
      return localState;
    };
  };

  const getEntryById = (entry: string) => {
    return (_state: any, id: string | number) => {
      if (_state && _state[entry]) {
        return _state[entry][id];
      }
    };
  };

  const isExist = (entry: string) => {
    return (_state: any, id: string | number) => {
      if (_state && _state[entry]) {
        return _state[entry]?.includes(id) ? true : false;
      }
    };
  };

  const combineToDataEntities = (stateEntry: any, entities: any[]) => {
    return { ...stateEntry, ...entities };
  };

  return {
    initialState: initialState(),
    getLocalState: () => localState,
    setAllEntities: listToObjectWithId,
    upsertEntity: upsertObject,
    deleteEntity: deleteObject,
    upsertManyEntities: addManyObject,
    upsertDetails: upsertDetailsOfObject,

    getEntityById: getEntryById("entities"),
    getDetailsById: getEntryById("details"),

    hasEntity: isExist("ids"),
    hasDetails: isExist("hasDetailsIds"),

    entityEntries: (state: DataEntity) => Object.entries(state.entities),
    detailsEntries: (state: DataEntity) => Object.entries(state.details),

    entitiesToArray: (state: DataEntity) => Object.values(state.entities).sort(sortCb as any),
    detailsToArray: (state: DataEntity) => Object.values(state.details).sort(sortCb as any),

    compareAndFind,
    compareAndMergeWith,
    combineToDataEntities,
  };
};

