import * as _ from "lodash";
// @ts-ignore
import * as math from "mathjs";

import { LensGroup, Metadata } from "@/index";
import { Ref, computed, ref, watchEffect } from "vue";
import { queryOptionBurstsChartConfig, queryOptionCSVConfig } from "./cosmosQueries";

import { defineStore } from "pinia";
import { sleep } from "@/@use/libs/helpers";
import { useAPI } from "@use/useAPI";
import { useUiStore } from "@/@use/uiStore";

export const useMetadataStore = defineStore(
  "metadata",
  () => {
    const API = useAPI();
    const ui = useUiStore();

    const selectedMetadataF2B = ref();
    const metadataF2B = ref<any[]>([]);
    const selectedMetadata = ref();
    const selectedMetadataB2F = ref();
    const metadataB2F = ref<any[]>([]);
    const masterConfigurations = ref();
    const selectedMetadataGenericCustom = ref();
    const selectedMetadataGenericCSVCustom = ref();
    const selectedMetadataGenericBurstChartCustom = ref();
    const metadataGenericCustom = ref<any[]>([]);
    const physicalQuantities = ref();

    const localLensVariableForCurrentLensItem = ref([]);
    const _localLensVariableForCurrentLensItem = ref([]);
    const variableForAllLensItem = ref([]);
    const currentLensItemId = ref();

    const selectedDisplayedGenericVariablesChart = ref([]);
    const selectedDisplayedGenericVariablesCSV = ref([]);

    const allStatsNames = ref([
      { name: "min_data", disp_name: "Min" },
      { name: "max_data", disp_name: "Max" },
      { name: "peak_peak_data", disp_name: "Peak Peak" },
      { name: "skewness_data", disp_name: "Skewness" },
      { name: "rms_data", disp_name: "RMS" },
      { name: "avg_data", disp_name: "Average" },
      { name: "kurtosis_data", disp_name: "Kurtosis" },
      { name: "std_data", disp_name: "Standard Deviation" },
      { name: "peak_data", disp_name: "Peak" },
    ]);

    /**
     * Définit les metadata sélectionnées en fonction dulensGroups sélectionné.
     * @param selectedLensGroup LelensGroups sélectionné.
     */
    const setSelectedMetadata = (selectedLensGroup: LensGroup) => {
      selectedMetadata.value = selectedLensGroup?.lensGroup_data.metadata;
      physicalQuantities.value = setPhysicalQuantitiesCompile(selectedMetadata.value);
    };

    /**
     * Met à jour les metadata sélectionnées en fonction de la réponse reçue.
     * @param updatedMetadata Les metadata mises à jour.
     */
    const setSelectedMetadataByResponse = (updatedMetadata: Metadata) => {
      selectedMetadata.value = updatedMetadata;
      physicalQuantities.value = setPhysicalQuantitiesCompile(updatedMetadata);
    };

    /**
     * Récupère les metadata à partir du magasin en fonction de l'identifiant de l'emplacement.
     * @param locationId L'identifiant de l'emplacement.
     * @param metadataRef La référence des metadata (par défaut : metadataB2F.value).
     * @returns Les metadata correspondantes à l'identifiant de l'emplacement, ou null si aucune correspondance n'est trouvée.
     */
    const getMetadataFromStoreByLocationId = (locationId: string, metadataRef: any = metadataB2F.value) => {
      if (locationId) {
        return metadataRef.length > 0 ? metadataRef.find((meta: any) => meta.id.startsWith(locationId)) : null;
      }
    };

    /**
     * Récupère les metadata sélectionnées pour un emplacement donné.
     *
     * @param locationId - L'identifiant de l'emplacement.
     */
    const fetchSelectedMetadataB2F = async (locationId: string) => {
      if (locationId) {
        ui.setLoadingState(true);
        const found = getMetadataFromStoreByLocationId(locationId);
        if (!found) {
          const url = `configs/metadataB2F/${locationId}`;
          const response = (await API.getData(url)) as Ref<any>;
          if (response?.value) {
            selectedMetadataB2F.value = response?.value;
            metadataB2F.value.push(response?.value);
          }
        } else {
          selectedMetadataB2F.value = found;
        }
        ui.setLoadingState(false);
      }
    };
    /**
     * Récupère les metadata sélectionnées à partir de l'identifiant de l'emplacement.
     * @param locationId - L'identifiant de l'emplacement.
     */
    const fetchSelectedMetadataF2B = async (locationId: string) => {
      if (locationId) {
        ui.setLoadingState(true);
        const found = getMetadataFromStoreByLocationId(locationId, metadataF2B.value);
        if (!found) {
          const url = `configs/metadataF2B/${locationId}`;
          const response = (await API.getData(url)) as Ref<any>;
          if (response?.value) {
            selectedMetadataF2B.value = response?.value;
            metadataF2B.value.push(response?.value);
          }
        } else {
          selectedMetadataB2F.value = found;
        }
        ui.setLoadingState(false);
      }
    };
    /**
     * Récupère les options de configuration CSV à partir de Cosmos DB pour une location donnée.
     *
     * @param locationId - L'identifiant de la location pour laquelle récupérer les options de configuration CSV.
     */
    const fetchOptionsCSVConfigurations = async (locationId: string) => {
      if (locationId) {
        ui.setLoadingState(true);

        const partitionKey = "metadata";
        const cosmosQuery = queryOptionCSVConfig(locationId);
        //@ts-ignore
        const responses: Ref<any[]> = await API.fetchFromCosmosWithquery({
          partitionKey: partitionKey,
          cosmosQuery: cosmosQuery,
        });
        if (responses?.value?.length > 0) selectedMetadataGenericCSVCustom.value = responses.value[0];

        ui.setLoadingState(false);
      }
    };
    /**
     * Récupère les options de configuration du graphique des rafales à partir de l'emplacement spécifié.
     *
     * @param locationId - L'identifiant de l'emplacement.
     * @returns Une Promise qui se résout avec les options de configuration du graphique des rafales.
     */
    const fetchOptionsBurstsChartConfigurations = async (locationId: string) => {
      if (locationId) {
        ui.setLoadingState(true);
        const partitionKey = "metadata";
        const cosmosQuery = queryOptionBurstsChartConfig(locationId)
        //@ts-ignore
        const responses: Ref<any[]> = await API.fetchFromCosmosWithquery({
          partitionKey: partitionKey,
          cosmosQuery: cosmosQuery,
        });
        if (responses?.value?.length > 0) selectedMetadataGenericBurstChartCustom.value = responses.value[0];

        ui.setLoadingState(false);
      }
    };

    /**
     * Récupère les metadata personnalisées pour un emplacement spécifique et un type de données donné.
     * @param locationId - L'identifiant de l'emplacement.
     * @param dataType - Le type de données.
     */
    const fetchCustomMetadataForGeneric = async (locationId: string, dataType: string) => {
      if (locationId) {
        ui.setLoadingState(true);
        // const found = getMetadataFromStoreByLocationId(locationId, metadataGenericCustom.value);
        await fetchOptionsCSVConfigurations(locationId);
        await fetchOptionsBurstsChartConfigurations(locationId);
        await fetchSelectedMetadataF2B(locationId);
        // if (!found) {
        const url = `configs/metadataGeneric/${locationId}/${dataType}`;
        const response = (await API.getData(url)) as Ref<any>;

        if (response?.value) {
          selectedMetadataGenericCustom.value = response?.value;
          metadataGenericCustom.value.push(response?.value);
        }
        // }
        // else {
        //     selectedMetadataGenericCustom.value = found;
        //   }

        ui.setLoadingState(false);
      }
    };

    /**
     * Récupère les configurations principales.
     * @returns Une Promise qui se résout avec les configurations principales.
     */
    const fetchMasterConfigurations = async () => {
      const url = `configs/master_config`;
      const response = (await API.getData(url)) as Ref<any>;

      if (response?.value) {
        masterConfigurations.value = response?.value.masterConfigurations;
      }
    };

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });

    /**
     * Cette fonction prend en paramètre un tableau de données (datas) et une variable de lens (lensVariable).
     * Elle retourne un nouveau tableau contenant des informations sélectionnées à partir des données d'entrée.
     * Chaque élément du tableau de données d'entrée est transformé en un objet contenant les noms de données, les graphiques personnalisés,
     * le nom de la variable de lens correspondante et l'identifiant.
     *
     * @param datas - Le tableau de données d'entrée.
     * @param lensVariable - La variable de lens utilisée pour la sélection.
     * @returns Un tableau contenant les informations sélectionnées à partir des données d'entrée.
     */
    const selectedDisplayedChartGenericVariables = (datas: any, lensVariable: any) => {
      let vc_name: string = "";
      return datas?.map((option: any) => {
        const dataNames = option.allDataNames?.map((name: string) => {
          return lensVariable
            ?.map((v: any) => {
              return option?.customVariables[name]
                ?.map((vc: string) => {
                  if (vc === v.vc_name) {
                    const _name = name.split("_").join(" ");
                    vc_name = v.vc_name;
                    return { title: `${v.disp_name} - ${_name}`, stat: _name };
                  }
                })
                ?.filter((e: any) => !_.isEmpty(e));
            })
            .flat(1);
        });

        return {
          dataNames,
          customCharts: option.customCharts,
          customLimits: option.customLimits,
          vcName: vc_name,
          id: option.id,
        };
      });
    };

    const getLensItemFiltered = () => _localLensVariableForCurrentLensItem.value;

    /**
     * Rafraîchit le graphique des variables génériques affichées.
     *
     * Cette fonction met à jour le graphique des variables génériques affichées en fonction des données sélectionnées.
     *
     * @returns Le tableau des variables génériques affichées après la mise à jour.
     */
    const refreshDisplayedGenericVariablesChart = () => {
      const lensItem = selectedMetadata.value?.lens_items.find((item: any) => item.lens_id === selectedMetadataGenericCustom.value?.lens_id);
      if (lensItem) {
        currentLensItemId.value = lensItem.lens_id;

        localLensVariableForCurrentLensItem.value = selectedMetadata.value.lens_variables;
        variableForAllLensItem.value = selectedMetadata.value.lens_variables;

        const datas = selectedMetadataGenericCustom.value?.options?.sort((a: any, b: any) => {
          return collator.compare(a.id, b.id);
        });

        selectedDisplayedGenericVariablesChart.value = selectedDisplayedChartGenericVariables(datas, localLensVariableForCurrentLensItem.value).sort(
          (a: any, b: any) => (a.customCharts.chart_index > b.customCharts.chart_index ? 1 : -1)
        );
        return selectedDisplayedGenericVariablesChart.value;
      }
    };

    /**
     * Rafraîchit les variables génériques affichées du graphique.
     *
     * @returns Les variables génériques affichées du graphique.
     */
    const refreshDisplayedChartGenericVariables = () => {
      const lensItem = selectedMetadata.value?.lens_items.find((item: any) => item.lens_id === selectedMetadataGenericCSVCustom.value?.lens_id);
      if (lensItem) {
        currentLensItemId.value = lensItem.lens_id;

        localLensVariableForCurrentLensItem.value = selectedMetadata.value.lens_variables;
        variableForAllLensItem.value = selectedMetadata.value.lens_variables;

        const datas = selectedMetadataGenericCSVCustom.value?.options?.sort((a: any, b: any) => {
          return collator.compare(a.id, b.id);
        });

        if (!_.isEmpty(datas) && !_.isEmpty(localLensVariableForCurrentLensItem.value))
          selectedDisplayedGenericVariablesCSV.value = selectedDisplayedChartGenericVariables(datas, localLensVariableForCurrentLensItem.value);
        return selectedDisplayedGenericVariablesCSV.value;
      }
    };

    const reloadDisplayedGeneric = () => {
      selectedDisplayedGenericVariablesChart.value = [];
      selectedDisplayedGenericVariablesCSV.value = [];

      return {
        chart: selectedDisplayedGenericVariablesChart.value,
        csv: selectedDisplayedGenericVariablesCSV.value,
      };
    };

    /**
     * Filtre les variables locales pour l'élément de lens actuel en fonction de la quantité physique spécifiée.
     * @param physicalQ La quantité physique utilisée pour filtrer les variables locales.
     * @returns Les variables locales filtrées pour l'élément de lens actuel.
     */
    const setFilterLensVariableForCurrentLensItem = (physicalQ: string) => {
      if (physicalQ) {
        return (_localLensVariableForCurrentLensItem.value = localLensVariableForCurrentLensItem.value?.filter((v: any) => {
          return v.physical_quantity?.toLowerCase() === physicalQ?.toLowerCase();
        }));
      }
    };

    /**
     * Récupère l'unité externe associée à un canal virtuel spécifié.
     *
     * @param vc_name - Le nom du canal virtuel.
     * @returns L'unité externe associée au canal virtuel spécifié, ou undefined si aucune unité n'est trouvée.
     */
    const getUnitByVirtualChannel = (vc_name: string) => {
      const _var = selectedMetadata.value.lens_variables?.find((p: any) => p.vc_name === vc_name);
      const res = _var && physicalQuantities.value.find((p: any) => p.type === _var?.physical_quantity);
      return res && res.external_unit;
    };

    const createNewMetadata = async (data: any) => {
      ui.setLoadingState(true);
      if (data.meta) {
        await API.postData("configs/upsertCosmosData", data.meta);
        await sleep(100);
      }
      if (data.csv) {
        await API.postData("configs/upsertCosmosData", data.csv);
        await sleep(100);
      }
      if (data.chart) {
        await API.postData("configs/upsertCosmosData", data.chart);
        await sleep(100);
      }

      ui.setLoadingState(false);
    };

    watchEffect(() => {
      refreshDisplayedChartGenericVariables();
      refreshDisplayedGenericVariablesChart();
    })

    // const lensVariableForCurrentLensItem = computed(() => {
    //   if (selectedMetadataGenericCSVCustom.value || selectedMetadataGenericCustom.value) {
    //     return localLensVariableForCurrentLensItem.value;
    //   }
    // });

    return {
      physicalQuantities,
      fetchCustomMetadataForGeneric,
      fetchOptionsBurstsChartConfigurations,
      fetchMasterConfigurations,
      fetchOptionsCSVConfigurations,
      fetchSelectedMetadataB2F,
      getMetadataFromStoreByLocationId,
      metadataB2F,
      metadataGenericCustom,
      setSelectedMetadata,
      setSelectedMetadataByResponse,
      setFilterLensVariableForCurrentLensItem,
      allStatsNames,
      masterConfigurations: computed(() => masterConfigurations.value),
      selectedMetadata: computed(() => selectedMetadata.value),
      selectedMetadataB2F: computed(() => selectedMetadataB2F.value),
      selectedMetadataGenericCSVCustom: computed(() => selectedMetadataGenericCSVCustom.value),
      selectedMetadataGenericCustom: computed(() => selectedMetadataGenericCustom.value),
      lensVariableForCurrentLensItem: computed(() => localLensVariableForCurrentLensItem.value),
      variableForAllLensItem,
      selectedDisplayedGenericVariablesChart,
      selectedDisplayedGenericVariablesCSV,
      selectedMetadataGenericBurstChartCustom,
      currentLensItemId,
      selectedMetadataF2B,
      reloadDisplayedGeneric,
      createNewMetadata,
      getUnitByVirtualChannel,
      getLensItemFiltered,
    };
  },
  {
    persist: false,
  }
);

/**
 * Compile les quantités physiques dans l'état de metadata.
 *
 * @param state L'état de metadata contenant les quantités physiques.
 * @returns Un tableau des quantités physiques compilées.
 */
function setPhysicalQuantitiesCompile(state: Metadata) {
  if (!_.isEmpty(state.physical_quantities)) {
    return state.physical_quantities.map((ph) => {
      return {
        external_unit: ph.external_unit,
        internal_unit: ph.internal_unit,
        type: ph.type,
        user_decimal_digits: ph.user_decimal_digits,
        f_to_external_unit: math.compile(ph.f_to_external_unit),
        f_to_internal_unit: math.compile(ph.f_to_internal_unit),
      };
    });
  }
}
