import { setformType, titleCase } from "./helpers";

import { FieldObject } from "@/index";
import { v4 as uuidv4 } from "uuid";

//  On convertit un object comme les métadata en champs de formulaire
/**
 * Formatte les données d'un objet en un format adapté pour les formulaires.
 * @param formObject L'objet contenant les données du formulaire.
 * @param toHide Les clés à masquer dans le formulaire (facultatif).
 * @param options Les options de formatage (facultatif).
 * @param options.valueIsTitle Indique si la valeur doit être utilisée comme titre (facultatif, par défaut à false).
 * @returns Un tableau d'objets formatés pour les formulaires.
 */
export const formatToForms = (formOject: any, toHide: string[] = [],{ valueIsTitle = false}) => {
  const _toHide = toHide?.concat("id");
  // On prend chaque entrée qu'on transforme en liste
  return (
    Object.entries(formOject)
      ?.filter((it) => {
        return !_toHide.includes(it[0]);
      })
      // on applique les modifs suivantes
      ?.map((el) => {
        return {
          id: uuidv4(),
          key: el[0],
          value: el[1],
          title: valueIsTitle === true ? titleCase(el[1] as string) : titleCase(el[0]),
          type: setformType(el[1]),
        };
      })
  );
};

// Convertion des retour du formulaire dynamic en object directement comprise par le serveur
/**
 * Compose an object into a valid form object.
 *
 * @param formObject - The object to be composed into a valid form object.
 * @returns The composed form object.
 */
export const composeObjectToValidForm = (formObject: any) => {

  return Object.values(formObject)
    ?.filter((el: any) => !!el.key || !!el.parentRef)
    ?.map((obj: any) => {
      if (obj.key != null) {
        return { [obj.key]: obj.value };
      }
    })
    ?.reduce((obj, item) => {
      return {
        ...obj,
        ...item,
      };
    }, {});
};

/**
 * Compose un objet en une forme brute.
 *
 * @param formObject L'objet du formulaire à composer.
 * @returns L'objet du formulaire sous forme brute.
 */
export const composeObjectToRawForm = (formObject: any) => {
  return Object.values(formObject)
   // .filter((el: any) => !!el.key || !!el.parentRef)
};
