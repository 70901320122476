export default {
  common: {
    bursts: "Bursts",
    statistics: "Statistics",
    deviceInfo: "Device Info",
    burstCalendar: "Bursts events calendar",
    statCalendar: "Statistics calendar",
    burstList: "Bursts list",
    burstOf: "Burst of",
    statOf: "Statistics of",
    to: "to",
    archiveFiles: "Archive Files",
    reportFiles: "Reports files",
    notifLog: "Notification's logs",
    generateReport: "Generate reports",
    active: "Active",
    disabled: "Disabled",
    cloudStorage: "Save to the cloud",
    password: "Password",
    updateFilter: "Update filter",
    saveFilter: "Save filter",
    applyFilter: "Filter",
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday",
    startDate: "Starting date",
    endDate: "End date",
    exportCSV: "Export to CSV",
  },
  auth: {
    signin: "Sign in to your account",
    login: "Login",
    register: "Register a new account",
    signup: "Sign up",
    forgetPass: "Forgot Password ?",
    loggedAs: "Logged as",
    logout: "Logout",
    resetPass: "Reset Password",
    recover: "Send Recovery Email",
    relogin: "",
    or: "Or",
    contact: "contact us",
    username: "Username",
    fullname: "Full name",
    email: "Email address",
    password: "Password",
    passwordConfirm: "Confirm Password",
    hasAMCODE: "I have a validation code",
  },
  setting: {
    settings: "Settings",
    recipientList: "Recipient list",
    addNew: "Add new",
    recipient: "Recipient",
    device: "Device",
    planning: "Planning",
  },
  ui: {
    edit: "Edit",
    save: "Save",
    back: {
      all: "Back to all",
      list: "List",
    },
    get: {
      all: "Get all",
    },
    delete: "Delete",
    cancel: "Cancel",
    reload: "Reload",
    download: "Download",
    createdOn: "Created On",
    name: "Name",
    level: "Level",
    datetime: "Datetime",
    company: "Enterprise",
    yes: "Yes",
    no: "No",
    select: "Select",
    updatePlanning: "Update planning",
    active: "Active",
    inactive: "Inactive",
    email: "Email",
    phone: "Phone",
    event_base_report: "Event base report",
    notification: "Notification",
    technical_alarm: "Technical alarm",
    sms: "SMS",
    measurement: "Measurement",
    sms_notification: "SMS notification",
    email_notification: "Email notification",
    notification_configuration: "Notification configuration",
    notification_recipient: "Notification recipient",
  },
  group: {
    limit: "limites",
    label: "labels",
    name: "name",
    anomaly: "anomaly",
    process: "Processus",
    measGroup: "measurement group",
    measRecipient: "measurement recipient",
    measNotif: "measurement notification",
    measNotifLabel: "measurement notification label",
    measProcess: "measurement process",
    notifRecipient: "notification's recipient",
    measPlanning: "measurement planning",
  },
  modal: {
    deleteUser: "Delete user from the application",
    deleteOrganization: "Delete organization from the application",
    deleteOrganizationMsg: "Are you sure you want to delete this organization? All of your data will be permanently removed. This action cannot be undone.",
    deleteUserRecipient: "Delete this entry from the recipient list",
    deleteUserRecipientConfirm: "Are you sure you want to delete this entry?",
    deleteAlarmRecipient: "Delete this alarm",
    deleteConfirmMsg: "All of your data will be permanently removed. This action cannot be undone.",
  },
  chart: {
    din4150: "DIN-4150-3 chart",
  },
  device: {
    disconnected: "Disconnected",
    connected: "Connected",
    contractNumber: "Contract number",
  },
  hover: {
    annomalyDetectionEnabled: "Annomaly detection enabled",
  },
  configuration: {
    manageOrg: "Manage organizations",
    manageUser: "Manage users",
    manageMeta: "Manage metadata",
    manageProfile: "Manage your profile",
    manageRecipient: "Manage your recipient list",
    createOrg: "Create new organization",
    createUser: "Add user",
    globalMap: "All devices in maps",
    allMeasurement: "All user measurement",
  },
  tables: {
    download: "Download",
    show: "Show",
    name: "Name",
    createdOn: "Created on",
    edit: "Edit",
    delete: "Delete",
    comment: "Comment",
    burst: {
      visible: "Visible",
      notification_level: "Notification level",
      datetime: "Datetime",
      pvs_ppv: "PVS",
      pvs_time: "PVS time [s]",
      ppv_tran: "PPV-TRAN",
      freq: "freq [Hz]",
      ppv: "ppv [mm/s]",
      time: "time [s]",
    },
  },
};
