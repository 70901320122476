<template>
  <div>
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-start space-x-5">
        <div class="flex-shrink-0">
          <div class="relative">
            <slot name="image"> <!-- image size:  w-16 h16 --></slot>
          </div>
        </div>

        <div class="pt-1.5">
          <h1 class="text-2xl font-bold text-gray-900 uppercase">
            {{ title }}
          </h1>
          <p class="text-sm font-medium text-gray-500 text-blue-500">
            {{ subtitle }}
          </p>
        </div>
      </div>

      <div
        class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"
      >
        <button
          type="button"
          class="uppercase inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          @click="clearData()"
          :class="{ isDisabled: isInLoadingState }"
          v-bind:disabled="isInLoadingState"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
            ></path></svg
          ><span class="ml-4 flex items-start flex-col leading-none">Back to main</span>
        </button>

        <slot name="button"></slot>
      </div>
    </div>
    <hr class="my-8" />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUiStore } from "@use/uiStore";
import { storeToRefs } from "pinia";

const router = useRouter();
const ui = useUiStore();

const { isInLoadingState } = storeToRefs(ui);

const clearData = () => {
  router?.push({ name: "adminIndex" });
};

const props = defineProps<{
  title: String;
  subtitle: String;
}>();
</script>

<style scoped>
.isDisabled {
  background-color: #bfbfbf;
  cursor: not-allowed;
}
</style>
