import * as _ from "lodash";

import { h } from "vue";

export const renderRecipients = (cell: any[], recipients: any[], key: string) => {
  return _.isArray(cell) && cell.length > 0
    ? h(
        "div",
        { className: "" },
        cell.sort()?.map((val: string) => {
          const _rec = recipients?.find((el: any) => el[key] === val);
          const display = _rec ? _rec.name : "-/-";
          return h("span", { class: "my-2 mx-1 px-4 py-1 bg-green-200 shadow-xs" }, display + " ");
        })
      )
    : h("span", { class: "my-2 mx-1 px-4 py-1 bg-green-200 shadow-xs" }, "-/-");
};

export const renderActionButtons = (row: any, emit: any) => {
  return h("div", [
    h(
      "button",
      {
        className: `m-auto inline-block px-4 py-1  bg-blue-200 rounded shadow-xs cursor-pointer hover:bg-blue-500 hover:text-gray-100 text-sm mx-2`,
        onClick: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          emit("tableData:toggle-edit-modal", row);
        },
      },
      "edit"
    ),
    h(
      "button",
      {
        className: `m-auto inline-block px-4 py-1  bg-red-200 rounded shadow-xs cursor-pointer hover:bg-red-500 hover:text-gray-100 text-sm mx-2`,
        onClick: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          emit("tableData:toggle-delete-modal", row);
        },
      },
      "delete"
    ),
  ]);
};
