import { ChartEntity, GraphData, StatDataForLineMultiplesAxe } from "@/index";
import { Ref, reactive, ref, watch } from "vue";
import { graphListFormatterGenericBurst, graphListFormatterGenericBurstFFT } from "@/@core/charts/dataFormatters/burstGenericDataChartFormatter";

import { ConfigEnums } from "@/config/configEnum";
import CryptoJS from "crypto-js";
import { defineStore } from "pinia";
import { graphListFormatterBurst } from "@core/charts/dataFormatters/burstDataChartFormatter";
import { graphListFormatterGenericStats } from "@/@core/charts/dataFormatters/statGenericMultipleDataFormatter";
import { useAPI } from "@use/useAPI";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { useRoute } from "vue-router";

export const useGraphStore = defineStore(
  "graph",
  () => {
    const route = useRoute();
    const API = useAPI();
    const metadata = useMetadataStore();

    const graphData: GraphData = reactive({ entities: {}, queryIds: [] });
    const graphCurrent = ref<ChartEntity[] | StatDataForLineMultiplesAxe>();

    const addChartObjectToEntities = ({ entity, queryId }: { entity: ChartEntity[] | StatDataForLineMultiplesAxe; queryId: string }) => {
      //  on crée un object avec le MD5 comme id
      const entityWithId = { [queryId]: entity };

      if (!graphData?.queryIds?.includes(queryId)) {
        // on ajoute le MD5 dans la liste des ids pour être facilement trouvable
        graphData?.queryIds?.push(queryId);
        graphData.entities = { ...graphData.entities, ...entityWithId };
      }
    };

    /**
     * Récupère l'objet de graphique correspondant à l'identifiant de requête spécifié.
     *
     * @param queryId L'identifiant de la requête pour laquelle récupérer l'objet de graphique.
     * @returns L'objet de graphique correspondant à l'identifiant de requête spécifié, ou null si l'identifiant de requête n'est pas trouvé.
     */
    const getChartObjectToEntities = (queryId: string) => {
      if (graphData?.queryIds?.includes(queryId)) {
        // @ts-ignore
        return graphData.entities[queryId];
      } else return null;
    };

    // const fetchStatsFromCosmos = async (cosmosQuery: string, partitionKey: string) => {
    //   const stats = (await API.fetchFromCosmosWithquery({
    //     partitionKey: partitionKey,
    //     cosmosQuery: cosmosQuery,
    //   })) as Ref<any>;
    //   if (stats?.value && stats?.value.length > 0) return stats.value;
    // };

    /**
     * Récupère les rafales de données à partir de Cosmos DB.
     *
     * @param cosmosQuery La requête à exécuter dans Cosmos DB.
     * @param partitionKey La partitionKey à utiliser pour la requête.
     * @returns La première rafale de données récupérée, ou undefined si aucune rafale n'est trouvée.
     */
    const fetchBurstsFromCosmos = async (cosmosQuery: string, partitionKey: string) => {
      const bursts = (await API.fetchFromCosmosWithquery({
        partitionKey: partitionKey,
        cosmosQuery: cosmosQuery,
      })) as Ref<any>;
      if (bursts?.value) return bursts.value[0];
    };

    /**
     * Récupère les statistiques génériques à partir de Cosmos DB.
     *
     * @param cosmosQuery La requête à exécuter dans Cosmos DB.
     * @param partitionKey La partitionKey à utiliser lors de la requête.
     * @returns Les statistiques génériques récupérées à partir de Cosmos DB.
     */
    const fetchGenericStatsFromCosmos = async (cosmosQuery: string, partitionKey: string) => {
      const genStats = (await API.fetchFromCosmosWithquery({
        partitionKey: partitionKey,
        cosmosQuery: cosmosQuery,
      })) as Ref<any>;
      if (genStats?.value && genStats?.value.length > 0) return genStats.value;
    };

    /**
     * Ajoute une requête au graphique.
     *
     * @param query - La requête à exécuter.
     * @param formatter - Le formatteur à utiliser pour formater les données du graphique.
     * @param lensInfos - Les informations sur les lens.
     * @param partitionKey - La partitionKey.
     * @param isToday - Indique si la requête est pour aujourd'hui (facultatif, par défaut false).
     */
    const addToGraph = async ({
      query,
      formatter,
      lensInfos,
      partitionKey,
      isToday = false,
    }: {
      query: string;
      formatter: string;
      lensInfos: any;
      partitionKey: string;
      isToday?: boolean;
    }) => {
      const md5 = CryptoJS.MD5(query).toString();
      const queryId = isToday ? `today-query-${md5}${Math.random() * 1000}` : md5;

      if (queryId !== ConfigEnums.TODAY_QUERY && graphData?.queryIds?.includes(queryId)) {
        // @ts-ignore
        const data = graphData?.entities[queryId];
        graphCurrent.value = data;
      } else {
        if (graphCurrent.value && !isToday) {
          const dataToCache = { entity: graphCurrent.value, queryId };
          addChartObjectToEntities(dataToCache);
        } else if (formatter === "bursts") {
          const burst = await fetchBurstsFromCosmos(query, partitionKey);
          if (burst) {
            // @ts-ignore
            graphCurrent.value = graphListFormatterBurst(burst, lensInfos);
          }
        }
        // else if (formatter === "statistics") {
        //   const stats = await fetchStatsFromCosmos(query, partitionKey);
        //   if (stats) {
        //     // @ts-ignore
        //     graphCurrent.value = graphListFormatterStats(stats, lensInfos);
        //   }
        // } else if (formatter === "generic-statistics") {
        //   await metadata.fetchCustomMetadataForGeneric(partitionKey, "statistics");
        //   const genStats = await fetchGenericStatsFromCosmos(query, partitionKey);
        //   if (genStats && metadata.selectedMetadataGenericCustom) {
        //     graphCurrent.value = metadata.selectedMetadataGenericCustom.options
        //       .map((conf: any) => graphListFormatterGenericStats(genStats, lensInfos, conf))
        //       .flat(1);
        //   }
        // }
      }
    };

    /**
     * Définit le graphique Burst de lens sélectionné actuellement.
     *
     * @param {Object} options - Les options pour définir le graphique.
     * @param {string} options.query - La requête pour le graphique.
     * @param {string} options.formatter - Le formateur pour le graphique.
     * @param {any} options.lensInfos - Les informations sur les lens.
     * @param {string} options.partitionKey - La partitionKey pour le graphique.
     * @param {boolean} [options.isToday=false] - Indique si le graphique est pour aujourd'hui.
     * @returns {any} Le résultat de l'ajout au graphique.
     */
    const setSelectedLensBurstGraphCurrent = ({
      query,
      formatter,
      lensInfos,
      partitionKey,
      isToday = false,
    }: {
      query: string;
      formatter: string;
      lensInfos: any;
      partitionKey: string;
      isToday?: boolean;
    }) => {
      return addToGraph({ query, formatter, lensInfos, partitionKey, isToday });
    };

    /**
     * Définit le graphique actuel pour le graphique générique Burst de lens sélectionné.
     *
     * @param {Object} options - Les options pour définir le graphique.
     * @param {string} options.query - La requête pour récupérer les rafales.
     * @param {string} options.formatter - Le formatteur à utiliser pour formater les données du graphique.
     * @param {any} options.lensInfos - Les informations sur les lens.
     * @param {any} options.lensChartOptions - Les options du graphique des lens.
     * @param {string} options.partitionKey - La partitionKey.
     * @returns {Promise<void>} - Une Promise qui se résout lorsque le graphique actuel est défini.
     */
    const setSelectedLensBurstGenericGraphCurrent = async ({
      query,
      formatter,
      lensInfos,
      lensChartOptions,
      partitionKey,
    }: {
      query: string;
      formatter: string;
      lensInfos: any;
      lensChartOptions: any;
      partitionKey: string;
    }) => {
      const md5 = CryptoJS.MD5(query).toString();
      const queryId = `burst-${md5}`;

      const graphInCache = getChartObjectToEntities(queryId);
      if (graphInCache) {
        graphCurrent.value = graphInCache;
      } else {
        const burst = await fetchBurstsFromCosmos(query, partitionKey);
        if (burst) {
          if (formatter === "generic-bursts") {
            graphCurrent.value = graphListFormatterGenericBurst({ burst, lensInfos, lensChartOptions, computeFFT: false, useVCNames: false });
            const dataToCache = { entity: graphCurrent.value!, queryId };
            addChartObjectToEntities(dataToCache);
          } else if (formatter === "module-bursts-din41503" || formatter === "module-bursts-din41503-zc") {
            graphCurrent.value = graphListFormatterGenericBurst({ burst, lensInfos, lensChartOptions, computeFFT: false, useVCNames: true });
            const dataToCache = { entity: graphCurrent.value!, queryId };
            addChartObjectToEntities(dataToCache);
          } else if (formatter === "module-bursts-din41503-fft") {
            const raw = graphListFormatterGenericBurst({ burst, lensInfos, lensChartOptions: lensChartOptions.raw, computeFFT: false, useVCNames: true });
            const fft = graphListFormatterGenericBurstFFT({ burst, lensInfos, lensChartOptions: lensChartOptions.fft, computeFFT: true, useVCNames: false });

            graphCurrent.value = [...raw, ...fft];
            const dataToCache = { entity: graphCurrent.value!, queryId };

            addChartObjectToEntities(dataToCache);
          }
        }
      }
    };

    /**
     * Définit les statistiques graphiques actuelles pour plusieurs objectifs sélectionnés.
     *
     * @param query - La requête utilisée pour récupérer les données statistiques.
     * @param formatter - Le formatteur utilisé pour formater les données statistiques.
     * @param lensInfos - Les informations sur les objectifs.
     * @param partitionKey - La partitionKey utilisée pour récupérer les données.
     * @param isToday - Indique si la requête est pour les données d'aujourd'hui.
     * @returns Une Promise qui se résout lorsque les statistiques graphiques actuelles sont définies.
     */
    const setSelectedLensStatsGenericMultipleGraphCurrent = async ({
      query,
      formatter,
      lensInfos,
      partitionKey,
      isToday = false,
    }: {
      query: string;
      formatter: string;
      lensInfos: any;
      partitionKey: string;
      isToday: boolean;
    }) => {
      const modulesNames = ["generic-statistics"];

      if (modulesNames.includes(formatter)) {
        const md5 = CryptoJS.MD5(query).toString();
        const queryId = isToday ? `today-query-${md5}${Math.random() * 1000}` : md5;

        const graphInCache = getChartObjectToEntities(queryId);
        if (graphInCache) {
          graphCurrent.value = graphInCache;
        } else {
          const genStats = await fetchGenericStatsFromCosmos(query, partitionKey);
          await metadata.fetchCustomMetadataForGeneric(partitionKey, "statistics");

          if (genStats && metadata.selectedMetadataGenericCustom) {
            graphCurrent.value = metadata.selectedMetadataGenericCustom.options
              .map((conf: any) => graphListFormatterGenericStats(genStats, lensInfos, conf))
              .flat(1);

            const dataToCache = { entity: graphCurrent.value!, queryId };
            addChartObjectToEntities(dataToCache);
          }
        }
      }
    };

    /**
     * Définit les statistiques de la lens sélectionnée pour plusieurs graphiques actuels.
     *
     * @param {Object} options - Les options pour définir les statistiques de la lens sélectionnée.
     * @param {string} options.query - La requête pour récupérer les données des statistiques.
     * @param {string} options.formatter - Le formatteur pour formater les données des statistiques.
     * @param {any} options.lensInfos - Les informations de la lens.
     * @param {string} options.partitionKey - La partitionKey pour les données des statistiques.
     * @param {boolean} options.isToday - Indique si les statistiques sont pour aujourd'hui.
     * @returns {any} Les résultats de l'ajout au graphique.
     */
    const setSelectedLensStatsMultipleGraphCurrent = ({
      query,
      formatter,
      lensInfos,
      partitionKey,
      isToday = false,
    }: {
      query: string;
      formatter: string;
      lensInfos: any;
      partitionKey: string;
      isToday: boolean;
    }) => {
      return addToGraph({ query, formatter, lensInfos, partitionKey, isToday });
    };

    const clearCurrent = () => {
      graphCurrent.value = undefined;
    };
    // when we change params
    watch(
      () => route?.params,
      (params) => {
        clearCurrent();
      }
    );

    const reset = () => {
      clearCurrent();
      graphData.entities = {};
      graphData.queryIds = [];
    };

    return {
      graphData,
      clearCurrent,
      graphCurrent,
      addToGraph,
      setSelectedLensBurstGraphCurrent,
      setSelectedLensStatsMultipleGraphCurrent,
      setSelectedLensStatsGenericMultipleGraphCurrent,
      setSelectedLensBurstGenericGraphCurrent,
      reset,
    };
  },
  {
    persist: false,
  }
);
