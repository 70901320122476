<script lang="ts" setup>
import { computed } from "vue";
import BurstsListTableDin4150 from "@/@core/tables/modules/BurstsListTableDin4150.vue";

const emit = defineEmits(["tableData:update"]);

const props = defineProps<{
  tableData: any[];
  applicationType: any;
  module: any;
}>();

const getCurrentBurstTable = computed(() => {
  if (props.applicationType.toLowerCase() === "module-bursts-din41503" || props.applicationType.toLowerCase() === "module-bursts-din41503-zc") return BurstsListTableDin4150;
  if (props.applicationType.toLowerCase() === "module-bursts-din41503-fft") return BurstsListTableDin4150;
});
</script>
<template>
  <div class="container mx-auto items-center px-5 py-24">
    <component v-if="module && props.tableData" :is="getCurrentBurstTable" :key="props.applicationType" :tableData="props.tableData" @tableData:update="emit('tableData:update')"></component>
  </div>
</template>
