import * as _ from "lodash";

import { LensChart, Limit, PhysicalQuantity } from "@/index";

import { findDisplayNameByChannelNameChart } from "../dataFormatters/shared/findDisplayNameByChannelName";
import { helpers } from "@use/libs/helpers";
import moment from "moment-timezone";

export const defaultTz = import.meta.env.VITE_DEFAULT_TIMEZONE;

export const hiddenNamesConfig = [];
const countZeroNumber = (value: number) => 10 ** (`${value}`.length - 1);

const _lensParametersNotifications = [
  {
    name: "Limit 1",
    value: 0.7,
    is_default_level: true,
    is_display: true,
    color: "red",
    decimal_digits: 2,
  },
  {
    name: "Limit 2",
    value: 0.5,
    is_default_level: true,
    is_display: true,
    color: "blue",
    decimal_digits: 1,
  },
  {
    name: "Limit 3",
    value: 0.6,
    is_default_level: true,
    is_display: true,
    color: "green",
    decimal_digits: 3,
  },
];















/**
 * Configuration for common line settings.
 * @returns {Object} The common line configuration object.
 */
export const commonLineConfig = () => {
  return {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#1a202c",
      },
    },
    axisTick: {
      alignWithLabel: true,
    },
    nameTextStyle: {
      fontSize: 14,
      align: "left",
      fontWeight: "bold",
    },
    splitLine: {
      show: true,
      width: 0.4,
      lineStyle: {
        type: "solid",
        opacity: 0.6,
      },
    },
  };
};

/**
 * Fonction qui étend le formatteur de valeur en fonction des paramètres spécifiés.
 * @param {Object} params - Les paramètres de la fonction.
 * @param {any} params.axisDimension - La dimension de l'axe.
 * @param {any} params.value - La valeur à formater.
 * @param {PhysicalQuantity} params.physicalQ - L'objet représentant la quantité physique.
 * @param {number} params.dsf - Le facteur d'échelle.
 * @param {string} params.type - Le type de formatage.
 * @param {string} [params.tz=defaultTz] - Le fuseau horaire (optionnel, valeur par défaut: defaultTz).
 * @returns {string} La valeur formatée.
 */
export const extendFormatter = ({
  axisDimension,
  value,
  physicalQ,
  dsf = 1000,
  type,
  tz = defaultTz,
}: {
  axisDimension: any;
  value: any;
  physicalQ: PhysicalQuantity;
  dsf: number;
  type: string;
  tz?: string;
}) => {
  if (axisDimension === "x") {
    if (type === "burst") return `Time: ${(parseFloat(value) / countZeroNumber(dsf))?.toFixed(1)} s`;
    if (type === "burst-fft") return `Frequency: ${parseFloat(value).toFixed(2)} Hz`;
    else return `Time: ${moment(value).tz(tz).format("HH:mm:ss")}`;
  }
  if (axisDimension === "y") {
    return `${parseFloat(value)?.toFixed(physicalQ.user_decimal_digits || 3)} ${physicalQ.external_unit}`;
  }
};

/**
 * Calcule et formate la valeur de temps en fonction des paramètres spécifiés.
 * @param {Object} params - Les paramètres de la fonction.
 * @param {string} params.tz - Le fuseau horaire utilisé pour formater le temps.
 * @param {string} params.axisValue - La valeur de l'axe utilisée pour calculer le temps.
 * @param {string} params.type - Le type de graphique.
 * @param {number | null} params.dsf - Le facteur d'échantillonnage.
 * @returns {string} La valeur de temps formatée.
 */
const timeRez = ({ tz, axisValue, type, dsf }: { tz: string; axisValue: string; type: string; dsf: number | null }) => {
  let time;

  if (type === "burst") time = `${(parseFloat(axisValue) / countZeroNumber(dsf!))?.toFixed(1)} s`;
  if (type === "burst-fft") time = `${parseFloat(axisValue).toFixed(2)} Hz`;
  else
    time = moment(axisValue)
      .tz(tz)
      .format("HH:mm:ss" || import.meta.env.VITE_DEFAULT_TIME_FORMAT);
  // la première ligne correspondant à l'axe X
  return `<p><strong>${type === "burst-fft" ? "Frequency" : "Time"}:</strong> ${time} </p>`;
};

/**
 * Fonction qui étend le formatteur de tooltip.
 *
 * @param params - Les paramètres du tooltip.
 * @param physicalQ - La quantité physique.
 * @param variables - Les variables.
 * @param tz - Le fuseau horaire (par défaut: defaultTz).
 * @param type - Le type.
 * @param dsf - Le dsf.
 * @returns Le formatteur de tooltip étendu.
 */
export const extendTooltipFormatter = ({
  params,
  physicalQ,
  variables,
  tz = defaultTz,
  type,
  dsf,
}: {
  params: any;
  physicalQ: PhysicalQuantity;
  variables: any;
  dsf: number;
  type: any;
  tz?: string;
}) => {
  // Pour chaque point de couleur
  const colorSpan = (color: string) =>
    `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>`;

  let rez = timeRez({ tz, type, dsf, axisValue: params[0].axisValue });

  // on boucle puis on ajoute le reste à la première ligne qui correspond à l'axe Y
  params.forEach((item: any, i: number) => {
    const calculedY =
      typeof physicalQ.f_to_external_unit.evaluate === "function"
        ? physicalQ.f_to_external_unit.evaluate({ x: parseFloat(item.data[item.seriesName]) }).toFixed(physicalQ.user_decimal_digits || 3)
        : parseFloat(item.data[item.seriesName]).toFixed(physicalQ.user_decimal_digits || 3);

    const name = type === "burst" || type === "burst-fft" ? item.seriesName : findDisplayNameByChannelNameChart(variables, item.seriesName).dispName;

    if (i < params.length - hiddenNamesConfig.length) {
      var xx = `<p> ${colorSpan(item.color)} <strong>${_.upperCase(name)}:</strong> ${calculedY} ${physicalQ.external_unit}</p>`;
      rez += xx;
    }
  });
  return rez;
};

/**
 * Fonction qui retourne les options de tooltip pour un graphique.
 * @param physicalQ - La quantité physique associée au graphique.
 * @param type - Le type de graphique.
 * @param dsf - Le facteur d'échelle du graphique.
 * @param variables - Les variables associées au graphique.
 * @returns Les options de tooltip pour le graphique.
 */
export const tooltip = ({ physicalQ, type, dsf, variables }: { physicalQ: PhysicalQuantity; type: any; dsf: number; variables: any }) => {
  return {
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => extendTooltipFormatter({ params, physicalQ, variables, type, dsf }),
      axisPointer: {
        animation: false,
        type: "cross",
        lineStyle: {
          color: "#376df4",
          width: 1,
          opacity: 1,
        },
        label: {
          formatter: ({ axisDimension, value }: { axisDimension: any; value: any }) =>
            extendFormatter({
              axisDimension,
              value,
              physicalQ,
              dsf,
              type,
            }),
        },
      },
    },
  };
};

/**
 * Fonction qui retourne la configuration de la boîte à outils des graphiques.
 * @returns La configuration de la boîte à outils des graphiques.
 */
export const toolbox = () => {
  return {
    toolbox: {
      show: true,
      right: "1px",
      top: "1px",
      feature: {
        dataZoom: {
          title: {
            zoom: "Zoom",
            back: "Zoom Reset",
          },
        },
        restore: {
          title: "Restore",
        },
        saveAsImage: {
          title: "Save as image",
        },
      },
    },
  };
};

/**
 * Crée une ligne de marque à partir des limites spécifiées.
 *
 * @param limites - Les limites à partir desquelles créer la ligne de marque.
 * @returns Un tableau d'objets représentant les lignes de marque.
 */
export const createMarkLineFrom = (limites: Limit[] = []) => {
  if (!_.isEmpty(limites)) {
    return limites
      ?.filter((ls: any) => ls.is_display)
      .map((l: any, _) => {
        return {
          lineStyle: {
            color: l.color,
            opacity: 0.6,
          },
          label: {
            formatter: l.name,
            position: "insideEndTop",
          },
          yAxis: parseFloat(l.value.toFixed(l.decimal_digits)),
        };
      });
  }
};

/**
 * Crée un objet de configuration pour les séries de graphiques.
 * @param chart - L'objet de configuration du graphique.
 * @param keys - Les clés des séries.
 * @param limites - Les limites des séries.
 * @returns Un objet de configuration des séries de graphiques.
 */
export const series = ({ chart, keys, limites }: { chart?: LensChart; keys: string[]; limites?: Limit[] }) => {
  const res = {
    series: keys.map((key: string) => ({
      name: key,
      type: "line",
      showSymbol: false,
      markLine:
        // chart?.is_markline === true
        //   ?
        {
          silent: true,
          data: createMarkLineFrom(limites),
          symbol:['none', 'none']
        },
      // : null,
    })),
  };
  return res;
};

/**
 * Calcule les valeurs minimales et maximales pour la plage Y en fonction du graphique donné.
 * @param chart Le graphique LensChart.
 * @returns Un objet contenant les fonctions min et max pour calculer les valeurs minimales et maximales respectivement.
 */
export const hasMinMaxYRange = ({ chart }: { chart: LensChart }) => {
  return {
    /**
     * Calcule la valeur minimale pour la plage Y.
     * @param value La valeur minimale actuelle.
     * @returns La valeur minimale calculée.
     */
    min: function (value: any) {
      if (value.min < chart.y_range[0]) {
        const res = value.min + value.min / 15;
        return helpers.toDecimals(res, 2);
      } else {
        return helpers.toDecimals(chart.y_range[0], 2);
      }
    },
    /**
     * Calcule la valeur maximale pour la plage Y.
     * @param value La valeur maximale actuelle.
     * @returns La valeur maximale calculée.
     */
    max: function (value: any) {
      if (value.max > chart.y_range[1]) {
        const res = value.max + value.max / 15;
        return helpers.toDecimals(res, 2);
      } else {
        return helpers.toDecimals(chart.y_range[1], 2);
      }
    },
  };
};

/**
 * Fonction qui retourne les paramètres par défaut pour le dataZoom.
 * @returns Les paramètres par défaut pour le dataZoom.
 */
export const dataZoomDefault = () => {
  return {
    dataZoom: [
      {
        type: "slider",
        show: true,
        xAxisIndex: [0],
        filterMode: "none",
        bottom: "1%",
      },
      {
        type: "slider",
        show: true,
        yAxisIndex: [0],
        filterMode: "none",
        right: "0%",
      },
      {
        type: "inside",
        filterMode: "none",
        xAxisIndex: [0],
      },
      {
        type: "inside",
        filterMode: "none",
        yAxisIndex: [0],
      },
    ],
  };
};
/**
 * Fonction qui retourne les options de zoom par défaut pour les graphiques.
 * @returns Les options de zoom par défaut.
 */
export const dataZoomStats = () => {
  return {
    dataZoom: [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "none",
        bottom: "170px",
      },
      {
        id: "dataZoomY",
        type: "slider",
        yAxisIndex: [0],
        filterMode: "none",
        right: "10px",
      },
    ],
  };
};
/**
 * Fonction qui retourne les paramètres par défaut pour le zoom des données.
 * @returns Les paramètres de zoom des données.
 */
export const dataZoomBursts = () => {
  return {
    dataZoom: [
      {
        type: "slider",
        show: true,
        xAxisIndex: [0],
        filterMode: "none",
        bottom: "10px",
      },
      {
        type: "slider",
        show: true,
        yAxisIndex: [0],
        filterMode: "none",
        right: "5px",
        bottom: "140px",
      },
    ],
  };
};

/**
 * Fonction de formatage de l'étiquette de l'axe.
 *
 * @param value La valeur à formater.
 * @param physicalQ L'objet représentant la quantité physique associée à la valeur.
 * @returns La chaîne de caractères formatée de l'étiquette de l'axe.
 */
export const axisLabelFormatter = (value: any, physicalQ: any) => {
  if (physicalQ) {
    const fQ =
      typeof physicalQ.f_to_external_unit.evaluate === "function"
        ? physicalQ.f_to_external_unit.evaluate({ x: value }).toFixed(physicalQ.user_decimal_digits)
        : value;
    return `${fQ} ${physicalQ.external_unit}`;
  }
  return `${value}`;
};
