<template>
  <div>
    <v-chart class="chart" :option="chartOptions" />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";

import { LensVariable } from "@/index";
import { createMultipleAxesScatterPlotSeries } from "../normes/createScatterPlot";
import { Np2074_L1_curve, Np2074_L2_curve, Np2074_L3_curve } from "../normes/np2074";
import { burstListLogTooltip, burstListLogXAxis, burstListLogYAxis } from "../settings/chartListLogSettings";

const props = defineProps<{
  lensItem: any;
  tableData: any[];
  groupBy: any;
}>();

// @ts-ignore
import { use } from "echarts/core";
// @ts-ignore
import { CanvasRenderer } from "echarts/renderers";
// @ts-ignore
import {
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  // @ts-ignore
} from "echarts/charts";

// @ts-ignore
import {
  GridComponent,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
  // @ts-ignore
} from "echarts/components";

// @ts-ignore
import VChart, { THEME_KEY } from "vue-echarts";

use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent,
]);

const createMultipleAxesLineSeries = () => {
  if (props.lensItem && props.lensItem.lens_variables) {
    const table = props.tableData?.filter((el) => el.visible !== false);
    const scatterSeries = createMultipleAxesScatterPlotSeries(table, props.groupBy, props.lensItem);

    return [
      {
        type: "line",
        name: "Reforçadas",
        symbolSize: 0,
        data: Np2074_L1_curve,
        smooth: false,
        color: "green",
      },
      {
        type: "line",
        name: "Correntes",
        color: "red",
        symbolSize: 0,
        data: Np2074_L2_curve,
        smooth: false,
      },
      {
        type: "line",
        name: "Sensiveis",
        symbolSize: 0,
        data: Np2074_L3_curve,
        smooth: false,
        color: "blue",
      },
      ...scatterSeries,
    ];
  }
};

const chartOptions = reactive({
  tooltip: burstListLogTooltip(),
  legend: {
    data: computed(() => {
      if (props.lensItem) {
        return props.lensItem.lens_variables && props.lensItem.lens_variables.map((vr: LensVariable) => vr).disp_name;
      }
    }),
    left: "center",
    bottom: 10,
  },
  title: {
    text: "Norma NP 2074 - 2015",
    left: "center",
  },
  grid: {
    left: "3%",
    right: "7%",
    bottom: "7%",
    containLabel: true,
  },
  xAxis: burstListLogXAxis(),
  yAxis: burstListLogYAxis(),
  scatterSeries: null,
  series: computed(() => createMultipleAxesLineSeries()),
});
</script>

<style scoped>
.chart {
  height: 650px;
  min-width: 764px;
}
</style>
