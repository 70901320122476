import * as _ from "lodash";

import { LensChart, LensVariable, PhysicalQuantity } from "@/index";
import { axisLabelFormatter, commonLineConfig, dataZoomBursts, defaultTz, hasMinMaxYRange, series, toolbox, tooltip } from "./defaultSettings";

/**
 * Définit les options de l'axe Y pour le graphique Burst.
 * @param chart - Le graphique LensChart.
 * @param type - Le type de graphique.
 * @param physicalQ - La quantité physique.
 * @param usePhysicalQuantity - Indique si la quantité physique doit être utilisée. Par défaut, true.
 * @returns Les options de l'axe Y pour le graphique Burst.
 */
export const YBurstAxis = ({
  chart,
  type,
  physicalQ,
  usePhysicalQuantity = true,
}: {
  chart: LensChart;
  type: string;
  physicalQ: PhysicalQuantity;
  usePhysicalQuantity?: boolean;
}) => {
  return {
    yAxis: [
      {
        type: "value",
        name: _.upperCase(chart.y_label),
        ...commonLineConfig(),
        ...hasMinMaxYRange({ chart }),
        axisLabel: {
          formatter: (value: any) => axisLabelFormatter(value, usePhysicalQuantity === true ? physicalQ : null),
        },
      },
    ],
  };
};

/**
 * Définit les paramètres de l'axe X pour le graphique Burst.
 * @param timeUnit L'unité de temps utilisée sur l'axe X (par défaut: "ms").
 * @param dsf Le facteur d'échelle de la fréquence (par défaut: 1000).
 * @returns Les options de configuration de l'axe X.
 */
export const XBurstAxis = ({ timeUnit = "ms", dsf = 1000 }) => {
  return {
    xAxis: {
      type: "category",
      name: timeUnit === "ms" ? "Time" : "Frequency",
      ...commonLineConfig(),
      axisLabel: {
        formatter: function (value: any) {
          return timeUnit === "ms" ? `${(value / dsf).toFixed(1)}s` : `${parseFloat(value).toFixed(1)}`;
        },
        align: "right",
        showMinLabel: true,
        showMaxLabel: true,
      },
    },
  };
};

/**
 * Configuration du graphique Burst.
 * @param {Object} options - Les options de configuration du graphique Burst.
 * @param {PhysicalQuantity} options.physicalQ - La quantité physique associée au graphique.
 * @param {string[]} options.keys - Les clés des données à afficher dans le graphique.
 * @param {LensChart} options.chart - L'objet LensChart contenant les informations sur le graphique.
 * @param {any} options.info - Les informations supplémentaires pour le graphique.
 * @param {string} options.type - Le type de graphique.
 * @param {LensVariable[]} options.variables - Les variables Lens à utiliser dans le graphique.
 * @param {boolean} [options.usePhysicalQuantity=true] - Indique si la quantité physique doit être utilisée.
 * @returns {Object} - La configuration du graphique Burst.
 */
export const burstChartConfig = ({
  physicalQ,
  keys,
  chart,
  info,
  type,
  variables,
  usePhysicalQuantity = true,
}: {
  physicalQ: PhysicalQuantity;
  keys: string[];
  chart: LensChart;
  info: any;
  type: string;
  variables: LensVariable[];
  usePhysicalQuantity?: boolean;
}) => {
  const tz = defaultTz;
  return {
    title: {
      text: _.upperCase(chart.title),
      left: "center",
      top: "12%",
      textStyle: {
        fontSize: 25,
      },
    },

    legend: {
      type: "plain",
      data: keys.map((key: string) => key),
      // formatter: function (value: string) {
      //   return _.upperCase(value)
      // },
      bottom: "60px",
    },

    grid: {
      left: "120px",
      right: "100px",
      top: "100px",
      bottom: "120px",
      containLabel: false,
    },
    ...tooltip({ physicalQ, type, dsf: info.frequencyPerSecond, variables }),
    ...dataZoomBursts(), // BUG : on dataZoomDefault
    ...toolbox(),
    ...XBurstAxis({ timeUnit: type === "burst-fft" ? "Hz" : "ms", dsf: info.frequencyPerSecond }),
    ...YBurstAxis({ chart, type, physicalQ, usePhysicalQuantity }),
    ...series({ keys: keys }),
  };
};
