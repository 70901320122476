export const optionsRoutes = [
  {
    path: `/admin/lens/options/stats/chart/:locationId`,
    name: `admin-lens-options-stats-chart-id`,
    component: () => import("./stats/ChartOption.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/admin/lens/options/stats/variable/:locationId`,
    name: `admin-lens-options-stats-variable-id`,
    component: () => import("./stats/VariableOption.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/admin/lens/options/burst/:locationId`,
    name: `admin-lens-options-burst-id`,
    component: () => import("./bursts/BurstOption.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/admin/lens/options/csv/:locationId`,
    name: `admin-lens-options-csv-id`,
    component: () => import("./csv/OptionCSV.vue"),
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
];
