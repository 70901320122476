<template>
  <section class="text-gray-700 body-font relative mb-4 px-16">
    <form class="bg-white px-4 flex flex-row space-x-8 container mx-auto items-center rounded-md">
      <div class="mb-4 w-3/4">
        <section class="flex flex-row space-x-4">
          <div class="w-1/2">
            <span class="block text-gray-600 text-sm text-left font-bold mb-2">{{ $t("common.startDate") }}</span>

            <v-date-picker
              class="inline-block h-full w-full"
              v-model="range.start"
              mode="dateTime"
              :timezone="timezone"
              :masks="masks"
              :locale="locale"
              :is24hr="true"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center">
                  <button
                    class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                    @click.prevent.stop="togglePopover({ autoHide: true })"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
                      <path
                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </button>
                  <input
                    :value="inputValue"
                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                    readonly
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="w-1/2">
            <span class="block text-gray-600 text-sm text-left font-bold mb-2">{{ $t("common.endDate") }}</span>
            <v-date-picker
              class="inline-block h-full w-full"
              v-model="range.end"
              mode="dateTime"
              :timezone="timezone"
              :masks="masks"
              :locale="locale"
              :is24hr="true"
              :min-date="range.start"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center">
                  <button
                    class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                    @click.prevent.stop="togglePopover({ autoHide: true })"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
                      <path
                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </button>
                  <input
                    :value="inputValue"
                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                    readonly
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </section>
      </div>
      <button
        @click.prevent.stop="applyFilter"
        :class="{ isDisabled: !range.end }"
        class="px-4 w-1/4 py-2 mt-8 m-4 text-md font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
      >
        {{ $t("common.applyFilter") }}
      </button>
    </form>
  </section>
</template>
<script>
import * as _ from "lodash"

export default {
  props: {
    tz: {
      type: String,
      default: "Europe/Brussels",
    },
    locale: {
      type: String,
      default: "fr-FR",
    },
    startEndTodayDate: {
      startingDate: {
        type: String,
        default: "",
      },
      endDate: {
        type: String,
        default: "",
      },
    },
  },
  data() {
    return {
      range: {
        start: "",
        end: "",
      },
      masks: {
        input: "DD-MMM-YYYY",
      },
    };
  },
  mounted() {},

  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"].SimpleCalendar;
    },
    getEnumerations() {
      return this.$store.getters["selectEnumerations"];
    },
    getSelectedLens() {
      return this.$store.getters["lens/selectedLens"].lens;
    },
    getLocationPoint() {
      return this.getSelectedLens.lens_cosmos_id ? this.getSelectedLens.lens_cosmos_id.split("-")[0] : "";
    },
    getMetadataConfig() {
      return this.$store.getters["meta/getMetadataBypoint"](this.getSelectedLens.lens_metadata_id);
    },
    timezone() {
      return this.tz;
    },
    locale() {
      return this.locale;
    },
  },
  methods: {
    applyFilter() {
      let start, end;

      if (typeof this.range.start === "string") {
        start = this.range.start;
      } else {
        start = this.range.start.toISOString();
      }
      if (typeof this.range.end === "string") {
        end = this.range.end;
      } else {
        end = this.range.end.toISOString();
      }
      this.$emit("selected-range", { start, end });
    },
  },
};
</script>
<style scoped>
.isDisabled {
  background-color: #bfbfbf;
  cursor: not-allowed;
}
</style>
