<script setup lang="ts">
import { useRouter } from "vue-router";

import { requestModeler } from "@/config/requestModeler";
import BurstMultiChartsSwitcher from "@core/charts/BurstMultiChartsSwitcher.vue";
import NormeMultiChartsSwitcher from "@core/charts/NormeMultiChartsSwitcher.vue";
import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import BaseCalendar from "@core/components/calendar/BaseCalendar.vue";
import BurstListTable from "@core/tables/BurstsListTable.vue";
import DoubleDatetimePicker from "@/@core/components/calendar/DoubleDatetimePicker.vue";
import { computed, onMounted, ref, onUpdated } from "vue";

import { useConfigStore } from "@/config/configStore";
import { QueryParam } from "@/index";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { useBurstDataStore } from "@modules/admin/store/useBurstDataStore";
import { useDateTimeStore } from "@use/useDatetime";

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const datetime = useDateTimeStore();
const configs = useConfigStore().enumerationData;
const burstData = useBurstDataStore();
const graph = useGraphStore();

const partitionKey = "burst-list-log";

const id = ref(`${lens.selectedLensItems?.lens_id}-${partitionKey}`);

const locationPoint = computed(() => lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id as string);
const deviceTz = computed(() => lensGroup.selectedLensGroup?.lensGroup_data.metadata.timezone_javascript as string);

const getBurstListFromCalendar = computed(() => {
  return lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_SELECTED_DATE_LOG);
});

const getAllBurstFromListLog = computed(() => {
  return lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.BURST_ALL_DATE_LOG);
});

const getStartEndDatePicker = computed(() => {
  return datetime.helpers?.startEnd24hourFromDate(datetime.helpers?.today);
});

/**
 * Fonction qui récupère la liste des journaux de rafales en fonction de la date de début, de la date de fin et de l'identifiant.
 *
 * @param {string} start - La date de début au format string.
 * @param {string} end - La date de fin au format string.
 * @param {any} id - L'identifiant.
 * @returns {Promise<void>} - Une Promise qui ne renvoie rien.
 */
const _fetchBurstListLogByDate = async (start: string, end: string, id: any) => {
  if (getBurstListFromCalendar.value && id) {
    // on modifies les paramètres dans la requete venant de l'API avec les valeurs courantes
    const paramsList = [
      {
        key: configs.cosmosQueryParamsEnum.STARTING_DATE,
        value: start,
      },
      {
        key: configs.cosmosQueryParamsEnum.END_DATE,
        value: end,
      },
      {
        key: configs.cosmosQueryParamsEnum.ID,
        value: id,
      },
    ];
    //  On recompose la requete avec les vraies valeurs
    const query = requestModeler(paramsList, getBurstListFromCalendar.value.query_content);
    await burstData.fetchSelectedLensBurstTable({ query, partitionKey, id: id.value });
  }
};

/**
 * Récupère les éléments du calendrier depuis Cosmos de manière asynchrone.
 * @param {any} $event - L'événement déclencheur.
 * @returns {Promise<void>} Une Promise qui se résout lorsque les éléments sont récupérés avec succès.
 */
const fetchFromCalendarCosmosElements = async ($event: any) => {
  // si on click sur un calendrier vide on vide le tout

  const { startingDate, endDate } = datetime.helpers.startEnd24hourFromDate(
    $event.date,
    lensGroup.selectedLensGroup?.lensGroup_data.metadata.timezone_javascript
  );

  await _fetchBurstListLogByDate(startingDate, endDate, id.value);
};

/**
 * Cette fonction récupère toutes les rafales de la liste de journaux.
 * Elle utilise les valeurs de getAllBurstFromListLog et id pour construire une requête.
 * La date de début est extraite des metadata de lensGroup.selectedLensGroup.
 * La fonction appelle ensuite la méthode fetchSelectedLensBurstTable de burstData pour récupérer les données de la rafale sélectionnée.
 */
const fetchAllBurstFromListLog = async() => {
  if (getAllBurstFromListLog.value && id.value) {
    const startingDate = lensGroup.selectedLensGroup?.lensGroup_data.metadata.activation_datetime;

    const paramsList = [
      {
        key: configs.cosmosQueryParamsEnum.ID,
        value: id.value,
      },
      {
        key: configs.cosmosQueryParamsEnum.STARTING_DATE,
        value: startingDate,
      },
    ] as QueryParam[];
    const query = requestModeler(paramsList, getAllBurstFromListLog.value?.query_content);

   await burstData.fetchSelectedLensBurstTable({ query, partitionKey, id: id.value });
  }
};

const fetchBurstListLogByStartEnd = ($event: any) => {
  _fetchBurstListLogByDate($event.start, $event.end, id.value);
};

/**
 * Fonction pour recharger les données.
 * Cette fonction met à jour les éléments sélectionnés de la lens, récupère tous les groupes de lens de l'utilisateur,
 * sélectionne les groupes de lens correspondants à l'emplacement actuel, puis récupère toutes les rafales à partir du journal de liste.
 * @returns {Promise<void>} Une Promise qui se résout lorsque le rechargement est terminé.
 */
const reload = async () => {
  lens.setSelectedLensItems({ cosmosId: router.currentRoute.value.params.cosmosId, locationId: router.currentRoute.value.params.locationId });
  await lensGroup.fetchUserAllLensGroup();
  lensGroup.setSelectedLensGroups(router.currentRoute.value.params.locationId as string);
  await fetchAllBurstFromListLog();
};

onMounted(async () => {
  id.value = `${lens.selectedLensItems?.lens_id}-${partitionKey}`;
 await fetchAllBurstFromListLog();
});

onUpdated(() => (id.value = `${lens.selectedLensItems?.lens_id}-${partitionKey}`));
</script>

<template>
  <div>
    <LensItemsPageHeader :imageName="'bursts'" @refech-all="reload" />
    <BaseCalendar
      v-if="lensGroup.selectedLensGroup && lens.selectedLensItems?.lens_id"
      :key="lens.selectedLensItems?.lens_id"
      :notifParams="lens.selectedLensItems?.lens_parameters_notification"
      :locationPoint="locationPoint"
      :deviceTz="deviceTz"
      :dataType="lens.selectedLensItems?.data_type"
      @day-click="fetchFromCalendarCosmosElements"
    />
    <div v-if="lensGroup.selectedLensGroup?.lensGroup_application_type">
      <NormeMultiChartsSwitcher
        v-if="Array.isArray(burstData.burstTable) && lens?.selectedLensItems && lens?.selectedLensItems?.burst_list_log_group_by"
        :lensItem="lens?.selectedLensItems"
        :tableData="burstData?.burstTable"
        :applicationType="lensGroup.selectedLensGroup?.lensGroup_application_type"
        :lensLogGroup="lens?.selectedLensItems?.burst_list_log_group_by"
      />
      <DoubleDatetimePicker
        @selected-range="fetchBurstListLogByStartEnd"
        :tz="datetime.helpers?.timezone"
        locale="fr-FR"
        :startEndTodayDate="getStartEndDatePicker"
      />
      <div class="container px-2 py-4 mx-auto">
        <BurstListTable v-if="Array.isArray(burstData.burstTable)" :tableData="burstData.burstTable" />
        <BurstMultiChartsSwitcher :chartList="graph.graphCurrent" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
