<script setup lang="ts">
import { computed, ref } from "vue";

import BaseForm from "@core/components/dynamicForms/BaseForm.vue";

import { formatToForms } from "@core/components/dynamicForms/utils/inputHelpers";
import * as _ from "lodash";
import { phone } from "phone";

import { findAndMergeWith } from "@use/libs/filterAndMergeWith";

//@ts-ignore
import * as EmailValidator from "email-validator";

//@ts-ignore
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-center",
});

const props = defineProps<{
  recipientObjectData: any;
  isNewRecipient: Boolean;
}>();
const emit = defineEmits(["submit-form"]);

const createOrgFormValue = ref({});
const formId = ref("");
const updatedFormatOrgToForms = ref({});
const formatOrgToForms = computed({
  get() {
    // return formatToForms(props.recipientObjectData, ["deletedAt"], {
    //   valueIsTitle: false,
    // });
    return !_.isEmpty(updatedFormatOrgToForms.value)
      ? updatedFormatOrgToForms.value
      : formatToForms(props.recipientObjectData, ["deletedAt"], {
          valueIsTitle: false,
        });
  },
  set(msg: any) {
    const form = formatToForms(props.recipientObjectData, ["deletedAt"], {
      valueIsTitle: false,
    });
    if (msg && msg.error) {
      //@ts-ignore
      updatedFormatOrgToForms.value = findAndMergeWith(form, { error: msg.error }, { key: msg.key });
    } else {
      updatedFormatOrgToForms.value = form;
    }

    return updatedFormatOrgToForms.value;

    // Note: the return value is the final value after edit
  },
});

function recompose(newValue: any) {
  createOrgFormValue.value = { ...createOrgFormValue.value, ...newValue };
}

function recomposeValue($event: any) {
  recompose($event);
}

function create() {
  const dataToSend = { ...props.recipientObjectData, ...createOrgFormValue.value };
  let hasError = false;

  if (!_.isEmpty(dataToSend.email) && EmailValidator.validate(dataToSend.email)) {
    dataToSend.email = dataToSend.email.toLowerCase().trim();
    hasError = false;
  } else if (dataToSend.email === "-/-") {
    toaster.info(`Empty email address`);
  } else {
    // hasError = true;
    // formatOrgToForms.value = { error: "Invalid email address", key: "email" };

    dataToSend.email = "-/-".toLowerCase().trim();
    toaster.error(`Invalid email address`);
  }

  if (!_.isEmpty(dataToSend.phone) && phone(dataToSend.phone).isValid) {
    dataToSend.phone = phone(dataToSend.phone).phoneNumber;
    hasError = false;
  } else if (dataToSend.phone === "-/-") {
    toaster.info(`Empty phone number`);
  } else {
    // hasError = true;
    // formatOrgToForms.value = { error: "Invalid phone number", key: "phone" };

    dataToSend.phone = "-/-".toLowerCase().trim();
    toaster.error(`Invalid phone number`);
  }
  hasError ? null : emit("submit-form", dataToSend);
}
</script>
<style>
.size {
  width: 500px;
}
</style>

<template>
  <div v-if="recipientObjectData" class="flex pb-8">
    <div class="flex-col flex ml-auto mr-auto items-center w-full lg:w-2/3 md:w-3/5">
      <h1 class="text-2xl mt-8">
        {{ isNewRecipient ? "Add ne recipient" : "Update recipient" }}
      </h1>
      <form @submit.prevent="create" class="flex flex-col space-y-5 size" v-if="!_.isEmpty(formatOrgToForms)">
        <BaseForm :formSchema="formatOrgToForms" @form-value-updated="recomposeValue($event)" :key="formId" />
        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
          >
            {{ isNewRecipient ? "Create" : "Update" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
