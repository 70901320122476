// @ts-ignore
import { createToaster } from "@meforma/vue-toaster";
import { defineStore } from "pinia";
import { promiseTimeout } from "@vueuse/core";
import { ref } from "vue";

export const useUiStore = defineStore("ui", () => {
  const isInLoadingState = ref<boolean>(false);
  const isColdStart = ref<boolean>(true);
  const isRecreateLensGroup = ref<boolean>(false);
  const isToasterDebounceError = ref<boolean>(false);

  const toaster = createToaster({
    position: "top-right",
  });

  const setLoadingState = async (val: boolean) => {
    isInLoadingState.value = val;
  };

  const setColdStartState = async (val: boolean) => {
    await promiseTimeout(1600);
    isColdStart.value = val;
  };

  const setRecreateLensGroup = async (val: boolean) => {
    await promiseTimeout(1600);
    isRecreateLensGroup.value = val;
  };

  const displayError = debounce((message: string) => toaster.error(message));

  return {
    isInLoadingState,
    isColdStart,
    isRecreateLensGroup,
    setLoadingState,
    setColdStartState,
    setRecreateLensGroup,
    displayError,
  };
});

// @ts-ignore
function debounce(func, timeout = 1200) {
  let timeoutId: any = null;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}
