export const measurementPlanning = (location_id = "") => ({
  id: `${location_id}-measurement-planning`,
  partitionKey: "measurement-planning",
  sunday: [
    {
      start: "00:00",
      stop: "23:59",
      id: "392dfd4d-6fc1-4659-85c5-d27f6b87bf3",
    },
  ],
  monday: [
    {
      start: "00:00",
      stop: "23:59",
      id: "392dfd4d-6fc1-4659-85c5-d27f6bd87fc3",
    },
  ],
  tuesday: [
    {
      start: "00:00",
      stop: "23:59",
      id: "66392dfd4d-6fc1-4659-85c5-d27f6b87fc3",
    },
  ],
  wednesday: [
    {
      start: "00:00",
      stop: "23:59",
      id: "392dzdfd4d-6fc1-4659-85c5-d27f6b87fc3",
    },
  ],
  thursday: [
    {
      start: "00:00",
      stop: "23:59",
      id: "392dfd4d-6fc1-4v659-85c5-d27f6b87fc3",
    },
  ],
  friday: [
    {
      start: "00:00",
      stop: "23:59",
      id: "392dfd4d-6fc1-4659-85c675-d27f6b87fc3",
    },
  ],
  saturday: [
    {
      start: "00:00",
      stop: "23:59",
      id: "392dfd4d-68fc1-4659-85c5-d27f6b87fc3",
    },
  ],
});
