export const queryDeviceTwinsMeta = (locationId: string) => `SELECT * FROM c WHERE c.id = "${locationId}-metadata-device-twins"`;
export const queryModuleAlarmRules = (moduleName: string) => `SELECT * FROM c WHERE c.id = "${moduleName}-alarm-rules"`;
export const queryAlarmStatsLog = (locationId: string) => `SELECT * FROM c WHERE c.id = "${locationId}-generic-stat-alarm-logs"`;
export const queryAlarmStatsRule = (locationId: string) => `SELECT * FROM c WHERE c.id = "${locationId}-generic-stat-alarm-rules"`;
export const queryAlarmBurstLog = (locationId: string) => `SELECT * FROM c WHERE c.id = "${locationId}-generic-burst-alarm-logs"`;
export const queryAlarmBurstRule = (locationId: string) => `SELECT * FROM c WHERE c.id = "${locationId}-generic-burst-alarm-rules"`;
export const queryOptionCSVConfig = (locationId: string) => `SELECT * FROM c WHERE c.id = "${locationId}-metadata-generic-csv-statistics-options"`;


export const queryPlanning = (id: string) => `SELECT * FROM c WHERE c.id = "${id}-measurement-planning"`;
export const queryBurstListLogNotes = (logId: string) => `SELECT * FROM c WHERE c.id = "${logId}"`;

// CEt element est toujours créer manuellement dans le cosmos
export const queryOptionBurstsChartConfig = (locationId: string) => `SELECT * FROM c WHERE c.id = "${locationId}-metadata-generic-chart-bursts-options"`;
