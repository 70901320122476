<template>
  <div class="container px-4 py-4 mx-auto">
    <MainHeading :title="title" :subtitle="description">
      <template v-slot:image> <img class="w-16 h-16" :src="`/img/${imageName}.svg`" :alt="imageName" :title="imageName" /></template>
      <template v-slot:button>
        <button
          type="button"
          class="uppercase inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          @click="reFetchAll"
          :class="{ isDisabled: isInLoadingState }"
          v-bind:disabled="isInLoadingState"
          const
          isInLoadingState="ref(false);"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>

          <span class="ml-4 flex items-start flex-col leading-none">Reload</span>
        </button>
      </template>
    </MainHeading>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onUnmounted } from "vue";
import MainHeading from "@core/shared/ui/headings/MainHeading.vue";

import { useUiStore } from "@use/uiStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";
import { useGraphStore } from "@modules/admin/store/graphStore";

import { storeToRefs } from "pinia";
const props = defineProps<{
  imageName: string;
  page?: string;
}>();

const emit = defineEmits(["refech-all"]);

const ui = useUiStore();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const graph = useGraphStore();

const { selectedLensGroup } = storeToRefs(lensGroup);
const { selectedLensItems } = storeToRefs(lens);

const { isInLoadingState } = storeToRefs(ui);
const reloadOnce = ref(false);

const title = computed(() => `${selectedLensGroup.value?.lensGroup_name} `);
const description = computed(
  () =>
    `${selectedLensGroup.value?.lensGroup_description} | ${
      props.page ? props.page : selectedLensItems.value?.lens_description ? selectedLensItems.value?.lens_description : ""
    }`
);
/**
 * Souscrit à l'état de l'interface utilisateur et exécute une action lorsque l'état de chargement est activé.
 * Si l'état de chargement est activé et que le rechargement n'a pas encore été effectué, efface le graphique actuel.
 * @param {Object} mutation - La mutation qui a été effectuée.
 * @param {Object} state - L'état actuel de l'interface utilisateur.
 */
const unsubscribe$ = ui.$subscribe((mutation, state) => {
  if (state.isInLoadingState === true && reloadOnce.value === false) {
    graph.graphCurrent ? graph.clearCurrent() : null;
  }
});

/**
 * Émet un événement pour recharger toutes les données et met à jour l'état de chargement de l'interface utilisateur.
 * @param {any} $event - L'événement déclencheur.
 */
function reFetchAll($event: any) {
  emit("refech-all", $event);
  ui.setLoadingState(true);
  reloadOnce.value = false;
}

onUnmounted(() => unsubscribe$());
</script>
<style scoped>
.isDisabled {
  background-color: #bfbfbf;
  cursor: not-allowed;
}
</style>
