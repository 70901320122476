import * as _ from "lodash";

import { computed, onMounted, onUnmounted, onUpdated, ref } from "vue";

import { ColumnTable } from "@/index";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";

export default defineStore("tableData", () => {
  const router = useRouter();

  const rows = ref([]);
  const columns = ref<ColumnTable[]>();
  const mainKey = ref<string>();
  const options = ref<any>({});

  const updatedData = ref<any>([]);
  const _tempData = ref<any>([]);

  /**
   * Initialise la table avec les colonnes, les lignes, la clé principale et les options spécifiées.
   *
   * @param {Object} params - Les paramètres d'initialisation de la table.
   * @param {Array} params.columns - Les colonnes de la table.
   * @param {Array} params.rows - Les lignes de la table.
   * @param {string} params.mainKey - La clé principale utilisée pour identifier chaque ligne de la table.
   * @param {Object} params.options - Les options supplémentaires pour la table.
   */
  const init = ({ columns = [], rows = [], mainKey = "", options = {} }) => {
    rows = rows;
    columns = columns;
    mainKey = mainKey;
    options = options;
  };

  /**
   * Met à jour les données en fonction de l'interrupteur.
   * Si l'élément existe déjà dans les données mises à jour, il est supprimé.
   * Sinon, il est ajouté aux données mises à jour.
   *
   * @param update - L'objet de mise à jour contenant les informations à ajouter ou supprimer.
   */
  const updateDataFromSwitch = (update: any) => {
    const index = updatedData.value.findIndex((item: any) => item.name === update.name);
    if (index > -1) {
      updatedData.value.splice(index, 1);
    } else {
      updatedData.value.push(update);
    }
  };
  /**
   * Met à jour les données en fonction des événements.
   *
   * @param key - La clé de l'événement.
   * @param update - La mise à jour à appliquer.
   */
  const updateDataFromEvents = (key: string, update: any) => {
    switch (key) {
      case "update:switch":
        return updateDataFromSwitch(update);

      default:
        return;
    }
  };

  /**
   * Définit les données initiales pour un filtre de type switch.
   *
   * @param _rows - Les lignes de données à filtrer.
   * @param switchFilterName - Le nom du filtre de type switch.
   */
  const setInitialSwitchData = (_rows: any, switchFilterName: string) => {
    _rows.forEach((row: any, index: number) => {
      if (row[switchFilterName] === false) {
        updatedData.value.push(row);
      }
    });
  };

  onUnmounted(() => {
    rows.value = [];
    updatedData.value = [];
  });

  router?.beforeEach(async (to, from, next) => {
    if (from.name !== to.name) {
      rows.value = [];
      updatedData.value = [];
    }
    next();
  });

  return {
    rows,
    columns,
    updateDataFromEvents,
    updatedData: computed(() => _.uniqBy(updatedData.value, "name")),
    init,
    setInitialSwitchData,
  };
});
