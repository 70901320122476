import * as _ from "lodash"

const limit41503Interpolation = (f, ref_level) => {
  const line1_ref_level = 20.0 // level_0
  const line1_slope1 = 0.5 // mm/s per Hz = (40-20) / (50-10)
  const line1_slope2 = 0.2 // mm/s per Hz = (50-40) / (100-50)

  const line2_ref_level = 5.0 // level_0
  const line2_slope1 = 0.25 // mm/s per Hz = (15-5) / (50-10)
  const line2_slope2 = 0.1 // mm/s per Hz = (20-15) / (100-50)

  const line3_ref_level = 3.0 // level_0
  const line3_slope1 = 0.125 // mm/s per Hz = (8-3) / (50-10)
  const line3_slope2 = 0.04 // mm/s per Hz = (10-8) / (100-50)

  let ratio
  let slope1
  let slope2

  let level

  if (ref_level < line3_ref_level) {
    ratio = ref_level / line3_ref_level
    slope1 = ratio * line3_slope1
    slope2 = ratio * line3_slope2
  } else if (ref_level < line2_ref_level) {
    ;(ratio = (ref_level - line3_ref_level) / (line2_ref_level - line3_ref_level)),
      (slope1 = line3_slope1 + ratio * (line2_slope1 - line3_slope1)),
      (slope2 = line3_slope2 + ratio * (line2_slope2 - line3_slope2))
  } else if (ref_level < line1_ref_level) {
    ratio = (ref_level - line2_ref_level) / (line1_ref_level - line2_ref_level)
    slope1 = line2_slope1 + ratio * (line1_slope1 - line2_slope1)
    slope2 = line2_slope2 + ratio * (line1_slope2 - line2_slope2)
  } else {
    ratio = (ref_level - line1_ref_level) / line1_ref_level
    slope1 = line1_slope1 + ratio * line1_slope1
    slope2 = line1_slope2 + ratio * line1_slope2
  }

  // 0_constant
  if (f < 10) {
    level = ref_level
  }
  // 1_slope
  else if (f < 50) {
    level = ref_level + (f - 10) * slope1
  }
  // 2_slope
  else if (f < 100) {
    level = ref_level + (50 - 10) * slope1 + (f - 50) * slope2
  }
  // 3_constant
  else {
    level = ref_level + (50 - 10) * slope1 + (100 - 50) * slope2
  }
  return level
}

export const din41503_L1_curve = _.range(0, 101, 1).map((point) => [point, limit41503Interpolation(point, 20)])
export const din41503_L2_curve = _.range(0, 101, 1).map((point) => [point, limit41503Interpolation(point, 5)])
export const din41503_L3_curve = _.range(0, 101, 1).map((point) => [point, limit41503Interpolation(point, 3)])
