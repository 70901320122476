import { Ref, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { defineStore } from "pinia";
import { useAPI } from "@use/useAPI";

const refetchRoutes = [
  "adminIndex",
  "admin-lens-bursts-id",
  "admin-lens-statistics-id",
  "admin-lens-generic-bursts-id",
  "admin-lens-generic-statistics-id",
  "admin-lens-bursts-modular-id",
];

export const useConfigStore = defineStore(
  "configData",
  () => {
    const API = useAPI();
    const enumerationData = ref();
    const route = useRoute();
    const router = useRouter();

    /**
     * Récupère toutes les configurations.
     * Cette fonction effectue les étapes suivantes :
     * 1. Appelle l'API pour obtenir les données des énumérations.
     * 2. Stocke les données des énumérations dans la variable enumerationData.
     */
    const fetchAllConfig = async () => {
      const response = (await API.getData("configs/enumerations")) as Ref<any>;
      enumerationData.value = response?.value;
    };

    if (refetchRoutes.includes(route?.name as string)) {
      fetchAllConfig();
    }

    onMounted(async () => {
      if (route.meta.requiresAuth) {
        await fetchAllConfig();
      }
    });

    return {
      enumerationData,
      fetchAllConfig,
    };
  },
  {
    persist: false,
  }
);
