import { defineStore } from "pinia";
import moment from "moment-timezone";
import { reactive } from "vue";
import { ref } from "vue";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";

// Des fois ce format n'est pas considéré par l'application et return undefined

export const useDateTimeStore = defineStore("datetime", () => {
  const lensGroup = useLensGroupStore();
  const _tz = lensGroup.selectedLensGroup?.lensGroup_data.metadata.timezone_javascript || import.meta.env.VITE_DEFAULT_TIMEZONE;

  const time24hFormat = ref("D MMM, yyyy HH:mm:ss");
  const now = ref(moment().utc().format());
  const today = ref(moment().startOf("day").utc().format());
  const yesterday = ref(moment().startOf("day").subtract(1, "day").utc().format());
  const lastWeek = ref(moment().startOf("day").subtract(1, "week").utc().format());
  const lastMonth = ref(moment().startOf("day").subtract(1, "month").utc().format());
  const lastYear = ref(moment().subtract(1, "year").utc().format());

  const startEndDateFromCombinedTz = (date: Date | string, tzDevice: string) => {
    //  on transforme la date en moment date
    const dateUtc = moment(date);

    // On prends la différence en terme de temps entre l'appareil et le navigateur
    const deviceOffset = moment.tz(tzDevice).utcOffset();
    const browserOffset = moment.tz(moment.tz.guess()).utcOffset();

    // On souhaite avoir la date à XT00.00.00 UTC en ajoutant la différence avec le navigateur
    browserOffset >= 0 ? dateUtc.add(browserOffset, "minute") : dateUtc.subtract(browserOffset, "minute");
    // On applique ensuite le décalage selon la TZ du device
    dateUtc.subtract(deviceOffset, "minute");

    return {
      startingDate: dateUtc.toISOString(),
      endDate: dateUtc.add(1, "d").subtract(1, "second").toISOString(),
    };
  };

  const dateTime = {
    time24hFormat,
    now,
    today,
    yesterday,
    lastWeek,
    lastMonth,
    lastYear,
    timezone: _tz,

    startEnd24hourFromDate: function (date: Date | string, locationTz = _tz) {
      return startEndDateFromCombinedTz(date, locationTz);
    },
    monthAgo: function (date: Date | string, locationTz = _tz) {
      const tz = moment.tz(locationTz).utcOffset();
      return moment(date).tz(locationTz).subtract(1, "month").utc().format();
    },
    yearAgo: function (date: Date | string, locationTz = _tz) {
      const tz = moment.tz(locationTz).utcOffset();
      return moment(date).tz(locationTz).subtract(1, "year").add(1, "minute").utc().format();
    },
    getOffsetForTz: function (locationTz = _tz) {
      return moment.tz(locationTz).utcOffset();
    },
    dateOnly: function (date = "T") {
      return date.split("T")[0];
    },
    to24HourFormat: function (date: Date | string, locationTz = _tz) {
      return moment(date).tz(locationTz).format(time24hFormat.value);
    },
    nowWithTz: function (locationTz = _tz) {
      return moment.tz(locationTz).format();
    },
    todayWithTz: function (locationTz = _tz) {
      return moment.tz(locationTz).startOf("day").format();
    },
    todayEndWithTz: function (locationTz = _tz) {
      // @ts-ignore
      return moment.endOf("day").tz(locationTz).format();
    },
    toDate: function (date: Date | string, locationTz = _tz) {
      moment(date).tz(locationTz).format("DD-MM-YYYY HH:mm:ss").toString();
    },
  };

  return { helpers: reactive(dateTime), time24hFormat };
});
