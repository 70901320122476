<script setup lang="ts">
import { computed, reactive } from "vue";

import { LensVariable } from "@/index";
import { din41503_L1_curve, din41503_L2_curve, din41503_L3_curve } from "../normes/din4150";
import { create3AxesListLogSeries } from "../normes/formatToListLog";
import { burstListLogTooltip, burstListLogXAxis, burstListLogYAxis } from "../settings/chartListLogSettings";

const props = defineProps<{
  lensVariables: any;
  tableData: any[];
  module: any;
}>();
// @ts-ignore
import { use } from "echarts/core";
// @ts-ignore
import { CanvasRenderer } from "echarts/renderers";
// @ts-ignore
import {
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  // @ts-ignore
} from "echarts/charts";

// @ts-ignore
import {
  GridComponent,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
  // @ts-ignore
} from "echarts/components";

// @ts-ignore
import VChart, { THEME_KEY } from "vue-echarts";

use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent,
]);

const createMultipleAxesLineSeries = () => {
  if (props.lensVariables && props.module) {
    const table = props.tableData?.filter((el) => el.visible !== false);
    const scatterSeries = create3AxesListLogSeries(table, props.lensVariables, Object.values(props.module)[0]);
    const module = Object.values(props.module)[0] as any;
    const lineLevel = module ? module.line : 2;

    const lines = [
      {
        type: "line",
        symbolSize: 0,
        data: din41503_L1_curve,
        smooth: false,
      },
      {
        type: "line",
        symbolSize: 0,
        data: din41503_L2_curve,
        smooth: false,
      },
      {
        type: "line",
        symbolSize: 0,
        data: din41503_L3_curve,
        smooth: false,
      },
    ].map((line, index) => {
      if (index === lineLevel - 1) {
        return {
          ...line,
          name: `L${lineLevel}`,
        };
      } else {
        return {
          ...line,
          lineStyle: {
            color: "black",
            type: "dashed",
            width: 0.5,
          },
        };
      }
    });

    return [...lines, ...scatterSeries];
  }
};

const chartOptions = reactive({
  tooltip: burstListLogTooltip(),
  legend: {
    data: computed(() => {
      if (props.lensVariables) {
        const _lVar = props.lensVariables && props.lensVariables.map((vr: LensVariable) => vr);
        return _lVar?.disp_name;
      }
    }),
    left: "center",
    bottom: 10,
  },
  title: {
    text: "DIN4150 Module",
    left: "center",
  },
  grid: {
    left: "3%",
    right: "7%",
    bottom: "7%",
    containLabel: true,
  },
  xAxis: burstListLogXAxis(),
  yAxis: burstListLogYAxis(),
  // scatterSeries: null,
  series: computed(() => createMultipleAxesLineSeries()),
});
</script>

<style scoped>
.chart {
  height: 650px;
  min-width: 764px;
}
</style>
<template>
  <div>
    <v-chart class="chart" :option="chartOptions" />
  </div>
</template>
