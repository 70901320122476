import { h } from "vue";
import { renderActionButtons } from "../table.helpers";

export const userConfigurationColumns = ({ emit }: { emit: any }) => {
  return [
    {
      label: "Username",
      id: "username",
    },
    {
      label: "Email",
      id: "user_email",
    },
    {
      label: "Full name",
      id: "user_fullname",
    },
    {
      label: "Organization",
      id: "user_org",
      render: (cell: any, row: any) => {
        return h(
          "span",
          {
            className: `${`${cell ? "m-auto block px-4 py-1 m-2 hover:text-gray-900 hover:font-bold" : ""}`}`,
            row,
          },
          `${cell ? cell.org_display_name : "-"}`
        );
      },
    },
    {
      label: "Roles",
      id: "user_role",
      render: (cell: any, row: any) => {
        return h(
          "span",
          {
            className: `${cell ? "m-auto block px-4 py-1 m-2  hover:text-gray-900 hover:font-bold" : ""}`,
            row,
          },
          `${cell ? cell.role_name : "-"}`
        );
      },
    },
    {
      label: "Lens group",
      id: "user_lensGroups",
      render: (cell: any, row: any) => {
        const buttons =
          Array.isArray(cell) &&
          cell?.map((el) =>
            h(
              "span",
              {
                className: `${"m-auto block px-4 py-1 m-2 hover:text-gray-900 hover:font-bold"}`,
                row,
              },
              `${el.lensGroup_name}`
            )
          );
        return h("div", {}, buttons);
      },
    },
    {
      label: "Edit",
      width: "75px",
      render: (_: any, row: any) => renderActionButtons(row, emit),
    },
  ];
};
