import * as _ from "lodash";

import { isArray, isObject, isString } from "@vue/shared";

import { phone } from "phone";

export const titleCase = (str: string) => {
  let sentence = str.split("_");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0] + sentence[i].slice(1);
  }
  return sentence.join(" ");
};

export const setformType = (value: any) => {
  // if (Array.isArray(value)) {
  //   return;
  // }
  const isEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const isPhone = phone(value).isValid;

  if (isEmail.test(value)) {
    return "input_email";
  }
  if (isPhone) {
    return "input_phone";
  }
  if (typeof value === "boolean") {
    return "input_checkbox";
  }
  if (typeof value === "number") {
    return "input_number";
  }
  if (isArray(value)) {
    if (typeof value[0] === "number") return "input_array_number";
    if (typeof value[0] === "string") return "input_array_string";
  }
  if (isString(value)) {
    return "input_text";
  }
};

export const toDecimals = (point: any, decimal: any) =>
  parseFloat(point).toFixed(decimal);

export const valueOrNone = (value: any) =>
  typeof value !== "number" && typeof value !== "boolean" && _.isEmpty(value)
    ? "NONE"
    : value;

const setNullFromtype = (val: unknown) => {
  if (typeof val === "number") return 0;
  if (typeof val === "boolean") return false;
  if (isArray(val)) return [];
  if (isObject(val)) return {};
  if (isString(val)) return "NONE";
};

export const setAllObjectValueToNull = (obj: any) => {
  const innerObj = {} as any;
  for (const [key, value] of Object.entries(obj)) {
    innerObj[key] = setNullFromtype(value);
  }
  return innerObj;
};
