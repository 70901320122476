import * as _ from "lodash";

import { LensChart, LensParametersNotification, LensVariable } from "@/index";

import { createChartGraphForBurst } from "./shared/groupBurstByChartNumber";
import { fft_variables } from "./fftVariables";
import { formatAndGroupBurstForMultiGenericCharts } from "./shared/formatAndGroupBurstForMultiGenericCharts";
import { formatAndGroupBurstForMultiGenericChartsFFT } from "./shared/formatAndGroupBurstForMultiGenericChartsFFT";
import { splitDs } from "./fnUtils";

const defaultTz = import.meta.env.VITE_DEFAULT_TIMEZONE;

/**
 * Formate les données pour le graphique de rafales.
 *
 * @param ressources - Les ressources à formater.
 * @param lens_variables - Les variables de lens.
 * @param lens_charts - Les graphiques de lens.
 * @param lens_parameters_notification - Les paramètres de notification de lens.
 * @param lensChartOptions - Les options du graphique de lens.
 * @param fnFormatter - La fonction de formatage.
 * @param computeFFT - Indique si le calcul FFT doit être effectué (par défaut: true).
 * @param useVCNames - Indique si les noms de canal virtuel doivent être utilisés (par défaut: false).
 * @returns Les données formatées pour le graphique de rafales.
 */
const formatDataForBurstChart = (
  ressources: any,
  {
    lens_variables,
    lens_charts,
    lens_parameters_notification,
  }: {
    lens_variables: LensVariable[];
    lens_charts: LensChart[];
    lens_parameters_notification: LensParametersNotification[];
  },
  lensChartOptions: any,
  fnFormatter: Function,
  computeFFT = true,
  useVCNames = false
) => {
  const doc = ressources;

  // On vérifie bien que c'est un bursts
  if (_.isEmpty(doc.n_raw_data) || _.isEmpty(doc.n_raw_data)) {
    return Error("this is not a bursts");
  }
  let burstWithChartNumber: any;

  const burstByChartsNumber = fnFormatter(doc, {
    lensChartOptions,
    lens_variables,
  });

  burstWithChartNumber = {
    ...burstByChartsNumber,
  };
  return lensChartOptions
    ?.map((chart: any) => {
      const currVariable = lens_variables.filter((lv) => chart.virtual_channels.find((c: any) => lv.vc_name === c));
      let firstCol = computeFFT === true ? "freq" : "time";

      // let columns;

      // if (computeFFT === true) {
      //   // Putain je suis entrain de saloper mon code. C'est quoi cette merde ?!! C'était plus simple d'ajouter un les variable à la metadata
      //   columns = currVariable.concat(fft_variables as LensVariable[]).map((el) => el.disp_name);
      // } else if (useVCNames === true) {
      //   columns = splitDs(doc.virtual_channel_names) as string[];
      // } else {
      //   columns = currVariable.map((el) => el.disp_name);
      // }

      let columns: string[] = [];

      if (computeFFT === true) {
        // Putain je suis entrain de saloper mon code. C'est quoi cette merde ?!! C'était plus simple d'ajouter un les variable à la metadata
        columns = currVariable.concat(fft_variables as LensVariable[]).map((el) => el.disp_name);
      }
      if (useVCNames === true) {
        columns = columns.concat(splitDs(doc.virtual_channel_names) as string[]);
      } else {
        columns = columns.concat(currVariable.map((el) => el.disp_name));
      }

      if (burstWithChartNumber[chart.chart_number]) {
        return {
          id: `${computeFFT === true ? "fft" : "raw"}-${doc.id}`,
          info: {
            name: doc.name,
            frequencyPerSecond: burstWithChartNumber[chart.chart_number][0].dsf,
            timezone: doc.timezone_javascript || defaultTz,
            xUnit: computeFFT === true ? "Hz" : "ms",
          },
          chartData: {
            columns: [firstCol, ...columns],
            rows: createChartGraphForBurst(firstCol, burstWithChartNumber[chart.chart_number]),
          },
          key: columns,
          variables: currVariable,
          chart,
          limites: lens_parameters_notification,
        };
      }
    })
    .filter((el: any) => el !== undefined);
};

type FormatterGeneric = {
  burst: any;
  lensInfos: any;
  lensChartOptions: any;
  computeFFT: boolean;
  useVCNames: boolean;
};

/**
 * Formate les données pour le graphique Burst générique.
 *
 * @param burst - Les données Burst.
 * @param lensInfos - Les informations sur les lens.
 * @param lensChartOptions - Les options du graphique des lens.
 * @param computeFFT - Indique si le calcul de la FFT doit être effectué (facultatif, par défaut: false).
 * @param useVCNames - Indique si les noms des canaux virtuels doivent être utilisés (facultatif, par défaut: false).
 * @returns Les données formatées pour le graphique Burst.
 */
export function graphListFormatterGenericBurst({ burst, lensInfos, lensChartOptions, computeFFT = false, useVCNames = false }: FormatterGeneric) {
  return formatDataForBurstChart(burst, lensInfos, lensChartOptions, formatAndGroupBurstForMultiGenericCharts, computeFFT, useVCNames);
}
/**
 * Formate les données pour le graphique générique Burst FFT.
 *
 * @param burst - Les données Burst.
 * @param lensInfos - Les informations sur les lens.
 * @param lensChartOptions - Les options du graphique des lens.
 * @param computeFFT - Indique si le calcul de la FFT doit être effectué (par défaut: true).
 * @param useVCNames - Indique si les noms des canaux virtuels doivent être utilisés (par défaut: true).
 * @returns Les données formatées pour le graphique Burst.
 */
export function graphListFormatterGenericBurstFFT({ burst, lensInfos, lensChartOptions, computeFFT = true, useVCNames = true }: FormatterGeneric) {
  return formatDataForBurstChart(burst, lensInfos, lensChartOptions, formatAndGroupBurstForMultiGenericChartsFFT, computeFFT, useVCNames);
}
