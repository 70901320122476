import * as _ from "lodash";

import { LensVariable, MeasurementDocument } from "@/index";
import { findDisplayNameByChannelName, findDisplayNameByVCNameInnerChart } from "./findDisplayNameByChannelName";

import { splitDs } from "../fnUtils";

/**
 * Formate et regroupe les données Burst pour les graphiques génériques multiples.
 *
 * @param doc - Le document de mesure contenant les données brutes.
 * @param lens_variables - Les variables de lens utilisées pour formater les données.
 * @returns Un objet contenant les données Burst regroupées par numéro de graphique.
 */
export const formatAndGroupBurstForMultiCharts = (doc: MeasurementDocument, { lens_variables }: { lens_variables: LensVariable[] }) => {
  const raw_data = splitDs(doc.raw_data) as string[];
  const n_raw_data = splitDs(doc.n_raw_data) as string[];
  const virtual_channel_names = splitDs(doc.virtual_channel_names) as string[];

  const grouped_raw_data = [];
  let lastEnd = 0;

  for (let index = 0; index < n_raw_data.length; index++) {
    const el = n_raw_data[index];
    const slicedData = index === 0 ? _.slice(raw_data, 0, parseFloat(el)) : _.slice(raw_data, lastEnd, lastEnd + parseFloat(el));
    lastEnd += parseFloat(el);
    grouped_raw_data.push(slicedData);
  }

  const groupedByIndex = _.flatten(
    grouped_raw_data.map((group, index) => {
      const channelName: string = virtual_channel_names[index];
      const varInfos = findDisplayNameByChannelName(lens_variables)(channelName);

      return group.map((el, i) => {
        return {
          time: i,
          [varInfos.channelName]: group[i],
          chartNumber: varInfos.chart,
        };
      });
    })
  );
  return _.groupBy(groupedByIndex, "chartNumber");
};

/**
 * Formate et regroupe les données Burst pour les graphiques génériques multiples.
 *
 * @param doc - Le document de mesure.
 * @param lensChartOptions - Les options de graphique de lens.
 * @param lens_variables - Les variables de lens.
 * @returns Un objet contenant les données Burst regroupées par numéro de graphique.
 */
export const formatAndGroupBurstForMultiGenericCharts = (
  doc: MeasurementDocument,
  { lensChartOptions, lens_variables }: { lensChartOptions: any[]; lens_variables: LensVariable[] }
) => {
  const raw_data = splitDs(doc.raw_data) as string[];
  const n_raw_data = splitDs(doc.n_raw_data) as string[];
  const virtual_channel_names = splitDs(doc.virtual_channel_names) as string[];

  const virtual_channel_dsf = splitDs(doc.virtual_channel_dsf) as string[];
  const grouped_raw_data = []
  let lastEnd = 0

  for (let index = 0; index < n_raw_data.length; index++) {
    const el = n_raw_data[index];
    const slicedData = index === 0 ? _.slice(raw_data, 0, parseFloat(el)) : _.slice(raw_data, lastEnd, (lastEnd + parseFloat(el)));
    lastEnd += parseFloat(el);
    grouped_raw_data.push(slicedData);
  }

  const groupedByIndex = _.flatten(
    grouped_raw_data.map((group, index) => {
      const channelName: string = virtual_channel_names[index];
      const dsf : number = parseInt(virtual_channel_dsf[index]);
      const varInfos = findDisplayNameByVCNameInnerChart(lensChartOptions, channelName, lens_variables);

      return group.map((el, i) => {
        return {
          time: i,
          [varInfos.channelName]: group[i],
          chartNumber: varInfos.chart,
          dsf
        };
      });
    })
  );
  return _.groupBy(groupedByIndex, "chartNumber");
};
