<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

import BaseForm from "@core/components/dynamicForms/BaseForm.vue";
import SelectMultiInput from "@core/components/dynamicForms/inputs/SelectMultiInput.vue";
import SelectSingleInput from "@core/components/dynamicForms/inputs/SelectSingleInput.vue";

import { UserUpdate } from "@/index";
import { formatMultiSelectToInput, formatSingleSelectToInput } from "@core/components/dynamicForms/utils/formMultiSelectHelpers";
import { formatToForms } from "@core/components/dynamicForms/utils/inputHelpers";
import { useConfigurationStore } from "@modules/configuration/store/configurationStore";

const props = defineProps<{
  userObjectData: UserUpdate;
  isNewUser: Boolean;
  lensGroups: any[];
}>();
const emit = defineEmits(["submit-user-form"]);

const configurationStore = useConfigurationStore();

const createUserFormValue = ref({});

const lensGroupsWithDetails = computed(() => {
  return props.lensGroups.map((g: any) => {

    return {
      ...g,
      lensGroup_name: `${g.location_id} - ${g.lensGroup_application_type} - ${g.lensGroup_name}`,
    };
  });
});

const formatUserToForms = computed(() => {
  return formatToForms(props.userObjectData, ["user_password", "user_lensGroups", "user_role", "user_org", "deletedAt"], {
    valueIsTitle: false,
  });
});
const formatMetaToMuliSelectGroups = computed(() => {
  if (props.lensGroups && props.lensGroups?.length > 0) {
    const lensGroupIds = {
      user_lensGroups: props.userObjectData.user_lensGroups,
    };
    return formatMultiSelectToInput(lensGroupIds, lensGroupsWithDetails.value, "lensGroup_name", "id", "user measurement group");
  }
});
const formatMetaToMuliSelectRoles = computed(() => {
  if (configurationStore.roles && configurationStore.roles.length > 0) {
    // FIXME : faire une fonction pour traiter les resultats non listés
    const _roleId = { user_role: props.userObjectData?.user_role };
    return formatSingleSelectToInput(_roleId, configurationStore.roles, "role_name", "id", "user roles");
  }
});
const formatMetaToMuliSelectOrganizations = computed(() => {
  if (configurationStore.organizations && configurationStore.organizations.length > 0) {
    const _organizationId = { user_org: props.userObjectData?.user_org };
    return formatSingleSelectToInput(_organizationId, configurationStore.organizations, "org_display_name", "id", "user organization");
  }
});

function recompose(newValue: any) {
  createUserFormValue.value = { ...createUserFormValue.value, ...newValue };
}

function recomposeValue($event: any) {
  recompose($event);
}
function recomposeWithSelectRole($event: any) {
  recompose($event.value);
}
function recomposeWithMultiSelectGroups($event: any) {
  recompose($event.value);
}
function recomposeWithSelectOrganizations($event: any) {
  recompose($event.value);
}
function createNewUser() {
  const formData = { ...props.userObjectData, ...createUserFormValue.value };
  emit("submit-user-form", formData);
}
</script>

<template>
  <div v-if="userObjectData" class="flex pb-8">
    <div class="flex-col flex ml-auto mr-auto items-center w-full lg:w-2/3 md:w-3/5">
      <h1 class="text-2xl mt-8">
        {{ isNewUser ? "Create new user" : "Update user" }}
      </h1>
      <form @submit.prevent="createNewUser" class="flex flex-col space-y-5 size">
        <BaseForm :formSchema="formatUserToForms" @form-value-updated="recomposeValue($event)" />
        <SelectSingleInput
          v-if="formatMetaToMuliSelectRoles"
          :key="formatMetaToMuliSelectRoles.id"
          :fieldObj="formatMetaToMuliSelectRoles"
          @edited-input="recomposeWithSelectRole($event)"
        ></SelectSingleInput>

        <SelectMultiInput
          v-if="formatMetaToMuliSelectGroups"
          :key="formatMetaToMuliSelectGroups.id"
          :fieldObj="formatMetaToMuliSelectGroups"
          @edited-input="recomposeWithMultiSelectGroups($event)"
        ></SelectMultiInput>

        <SelectSingleInput
          v-if="formatMetaToMuliSelectOrganizations"
          :key="formatMetaToMuliSelectOrganizations.id"
          :fieldObj="formatMetaToMuliSelectOrganizations"
          @edited-input="recomposeWithSelectOrganizations($event)"
        ></SelectSingleInput>

        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
          >
            {{ isNewUser ? "Create" : "Update" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style>
.size {
  width: 500px;
}
</style>
