<script lang="ts" setup>
import BurstDin4150ListLogChart from "@core/charts/normeGraphs/BurstDin4150ListLogChart.vue";
import { computed, unref, reactive } from "vue";
// @ts-ignore
import { LensItemsGroup } from "@/index";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";

const metadata = useMetadataStore();

const genericLensVariables = computed(() => metadata.selectedMetadata.lens_variables);
// const normeVariant = ref('default')

const props = defineProps<{
  lensItem: LensItemsGroup;
  tableData: any[];
  applicationType: any;
  module: any;
}>();

const _module = reactive(props.module);

const getCurrentChart = computed(() => {
  if (props.applicationType.toLowerCase() === "module-bursts-din41503" || props.applicationType.toLowerCase() === "module-bursts-din41503-zc")
    return BurstDin4150ListLogChart;
  if (props.applicationType.toLowerCase() === "module-bursts-din41503-fft") {
    return BurstDin4150ListLogChart;
  }
});
</script>
<template>
  <div class="container mx-auto items-center px-5 py-24">
    <component
      v-if="_module"
      :key="props.applicationType"
      :is="getCurrentChart"
      :lensVariables="unref(genericLensVariables)"
      :module="_module"
      :tableData="props.tableData"
    ></component>
  </div>
</template>
