<template>
  <div class="flex flex-col space-y-2 my-4 w-full">
    <div class="flex justify-between">
      <label :for="fieldObj.id" class="block text-sm font-medium text-gray-700" :key="fieldObj.id">
        {{ fieldObj.title }}
      </label>
    </div>
    <div class="mt-2">
      <Multiselect
        v-model="value"
        :placeholder="'Search or add ' + fieldObj.title"
        label="name"
        track-by="code"
        :options="options"
        :multiple="true"
        :taggable="true"
        @tag="addTag"
      ></Multiselect>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FieldSelectObject } from "@/index";
import { ref, watch, onMounted } from "vue";
// @ts-ignore
import Multiselect from "vue-multiselect";

const emit = defineEmits(["edited-input"]);
const props = defineProps<{
  fieldObj: FieldSelectObject;
}>();

const value = ref(props.fieldObj.value);
const options = ref(props.fieldObj.options);

const addTag = (newTag: string) => {
  const tag = {
    name: newTag,
    code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
  };
  options.value.push(tag);
  value.value.push(tag);
};

const rebuildEntries = (fieldObj: FieldSelectObject) => {
  const parentRef = fieldObj.parentRef?.local !== fieldObj.parentRef?.root ? fieldObj.parentRef?.local : null;
  return {
    id: fieldObj.id,
    key: fieldObj.parentRef?.root,
    localKey: fieldObj.parentRef && Object.keys(fieldObj.parentRef.local)[1],
    title: fieldObj.key,
    type: "input_select_multi",
    value: {
      [fieldObj.key]: [...value.value.map((a: any) => a.value)],
      // @ts-ignore
      ...parentRef,
    },
  };
};
const compose = () => {
  const newValue = rebuildEntries(props.fieldObj);
  emit("edited-input", newValue);
};
watch(value, () => {
  compose();
});

onMounted(() => {
  compose();
});
</script>
