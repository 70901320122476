import * as _ from "lodash";

import { ColumnTable } from "@/index";
import { h } from "vue";
import { i18n } from "@/locales";

const labelColorSwitch = (type: string) => {
  switch (type) {
    case "peak":
      return "blue";
    case "min":
      return "green";
    case "max":
      return "red";
    case "avg":
      return "gray";
    default:
      return "yellow";
  }
};
const renderStatsItemButton = (channels: any, toExternalPQUnit: Function) => {
  const pQ = channels.physical_quantities;
  return Object.entries(channels).map((c: any) => {
    if (c[0] !== "peak") return;
    return h(
      "button",
      {
        className: `mx-1 px-4 py-1 bg-${labelColorSwitch(c[0])}-200 shadow-xs`,
      },
      [h("strong", {}, `${c[0]}: `), `${toExternalPQUnit(c[1].toFixed(2), pQ)}`]
    );
  });
};

export const burstGenericListTableColumns = ({
  fetchDataFromCosmosById,
  toExternalPQUnit,
  toDate,
  displayLensVariableName,
  displayCommentDialog,
}: {
  fetchDataFromCosmosById: Function;
  toDate: Function;
  toExternalPQUnit: Function;
  displayLensVariableName: Function;
  displayCommentDialog: Function;
}): ColumnTable[] => [
  {
    label: "name",
    id: "name",
    hidden: true,
  },
  {
    label: i18n.global.t("tables.show"),
    id: "show",
    render: (_: any, row: any) => {
      return h(
        "button",
        {
          className: "m-auto inline-block px-4 py-1 bg-blue-200 rounded shadow-xs cursor-pointer hover:bg-blue-500 hover:text-gray-100 text-sm",
          onClick: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            fetchDataFromCosmosById(row.name, row.burst_id);
          },
        },
        "show"
      );
    },
    },
  // TODO: Disable note for now
  // {
  //   label: i18n.global.t("tables.comment"),
  //   id: "show",
  //   render: (_: any, row: any) => {
  //     return h(
  //       "button",
  //       {
  //         className: `m-auto inline-block px-4 py-1 ${
  //           row.message.length < 1 ? "bg-indigo-100" : "bg-orange-300"
  //         } rounded shadow-xs cursor-pointer hover:bg-indigo-500 hover:text-gray-100 text-sm`,
  //         onClick: (e: any) => {
  //           e.preventDefault();
  //           e.stopPropagation();
  //           displayCommentDialog(row);
  //         },
  //       },
  //       `${row.message.length > 0 ? 1 : 0} note`
  //     );
  //   },
  // },
  {
    label: i18n.global.t("tables.burst.datetime"),
    id: "datetime",
    sortable: true,
    isKey: true,
    formatter: toDate,
    sortItemType: "date",
    sortItemKey: "datetime",
  },

  {
    label: "burst id",
    id: "burst_id",
    sortable: false,
    isKey: true,
    sortItemKey: "burst_id",
    sortItemType: "number",
  },
  {
    label: "Duration",
    id: "burst_duration",
    hidden: false,
    isKey: true,
    formatter: (cell: any, row: any) => toExternalPQUnit(cell?.value, cell?.physical_quantities),
  },

  {
    label: "Peak infos",
    id: "burst_stat",
    hidden: false,
    render: (cell: any) => {
      return h(
        "div",
        { className: "" },
        _.isObject(cell) &&
          Object.entries(cell).map((VC) => {
            return h("span", { class: "my-2" }, [
              h("strong", {}, displayLensVariableName(VC[0]).channelName),
              ": ",
              renderStatsItemButton(VC[1], toExternalPQUnit),
              " ",
            ]);
          })
      );
    },
  },
];
