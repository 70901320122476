import { LensChart } from "@/index";

/**
 * Formate les options génériques du graphique.
 *
 * @param chartInput - Les options du graphique d'origine.
 * @param customGenericConfig - La configuration personnalisée pour les options génériques.
 * @returns Les options du graphique formatées avec les options génériques.
 */
export const genericChartOptionsFormatter = (chartInput: LensChart, customGenericConfig: any): LensChart => {
  const selectedChart = customGenericConfig.customCharts as LensChart;
 return{ ...chartInput, ...selectedChart, ...{is_generic: true} };
};
