<template>
  <div>
    <div class="flex justify-between">
      <label
        :for="fieldObj.key"
        class="block text-sm font-medium text-gray-700"
        >{{ fieldObj.title }}</label
      >
      <span class="text-sm text-gray-500" v-if="fieldObj.hint">{{
        fieldObj.hint
      }}</span>
    </div>
    <div class="mt-2">
      <input
        type="number"
        :name="fieldObj.key"
        :id="fieldObj.key"
        v-model="fieldObj.value"
        :key="fieldObj.id"
        autofocus
        autocomplete="off"
        @keyup="debounce"
        @blur="debounce"
        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </div>

    <p class="mt-2 text-sm text-red-600" v-if="fieldObj.error">
      {{ fieldObj.error }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { FieldObject } from "@/index";
import { useDebounceFn } from "@vueuse/core";
import { ref } from "vue";

const props = defineProps<{
  fieldObj: FieldObject;
}>();

const emit = defineEmits(["edited-input"]);

const errorClass = ref<string | undefined>('');

const compareToRange = (term: any) => {
  if (props.fieldObj?.range && props.fieldObj?.range.length > 0) {
    let val = props.fieldObj.range;
    if (val[0] > val[1]) {
      val[0] = props.fieldObj.range[1];
      val[1] = props.fieldObj.range[0];
    }
    if (term < val[0]) return "border-red-500";
    else if (term > val[1]) return "border-red-500";
    else return "";
  }
};

const debounce = useDebounceFn(() => {
  errorClass.value = compareToRange(props.fieldObj.value);
  const error = errorClass ? true : false;
  const number = parseFloat(props.fieldObj.value as string );

  const valuetoDispatch = {
    ...props.fieldObj,
    id: props.fieldObj.id,
    value: number,
    // error,
    error: props.fieldObj.error,
  };
  emit("edited-input", valuetoDispatch);
}, 700);
</script>

<style></style>
