<template>
  <div>
    <div v-if="props.chartObj.id">
      <v-chart class="chart" :option="{ ...chartOptions, ...dataSet }" :key="props.chartObj.id" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";

import { useMetadataStore } from "@/@modules/admin/store/metadataStore";
import { ChartEntity, PhysicalQuantity } from "@/index";
import { burstChartConfig } from "../settings/burstChartSettings";

const metadata = useMetadataStore();

const props = defineProps<{
  chartObj: ChartEntity;
}>();

const physicalQuantity = computed(() => metadata.physicalQuantities);
const getPhysicalByType = (type = "velocity"): PhysicalQuantity | undefined => {
  return physicalQuantity.value?.find((ph: PhysicalQuantity) => ph.type === type);
};

const dataSet = reactive({
  dataset: {
    dimensions: props.chartObj.chartData.columns,
    source: props.chartObj.chartData.rows,
  },
});

/**
 * Options de configuration du graphique BurstLineFFTChart.
 * @typedef {Object} ChartOptions
 * @property {PhysicalQuantity} physicalQ - La quantité physique associée au graphique.
 * @property {string[]} keys - Les clés du graphique.
 * @property {Chart} chart - Les informations sur le graphique.
 * @property {ChartInfo} info - Les informations supplémentaires sur le graphique.
 * @property {string} type - Le type de graphique (burst-fft).
 * @property {string[]} variables - Les variables du graphique.
 * @property {boolean} usePhysicalQuantity - Indique si la quantité physique doit être utilisée.
 */

/**
 * Calcule les options de configuration du graphique BurstLineFFTChart.
 * @returns {ChartOptions} Les options de configuration du graphique.
 */
const chartOptions = computed(() => {
  const physicalQ = getPhysicalByType(props.chartObj.chart.physical_quantity);
  if (physicalQ) {
    return burstChartConfig({
      physicalQ,
      keys: props.chartObj.key,
      chart: props.chartObj.chart,
      info: props.chartObj.info,
      type: "burst-fft",
      variables: props.chartObj.variables,
      usePhysicalQuantity: false,
    });
  }
});

//--------------------------------------------------------------------------
// @ts-ignore
import { use } from "echarts/core";
// @ts-ignore
import { CanvasRenderer } from "echarts/renderers";
// @ts-ignore
import {
  BarChart,
  EffectScatterChart,
  LineChart,
  LinesChart,
  MapChart,
  PieChart,
  RadarChart,
  ScatterChart,
  // @ts-ignore
} from "echarts/charts";

// @ts-ignore
import {
  DataZoomComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  PolarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  // @ts-ignore
} from "echarts/components";

// @ts-ignore
import VChart from "vue-echarts";

use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent,
]);
</script>

<style scoped>
.chart {
  height: 650px;
  min-width: 764px;
}
</style>
