import * as _ from "lodash";

import { LensVariable, MeasurementDocumentBurstFFT } from "@/index";

import { fft_variables } from "../fftVariables";
import { findDisplayNameByChannelName } from "./findDisplayNameByChannelName";
import { splitDs } from "../fnUtils";

/**
 * Formate et regroupe les données Burst pour les graphiques génériques multiples FFT.
 *
 * @param doc - Le document de mesure Burst FFT.
 * @param lens_variables - Les variables de lens.
 * @returns Un objet contenant les données Burst formatées et regroupées par numéro de graphique.
 */
export const formatAndGroupBurstForMultiGenericChartsFFT = (doc: MeasurementDocumentBurstFFT, { lens_variables }: { lens_variables: LensVariable[] }) => {
  const raw_data = splitDs(doc.burst_fft_data) as string[];
  const n_raw_data = splitDs(doc.n_burst_fft_data) as string[];
  const virtual_channel_names = splitDs(doc.virtual_channel_names_fft) as string[];

  const count = parseFloat(n_raw_data[0]);
  const grouped_raw_data = _.chunk(raw_data, count);
  const freqs = splitDs(doc.burst_fft_freq) as string[];

  // Putain je suis entrain de saloper mon code. C'est quoi cette merde ?!!
  const _lensVariables = [...lens_variables, ...fft_variables] as LensVariable[];
  const groupedByIndex = _.flatten(
    grouped_raw_data.map((group, index) => {
      const channelName: string = virtual_channel_names[index];
      const varInfos = findDisplayNameByChannelName(_lensVariables)(channelName);

      return group.map((_, i) => {
        return {
          freq: freqs[i],
          [varInfos.channelName]: group[i],
          chartNumber: varInfos.chart,
        };
      });
    })
  );

  return _.groupBy(groupedByIndex, "chartNumber");
};
