<template>
  <div class="relative">
    <div class="container mx-auto items-center px-5 pb-24">
      <template v-for="c in chartList">
        <component v-if="c" :key="c.id" :is="getCurrentChart(c.chart.type)" :chartObj="c"></component>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
// import BurstFFTChart from "@core/charts/elements/BurstFFTChart.vue";
import BurstLineChart from "@core/charts/elements/BurstLineChart.vue";
// @ts-ignore
import { useConfigStore } from "@/config/configStore";
import { ChartEntity } from "@/index";
import BurstLineFFTChart from "./elements/BurstLineFFTChart.vue";
const configStore = useConfigStore();

const props = defineProps<{
  chartList: any;
}>();

const getCurrentChart = (type: string) => {
  if (type === configStore.enumerationData.chartTypeEnum.BURSTS_TIMESERIES_3AXES) return BurstLineChart;
  if (type === configStore.enumerationData.chartTypeEnum.BURSTS_FFT_3AXES) return BurstLineFFTChart;
};
</script>
