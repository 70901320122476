import * as _ from "lodash";

export const organizationTemplate = (orgName: string = "error") => ({
  id: `${_.lowerCase(orgName)}-application`,
  display_name: _.capitalize(orgName),
  applications: [
    {
      type: "generic",
      measurement_point_list: [],
    },
  ],
  recipients: [
    {
      email: "anthony@novity.io",
      name: "Anthony Michel",
    },
    {
      email: "angelo@novity.io",
      name: "Angel o",
    },
    {
      email: "onguyen@micromega-dynamics.com",
      name: "Olivier Nguyen",
    },
    {
      email: "iloiselle@micromega-dynamics.com",
      name: "Igor Loiselle",
    },
    {
      email: "tdavy@micromega-dynamics.com",
      name: "Thibaut Davy",
    },
  ],
  partitionKey: "application-metadata",
});
