import { FieldSelectObject, KeyValue } from "@/index";

import { titleCase } from "./helpers";
import { v4 as uuidv4 } from "uuid";

type Option = { code: number | string; name: string; value: string | number };

type ParentRef = { local: string; root: string };

type LensItemsNames = { id: undefined | string; name: string };

const getaValue = (arr: any) => arr.map((v: any) => v.value);

/**
 * Formate les objets d'une liste en options pour une sélection multiple.
 *
 * @param objList - La liste d'objets à formater.
 * @param valuekey - La clé utilisée pour la valeur de chaque option (par défaut: "error_code_index").
 * @param namekey - La clé utilisée pour le nom de chaque option (par défaut: "name").
 * @returns Les options formatées pour une sélection multiple.
 */
const formatToMultiSelectOptions = (
  objList: any,
  valuekey = "error_code_index",
  namekey = "name"
) => {
  return objList?.map((obj: any) => {
    return { name: obj[namekey], value: obj[valuekey], code: obj[valuekey] };
  });
};

//  L'usage du parent ref c'est pour les cas il s'agit d'une liste d'objects contenant des entrées différentes et un id
/**
 * Formatte les sélections multiples avec une référence parente vers les formulaires.
 *
 * @param rootObject - L'objet racine contenant les données.
 * @param parentRef - La référence parente.
 * @param lensItemsNames - Les noms des éléments de la lens.
 * @param labelObjects - Les objets d'étiquettes.
 * @param valuekey - La clé de la valeur (par défaut : "error_code_index").
 * @param namekey - La clé du nom (par défaut : "name").
 * @returns Un tableau d'objets formatés pour les sélections multiples.
 */
export const formatMultiSelectWithParentRefToForms = (
  rootObject: any,
  parentRef: ParentRef,
  lensItemsNames: LensItemsNames[],
  labelObjects: any[],
  valuekey = "error_code_index",
  namekey = "name"
) => {
  const _ref = {
    local: { [parentRef.local]: rootObject[parentRef.local] },
    root: parentRef.root,
  };

  const _toHide = parentRef.local?.concat("id");
  const findLensName = (name: string) => {
    const _name = lensItemsNames.find((l: LensItemsNames) => l.id === name);
    return _name ? _name.name : name;
  };

  return Object.entries(rootObject)
    ?.filter((it: any) => {
      return !_toHide.includes(it[0]);
    })
    .map((el: any) => {
      const options = formatToMultiSelectOptions(labelObjects, valuekey, namekey);
      return {
        id: uuidv4(),
        key: el[0],
        value: options?.filter((op: Option) => el[1]?.includes(op.code)),
        title: `${findLensName(rootObject[parentRef.local])} ${titleCase(el[0])}`,
        type: "input_select_multi",
        options,
        parentRef: _ref,
      };
    });
};

// L'usage du simple c'est pour tous les autres cas, mais il faut penser à utiliser 1 form par object label
export const formatMultiSelectToInput = (
  innerValueObject: KeyValue<string, string[] | number[]>,
  allOuterValue: any[],
  namekey: string,
  valuekey: string | undefined = "id",
  title: string | null = null
) => {
  const inputs = Object.entries(innerValueObject).map((el: any) => {
    const options = formatToMultiSelectOptions(allOuterValue, valuekey, namekey);
    return {
      id: uuidv4(),
      key: el[0],
      value: options?.filter((op: Option) => el[1]?.includes(op.code)),
      title: title ? title : `${titleCase(el[0])}: `,
      type: "input_select_multi",
      options,
      parentRef: { root: el[0], local: el[0] },
    };
  });

  return inputs[0];
};
// L'usage du simple c'est pour tous les autres cas, mais il faut penser à utiliser 1 form par object label
/**
 * Convertit une valeur de sélection unique en un objet d'entrée pour un formulaire.
 * @param innerValueObject - L'objet contenant la valeur de sélection unique.
 * @param allOuterValue - Un tableau contenant toutes les valeurs possibles pour la sélection.
 * @param namekey - La clé utilisée pour le nom de la sélection.
 * @param valuekey - La clé utilisée pour la valeur de la sélection. Par défaut: "id".
 * @param title - Le titre optionnel à afficher pour la sélection. Par défaut: null.
 * @returns L'objet d'entrée pour le formulaire.
 */
export const formatSingleSelectToInput = (
  innerValueObject: KeyValue<string, string | number>,
  allOuterValue: any[],
  namekey: string,
  valuekey = "id",
  title: string | null = null
) => {
  const inputs = Object.entries(innerValueObject).map((el) => {
    const options = formatToMultiSelectOptions(allOuterValue, valuekey, namekey);
    return {
      id: uuidv4(),
      key: el[0],
      value: options?.find((op: Option) => el[1] === op.code),
      title: title ? title : `${titleCase(el[0])}: `,
      type: "input_select_single",
      options,
      parentRef: { root: el[0], local: el[0] },
    };
  });

  return inputs[0];
};

// Convertion des retour du formulaire dynamic en object directement comprise par le serveur
// Formulaire à envoyé au serveur
/**
 * Compose un objet valide pour un formulaire de sélection multiple.
 *
 * @param formObject - L'objet de formulaire contenant les champs de sélection.
 * @param multiSelectEntries - Les entrées de sélection multiple.
 * @param selector - Le sélecteur par défaut pour les clés d'entrée.
 * @returns L'objet composé valide pour le formulaire de sélection multiple.
 */
export const composeObjectToValidMultiSelectForm = (
  formObject: KeyValue<string, FieldSelectObject>,
  multiSelectEntries: string[],
  selector = "lens_items_id"
) => {
  const fields: FieldSelectObject[] = Object.values(formObject);
  const combined = fields
    ?.filter((el) => !!el.key)
    ?.map((obj: FieldSelectObject) => {
      if (
        obj.key != null &&
        !obj.parentRef &&
        !multiSelectEntries.includes(obj.key)
      ) {
        return obj.value;
      }
      if (obj.parentRef) {
        const entry = { [obj.key]: getaValue(obj.value) };
        const _ref = obj.parentRef.local;
        // @ts-ignore
        const comb = { ...entry, ..._ref };
        return comb;
      }
    })
    ?.reduce((obj: FieldSelectObject[], item) => {
      const keys = Object.keys(obj);
      if (keys.length > 0 && keys.includes(item[selector])) {
        obj[item[selector]] = { ...obj[item[selector]], ...item };
        return obj;
      } else {
        obj[item[selector]] = item;
        return obj;
      }
    }, {});

  // @ts-ignore
  return { [multiSelectEntries]: Object.values(combined) };
};
