<template>
    <article class="overflow-hidden rounded-lg shadow-lg mx-40 bg-white">
        <header class="px-4 pt-4 pb-10 border-b border-gray-200 bg-blue-custom sm:px-8 relative text-center">
            <h3 class="text-lg leading-6 font-medium text-white">
                Profile information
            </h3>
        </header>
        <footer class="justify-between p-2 md:p-2">
            <div>
                <div class="mb-6 mx-4">
                    <label for="account-name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Account
                        Name</label>

                    <input type="text" id="account-name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                         :value=" auth.user?.user_fullname" disabled readonly>

                </div>
                <div class="mb-6 mx-4">
                    <label for="account-type" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Account
                        Type</label>
                    <input type="text" id="account-type"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        :value=" auth.user?.user_role?.role_name " disabled readonly>
                </div>
                <div class="mb-6 mx-4">
                    <label for="tenant" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tenant</label>
                    <input type="text" id="tenant"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        :value="auth.user?.user_org?.org_display_name" disabled readonly>
                </div>

                <div class="mb-6 mx-4">
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input type="text" id="email"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        :value=" auth.user?.user_email" disabled readonly>
                </div>


                <div class="mb-6 mx-4">
                    <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                        Reset Password
                    </button>
                </div>

                <div class="mb-6 mx-4">
                    <label for="logo" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Logo</label>
                    <label
                        class="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-200 border-dashed rounded-md appearance-none  hover:border-gray-400 cursor-pointer focus:outline-none">
                        <span class="flex items-center space-x-2">
                        <CloudUploadIcon class="w-6 h-6 text-gray-600" />
                            <span class="font-medium text-gray-600">
                                Drop a png file to Attach, or
                                <span class="text-blue-600 underline">browse</span>
                            </span>
                        </span>
                        <input type="file" name="file_upload" class="hidden">
                    </label>
                </div>

                <div class="mb-6 mx-4">
                    <label for="preview" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Preview
                        Logo
                        :</label>
                    <div class="flex justify-center">
                        <img src="" alt="preview">
                    </div>
                </div>

                <div class="mb-6 mx-4">
                    <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                        Save
                    </button>
                </div>

            </div>

        </footer>
    </article>
</template>
<script lang="ts" setup>

import { CloudUploadIcon  } from "@heroicons/vue/outline";
import { useAuthStore } from "@modules/auth/authStore";


const auth = useAuthStore();

</script>
<style scoped>
.bg-blue-custom {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
}
</style>
