import * as _ from "lodash";

import { LensVariable } from "@/index";
import { createGenericDataName } from "../../fnUtils";
import { listToObjectWithKey } from "@/@use/libs/helpers";

/**
 * Formate les options des variables génériques en fonction du nom.
 * @param conf - La variable Lens.
 * @param name - Le nom de la variable.
 * @param _customVariables - Les variables personnalisées.
 * @param formattedVCName - Indique si le nom de la variable doit être formaté.
 * @returns La variable Lens formatée avec les options correspondantes.
 */
export const genericVariablesOptionsFormatterByName = (conf: LensVariable, name: string, _customVariables: any, formattedVCName = true) => {
  // @ts-ignore
  const hasOptions = _customVariables[name];
  const customOptions = !_.isEmpty(hasOptions) ? listToObjectWithKey(hasOptions as any, "vc_name") : {};
  let currentOption = customOptions[`${conf.vc_name}`];

  if (!currentOption) return undefined;
  else {
    const vc_name = formattedVCName ? createGenericDataName(conf.vc_name, name) : conf.vc_name;
    const res = Object.assign({}, conf, currentOption, { vc_name });
    return res;
  }
};

// export const genericVariablesOptionsFormatter = (
//   { lens_variables, data_name }: { lens_variables: LensVariable[]; data_name: string },
//   customGenericConfig: any
// ) => {
//   if (data_name === "data") return lens_variables;
//   else {
//     return customGenericConfig.allDataNames
//       .map((dataName: string) =>
//         lens_variables.map((conf) =>
//           genericVariablesOptionsFormatterByName(conf, dataName, customGenericConfig.customVariables, true))
//       )
//       .flat(1)
//       .filter((el: any) => el !== undefined);
//   }
// };
/**
 * Formate les options des variables génériques racines.
 *
 * @param {Object} params - Les paramètres de la fonction.
 * @param {LensVariable[]} params.lens_variables - Les variables de la lens.
 * @param {string} params.data_name - Le nom des données.
 * @param {any} customGenericConfig - La configuration personnalisée générique.
 * @returns {string[]} Les options des variables génériques racines.
 */
export const genericRootVariablesOptionsFormatter = (
  { lens_variables, data_name }: { lens_variables: LensVariable[]; data_name: string },
  customGenericConfig: any
) => {
  if (data_name === "data") return lens_variables;
  else {
    return customGenericConfig.allDataNames
      .map((dataName: string) => customGenericConfig.customVariables[dataName].map((vc: string) => `${vc}__${dataName}`))
      .flat(1)
      .filter((el: any) => el !== undefined);
  }
};
