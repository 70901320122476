

<script setup lang="ts">
import { useConfigStore } from "@/config/configStore";
import Footer from "@core/components/banner/Footer.vue";
import { Disclosure } from "@headlessui/vue";
import { LogoutIcon, UserGroupIcon, OfficeBuildingIcon, IdentificationIcon, LocationMarkerIcon, CollectionIcon, AdjustmentsIcon, UserAddIcon  } from "@heroicons/vue/outline";
import { useAuthStore } from "@modules/auth/authStore";
import { useIdle } from "@vueuse/core";
import { watch, inject } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useUiStore } from "@use/uiStore";
import ScaleLoader from "@/@core/shared/ui/ScaleLoader.vue";

const $zo: any = inject("$zo");

const config = useConfigStore();
const { enumerationData } = storeToRefs(config);
const ui = useUiStore();

const { idle, lastActive } = useIdle(12 * 60 * 1000); // 12 min
const auth = useAuthStore();
const router = useRouter();

const logout = () => {
  auth.logout();
  router?.push({ name: "home" });
};

watch(idle, () => {
  if (idle.value === true) {
    logout();
  }
});
</script>
<template>
  <div class="flex flex-col h-screen justify-between bg-gray-50">
    <Disclosure as="nav" class="bg-blue-custom shadow" v-slot="{ open }">
      <div class="container px-2 py-2 mx-auto">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <img class="block lg:hidden h-8 w-auto" src="/img/logo/recovib_full_white.svg" alt="Recovib" />
              <img class="hidden lg:block h-8 w-auto" src="/img/logo/recovib_full_white.svg" alt="Recovib" />
            </div>
          </div>
          <div class=" sm:ml-6 flex items-center" v-if="enumerationData?.roleTypesEnum">
            <div class="hidden inline-flex items-center px-3.5 py-1.5 font-medium text-gray-200">
              <span class="bg-blue-100 text-gray-800 px-4 py-0.5 rounded-md text-xs font-medium">{{
                auth.user?.user_org?.org_display_name }} > {{ auth.user?.user_fullname }}</span>
            </div>
            <router-link v-role="enumerationData?.roleTypesEnum.SUPER_ADMIN" :to="{ name: 'configuration-users' }"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
              :title="$t('configuration.manageUser')">
              <UserGroupIcon class="block h-6 w-6 text-emerald-400" alt="users" />
            </router-link>
            <router-link v-role="enumerationData?.roleTypesEnum.SUPER_ADMIN" :to="{ name: 'configuration-metadata' }"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
              :title="$t('configuration.manageMeta')">
              <AdjustmentsIcon class="block h-6 w-6 text-yellow-400" alt="users" />
            </router-link>
            <router-link v-role="enumerationData?.roleTypesEnum.SUPER_ADMIN" :to="{ name: 'configuration-organizations' }"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
              :title="$t('configuration.manageOrg')">
              <OfficeBuildingIcon class="block h-6 w-6 text-sky-400" alt="organizations" />
            </router-link>

            <router-link v-role="enumerationData?.roleTypesEnum.ADMIN" :to="{ name: 'configuration-recipients' }"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
              :title="$t('configuration.manageRecipient')">
              <UserAddIcon  class="block h-6 w-6 text-purple-400" alt="recipients" />
            </router-link>
            <!-- <router-link
                :to="{ name: 'globalMap' }"
                class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
                :title="$t('configuration.globalMap')"
              >
                <LocationMarkerIcon  class="block h-6 w-6 text-yellow-500" alt="map" />
              </router-link> -->
            <router-link :to="{ name: 'adminIndex' }"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
              :title="$t('configuration.allMeasurement')">
              <CollectionIcon class="block h-6 w-6 text-gray-400" alt="map" />
            </router-link>

            <!-- To decomment, when the page user profile is done
              <router-link :to="{ name: 'configuration-users-profile' }"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
              :title="$t('configuration.manageProfile')">
              <IdentificationIcon class="block h-6 w-6 text-sky-200" alt="profil" />
            </router-link>
          -->

            <button @click="logout"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium"
              :title="$t(`auth.logout`)">
              <LogoutIcon class="block h-6 w-6 text-red-500/90" alt="logout" />
            </button>
          </div>
        </div>
      </div>
    </Disclosure>

    <div class="py-2 bg-gradient-to-r from-gray-50 to-gray-100">
      <main>
        <ScaleLoader v-if="ui?.isInLoadingState" />
        <div class="max-w-9xl mx-auto sm:px-6 lg:px-8 " :class="ui?.isInLoadingState ? 'isLoading' : ''">
          <slot />
        </div>
      </main>
    </div>
    <Footer></Footer>
  </div>
</template>

<style>
.isLoading {
  opacity: 0.2;
}
</style>

<style scoped>
.bg-blue-custom {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity));
}
</style>
