<template>
  <div>
    <TableData :columns="columns" :rows="tableData" />
  </div>
</template>

<script setup lang="ts">
import TableData from "@core/components/tables/elements/TableData.vue";
import { h } from "vue";
import * as _ from "lodash";
import { RowTable } from "@/index";
import { useRouter } from "vue-router";
import { useLensStore } from "@/@modules/admin/store/lensStore";
// @ts-ignore
import { createToaster } from "@meforma/vue-toaster";

const router = useRouter();
const lens = useLensStore();

const props = defineProps<{ tableData: any[]; currentUserEmail: string }>();

const toaster = createToaster({
  position: "top-right",
});

const columns = [
  {
    label: "Location ID",
    id: "location_id",
    sortable: true,
  },
  {
    label: "Name",
    id: "lensGroup_name",
    sortable: true,
  },
  {
    label: "Status",
    id: "lensGroup_status",
    sortable: true,
  },
  {
    label: "Type",
    id: "lensGroup_application_type",
    sortable: true,
  },

  {
    label: "Organization",
    id: "organization",
  },
  {
    render: (_: any, row: any) => {
      const isUserLensGroup = row.lensGroup_users?.find((user: any) => user.user_email === props.currentUserEmail) ? true : false;
      return h(
        "button",
        {
          className: `m-auto inline-block px-4 py-1 ${isUserLensGroup ? "bg-blue-200" : "bg-red-200"} rounded shadow-xs cursor-pointer ${
            isUserLensGroup ? "hover:bg-blue-500" : "hover:bg-gray-500"
          } hover:text-gray-100 text-sm`,
          onClick: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (isUserLensGroup) {
              lens.setSelectedLensItems({ cosmosId: row.cosmosId, locationId: row.location_id });
              router?.push({ name: "admin-lens-settings-lens-group-id", params: { locationId: row.location_id } });
            } else {
              toaster.info(`Please assign lens group to user`);
            }
          },
        },
        "edit"
      );
    },
  },
] as RowTable;
</script>
