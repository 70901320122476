export const genericChartOption = (location_id = "error") => ({
  id: `${location_id}-metadata-generic-statistics-options`,
  partitionKey: "metadata",
  doc_type: "generic_options",
  lens_id: `${location_id}-generic-stat-4`,
  lens_name: "Statistics",
  data_type: "generic-statistics",
  options: [
    {
      id: "c723c296-8280-4adf-a8b1-3d93646dfd4e",
      allDataNames: ["max_data"],
      customVariables: {
        max_data: ["VC_0"],
      },
      customCharts: {
        x_range: [0, 0],
        y_range: [-0.01, 0.01],
        x_label: "Time",

        y_label: "Acceleration",
        title: "Stat title (max)",
        chart_index: 1,
        physical_quantity: "acceleration",
      },
    },
  ],
});


// WIP: create a generic chart option for din41503
export const genericChartDin41503 = (location_id = "error") => ({
  id: `${location_id}-metadata-generic-statistics-options`,
  partitionKey: "metadata",
  doc_type: "generic_options",
  lens_id: `${location_id}-generic-stat-4`,
  lens_name: "Statistics",
  data_type: "generic-statistics",
  options: [
    {
      id: "c723c296-8280-4adf-a8b1-3d93646dfd4e",
      allDataNames: ["max_data"],
      customVariables: {
        max_data: ["VC_0"],
      },
      customCharts: {
        x_range: [0, 0],
        y_range: [-0.01, 0.01],
        x_label: "Time",

        y_label: "Acceleration",
        title: "Stat title (max)",
        chart_index: 1,
        physical_quantity: "acceleration",
      },
    },
  ],
});
