import { formatDataForCombinedGenericLineTypes } from "./shared/generic/formatDataForCombinedGenericLineTypes";
import { multipleStreamCombinedDataFormat } from "./shared/generic/multipleStreamCombinedDataFormat";

/**
 * Formatteur de données génériques pour les graphiques.
 *
 * @param data Les données à formater.
 * @param lensInfos Les informations sur les lens.
 * @param customGenericConfig La configuration personnalisée pour les données génériques.
 * @returns Les données formatées pour les types de lignes génériques combinées.
 */
export function graphListFormatterGenericStats(data: any, lensInfos: any, customGenericConfig: any) {
  const fDataCombined = multipleStreamCombinedDataFormat(data, lensInfos, customGenericConfig.allDataNames);
  return formatDataForCombinedGenericLineTypes(fDataCombined, customGenericConfig);


  // NOTE : disable for now
  // const distincts = allDataNames
  //   .map((name: string) => {
  //     const fData = multipleStreamDataFormat(data, lensInfos, name);
  //     return formatDataForAllOthersGenericLineTypes(fData);
  //   })
  //   .flat(1)
  //   .filter((el) => el !== undefined);
  // allGraphs = [combined, ...distincts].flat(1);
}
