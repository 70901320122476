export const fft_variables = [
  {
    vc_name: "PV-X-FFT",
    disp_name: "PV-X-FFT",
    sensor_sn: "030420391",
    sensor_type: "IAC-STD-I-6G",
    physical_quantity: "velocity",
  },
  {
    vc_name: "PV-Y-FFT",
    disp_name: "PV-Y-FFT",
    sensor_sn: "030420391",
    sensor_type: "IAC-STD-I-6G",
    physical_quantity: "velocity",
  },
  {
    vc_name: "PV-Z-FFT",
    disp_name: "PV-Z-FFT",
    sensor_sn: "030420391",
    sensor_type: "IAC-STD-I-6G",
    physical_quantity: "velocity",
  },
];
