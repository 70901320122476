<template>
  <div class="my-4">
    <component
      v-for="formInput in formSchema"
      class="my-4"
      :is="renderInput(formInput)"
      :key="formInput.id"
      :fieldObj="formInput"
      @edited-input="recomposeValue($event)"
    ></component>
  </div>
</template>

<script setup lang="ts">
import { composeObjectToValidForm, composeObjectToRawForm } from "./utils/inputHelpers";
import TextInput from "./inputs/TextInput.vue";
import NumberInput from "./inputs/NumberInput.vue";
import EmailInput from "./inputs/EmailInput.vue";
import CheckboxInput from "./inputs/CheckboxInput.vue";
import RadioInput from "./inputs/RadioInput.vue";
import PasswordInput from "./inputs/PasswordInput.vue";
import ArrayNumberInput from "./inputs/ArrayNumberInput.vue";
import PhoneNumberInput from "./inputs/PhoneNumberInput.vue";

import { watch, ref, onMounted } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { FieldObject } from "@/index";

const props = defineProps<{
  formSchema: FieldObject[];
}>();

const emit = defineEmits(["form-value-updated", "raw-form-value-updated"]);

const setInitialValue = ref(false);
const formValue = ref({});
const isWatchingRoot = ref(false);

/**
 * Recompose la valeur du formulaire en mettant à jour les champs modifiés.
 * @param {Object|null} $event - L'événement contenant les informations du champ modifié.
 */
const recomposeValue = ($event = null) => {
  if ($event !== null) {
    // @ts-ignore
    formValue.value[$event.id] = $event;
  }
  const validForm = composeObjectToValidForm(formValue.value);
  const rawForm = composeObjectToRawForm(formValue.value);
  emit("raw-form-value-updated", rawForm);
  emit("form-value-updated", validForm);
};

/**
 * Initialise les valeurs du formulaire à partir des propriétés fournies.
 *
 * @param {FieldObject[]} schema - Le schéma des champs du formulaire.
 */
const initialValueFromProps = (schema: FieldObject[]) => {
  schema?.forEach((field) => {
    if (field) {
      formValue.value = Object.assign(formValue.value, { [field.id]: field });
    }
  });
};

const renderInput = (formInput: FieldObject) => {
  if (formInput.type === "input_text") return TextInput;
  if (formInput.type === "input_password") return PasswordInput;
  if (formInput.type === "input_email") return EmailInput;
  if (formInput.type === "input_phone") return PhoneNumberInput;
  if (formInput.type === "input_number") return NumberInput;
  if (formInput.type === "input_checkbox") return CheckboxInput;
  if (formInput.type === "input_radio") return RadioInput;
  if (formInput.type === "input_array_number") return ArrayNumberInput;
};

watch([setInitialValue, props.formSchema], (val, oldVal) => {
  if (val && val != oldVal && setInitialValue.value === false) {
    // @ts-ignore
    initialValueFromProps(val);
    setInitialValue.value = true;
    useDebounceFn((term) => {
      recomposeValue();
    }, 500);
  }
});

onMounted(() => {
  initialValueFromProps(props.formSchema);
  recomposeValue();
});
</script>

<style scoped></style>
