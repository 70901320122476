import Configuration from "./Configuration.vue";
import MetadataConf from "./metadata/MetadataConf.vue";
import MetadataConfAdd from "./metadata/MetadataConfAdd.vue";
import Organization from "./organizations/Organization.vue";
import Recipient from "./recipients/Recipient.vue";
import User from "./users/User.vue";
import UserProfile from "./users/UserProfile.vue";

const configurationRoutes = [
  {
    path: `/configuration`,
    name: `configuration`,
    component: Configuration,
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/configuration/users`,
    name: `configuration-users`,
    component: User,
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/configuration/users/profile`,
    name: `configuration-users-profile`,
    component: UserProfile,
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/configuration/organizations`,
    name: `configuration-organizations`,
    component: Organization,
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/configuration/recipients`,
    name: `configuration-recipients`,
    component: Recipient,
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/configuration/metadata`,
    name: `configuration-metadata`,
    component: MetadataConf,
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
  {
    path: `/configuration/metadata/add`,
    name: `configuration-metadata-add`,
    component: MetadataConfAdd,
    meta: { requiresAuth: true, layout: null, isHome: false },
  },
];

export default configurationRoutes;
