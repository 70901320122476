<script setup lang="ts">
import moment from "moment-timezone";
import { Ref, computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { useMetadataStore } from "@/@modules/admin/store/metadataStore";

import { useConfigStore } from "@/config/configStore";
import { requestModeler } from "@/config/requestModeler";
import { QueryParam } from "@/index";

import StatMultiChartsSwitcher from "@core/charts/StatMultiChartsSwitcher.vue";
import LensItemsPageHeader from "@core/components/banner/headers/LensItemsPageHeader.vue";
import BaseCalendar from "@core/components/calendar/BaseCalendar.vue";
import GenericNotificationTable from "@core/tables/notifications/GenericNotificationTable.vue";

import { useAlarmRuleStore } from "@/@modules/admin/store/alarmRuleStore";
import { useGraphStore } from "@modules/admin/store/graphStore";
import { useLensGroupStore } from "@modules/admin/store/lensGroupStore";
import { useLensStore } from "@modules/admin/store/lensStore";

import { useDateTimeStore } from "@use/useDatetime";
import { v4 as uuidv4 } from "uuid";
import { storeToRefs } from "pinia";
const defaultTz = import.meta.env.VITE_DEFAULT_TIMEZONE;

const router = useRouter();
const lensGroup = useLensGroupStore();
const lens = useLensStore();
const datetime = useDateTimeStore();
const configs = useConfigStore().enumerationData;
const graph = useGraphStore();
const metadata = useMetadataStore();
const alarm = useAlarmRuleStore();
const { currentAlarmStatsLog } = storeToRefs(alarm);

const notifications = ref([] as any[]);
const notificationTableId = ref("00");

const isGenericStat = computed(() => (lensGroup.selectedLensGroup?.lensGroup_application_type === "generic" ? true : false));
const getStatsElementsFromCalendar = computed(() => {
  return lens.selectedLensItems?.lens_queries?.find((el) => el.query_type === configs.cosmosQueryTypeEnum.STATS_DETAILS);
});

const genericLensVariables = computed(() => metadata.selectedMetadata.lens_variables);

const locationPoint = computed(
  () => router.currentRoute.value.params.locationId || lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id
) as Ref<string>;

const deviceTz = computed(() => lensGroup.selectedLensGroup?.lensGroup_data.metadata.timezone_javascript || defaultTz);

// @ts-ignore
const alarmStatsLog = computed(() => currentAlarmStatsLog.value?.logs.sort((a: any, b: any) => moment(b.date).diff(moment(a.date))));

/**
 * Cette fonction recharge les données en fonction de la sélection actuelle.
 * Elle effectue les étapes suivantes :
 * 1. Sélectionne les groupes de lens correspondants à un point de localisation spécifié.
 * 2. Sélectionne les éléments de lens spécifiés en fonction de l'ID du cosmos et de l'ID de localisation.
 * 3. Si la statistique est générique, récupère les statistiques de l'alarme pour le point de localisation spécifié.
 * 4. Met à jour les notifications avec les statistiques de l'alarme récupérées.
 * 5. (Optionnel) Récupère tous les groupes de lens de l'utilisateur.
 */
const reload = async () => {
  lensGroup.setSelectedLensGroups(locationPoint.value as string);
  lens.setSelectedLensItems({ cosmosId: router.currentRoute.value.params.cosmosId, locationId: locationPoint.value as string });
  if (isGenericStat.value) {
    await alarm.fetchAlarmStatsLog(locationPoint.value);
    // @ts-ignore
    notifications.value = alarmStatsLog.value;
  }
  // await lensGroup.fetchUserAllLensGroup();
};

/**
 * Cette fonction filtre les journaux de notification en fonction des paramètres spécifiés.
 * Elle effectue les étapes suivantes :
 * 1. Génère un nouvel identifiant de table pour la notification.
 * 2. Filtre les statistiques de l'alarme en fonction de la date de début, de la date de fin et de l'indicateur "aujourd'hui".
 * 3. Met à jour les notifications avec les statistiques de l'alarme filtrées.
 */
const filterNotificationLogs = ({ startingDate, endDate, isToday }: { startingDate: string; endDate: string; isToday: boolean }) => {
  notificationTableId.value = uuidv4();

  notifications.value = alarmStatsLog.value?.filter((el: any) => {
    if (isToday) {
      return moment(el.date).isSameOrAfter(startingDate);
    } else {
      return moment(el.date).isBetween(startingDate, endDate);
    }
  });
  // .sort((a: any, b: any) => moment(b.date).diff(moment(a.date)));
};

/**
 * Cette fonction récupère les éléments graphiques à partir de Cosmos en fonction de l'événement spécifié.
 * Elle effectue les étapes suivantes :
 * 1. Récupère le formatteur de données de l'élément de lens sélectionné.
 * 2. Récupère la partitionKey à partir dulensGroups sélectionné.
 * 3. Calcule la date de début et la date de fin à partir de la date spécifiée et du fuseau horaire dulensGroups sélectionné.
 * 4. Prépare les informations de lens en fonction du formatteur de données.
 * 5. Si l'indicateur "getStatsElementsFromCalendar" est activé et que la partitionKey et le formatteur sont disponibles :
 *    - Modifie les paramètres de la requête avec les valeurs actuelles.
 *    - Filtre les journaux de notification en fonction des paramètres spécifiés.
 *    - Sélectionne les statistiques génériques de lens pour le graphique actuel.
 */
const fetchGraphElementsFromCosmos = async ($event: any) => {
  const formatter = lens.selectedLensItems?.data_type;
  const partitionKey = lensGroup.selectedLensGroup?.lensGroup_data.metadata.location_id;

  const { startingDate, endDate } = datetime.helpers.startEnd24hourFromDate(
    $event.date,
    lensGroup.selectedLensGroup?.lensGroup_data.metadata.timezone_javascript
  );

  let lensInfos;
  lensInfos = formatter === "statistics" ? lens.selectedLensItems : Object.assign({}, lens.selectedLensItems, { lens_variables: genericLensVariables.value });

  if (getStatsElementsFromCalendar.value && partitionKey && formatter) {
    // on modifies les paramètres dans la requete venant de l'API avec les valeurs courantes
    const paramsList = [
      {
        key: configs.cosmosQueryParamsEnum.STREAM_TYPE,
        value: "peak",
      },
      {
        key: configs.cosmosQueryParamsEnum.STARTING_DATE,
        value: startingDate,
      },
      {
        key: configs.cosmosQueryParamsEnum.END_DATE,
        value: endDate,
      },
      // ici TLM name est utilisé 4 fois alors ont le set 4 fois
      // TODO: ne setter qu'une seule fois
      {
        key: configs.cosmosQueryParamsEnum.TLM_NAME,
        value: lens.selectedLensItems?.lens_id,
      },
      {
        key: configs.cosmosQueryParamsEnum.TLM_NAME,
        value: lens.selectedLensItems?.lens_id,
      },
      {
        key: configs.cosmosQueryParamsEnum.TLM_NAME,
        value: lens.selectedLensItems?.lens_id,
      },
      {
        key: configs.cosmosQueryParamsEnum.TLM_NAME,
        value: lens.selectedLensItems?.lens_id,
      },
    ] as QueryParam[];
    //  On recompose la requete avec les vraies valeurs

    const query = requestModeler(paramsList, getStatsElementsFromCalendar.value?.query_content);

    let isToday = false;
    if (moment(startingDate).isSameOrAfter(datetime.helpers.today)) {
      isToday = true;
    }
    if (isGenericStat.value) {
      filterNotificationLogs({ startingDate, endDate, isToday });
    }

    graph.setSelectedLensStatsGenericMultipleGraphCurrent({
      query,
      lensInfos,
      partitionKey,
      formatter,
      isToday,
    });
  }
};

//-------------------------------------------------------------

onMounted(async () => {
  await reload();
});
</script>

<template>
  <div>
    <LensItemsPageHeader :imageName="'statistics'" @refech-all="reload" />
    <BaseCalendar
      v-if="lensGroup.selectedLensGroup && lens.selectedLensItems?.lens_id && locationPoint"
      :key="locationPoint + notifications?.length"
      :locationPoint="locationPoint"
      :deviceTz="deviceTz"
      :dataType="lens.selectedLensItems?.data_type"
      @day-click="fetchGraphElementsFromCosmos($event)"
    />
    <GenericNotificationTable  :key="locationPoint + notifications?.length" v-if="isGenericStat" :notifications="notifications || alarmStatsLog" />

    <StatMultiChartsSwitcher :chartList="graph.graphCurrent" />
  </div>
</template>
