/**
 * Crée un objet de logs génériques pour une alarme statistique.
 *
 * @param location_id L'identifiant de l'emplacement (par défaut: "error").
 * @returns L'objet de logs génériques pour l'alarme statistique.
 */
export const genericStatLogs = (location_id = "error") => ({
  id: `${location_id}-generic-stat-alarm-logs`,
  partitionKey: "metadata",
  logs: [],
});
/**
 * Définit les règles génériques pour une alarme statistique.
 *
 * @param location_id L'identifiant de l'emplacement (par défaut: "error").
 * @returns Les règles génériques pour une alarme statistique.
 */
export const genericStatRules = (location_id = "error") => ({
  id: `${location_id}-generic-stat-alarm-rules`,
  partitionKey: "metadata",
  rules: [],
});
