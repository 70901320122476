import * as _ from "lodash";

import { computed, onMounted, ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";

import entityStateManager from "@use/entity";
import { helpers } from "@use/libs/helpers";
import { metadataOptionStore } from "@/@modules/configuration/store/metadataOptionStore";
import { useAPI } from "@use/useAPI";
import { useAuthStore } from "@/@modules/auth/authStore";
import { useGraphStore } from "./graphStore";
import { useLensGroupStore } from "./lensGroupStore";
import { useLensStore } from "./lensStore";
import { useMetadataStore } from "@/@modules/admin/store/metadataStore";

export const useRecipientStore = defineStore(
  "recipient",
  () => {
    const lensGroupStore = useLensGroupStore();
    const router = useRouter();
    const route = useRoute();
    const manager = entityStateManager("lens_id");
    const metadata = useMetadataStore();
    const API = useAPI();
    const auth = useAuthStore();

    const metadataOptions = metadataOptionStore();

    const { selectedLensGroup } = storeToRefs(lensGroupStore);
    const recipients = ref([]);

    const orgName = computed(() => auth.user.user_org && auth.user.user_org.org_name);
    /**
     * Met à jour ou ajoute un destinataire à la liste des destinataires.
     *
     * @param clientData - Les données du client.
     * @param recipient - Le destinataire à mettre à jour ou ajouter.
     * @param remove - Indique si le destinataire doit être supprimé s'il existe déjà dans la liste.
     * @returns Les données du client mises à jour.
     */
    const _updateOrAddToRecipientList = (clientData: any, recipient: any, remove = true) => {
      // Find an element to recipientList if element found then update it otherwise add it to recipientList
      const index = _.findIndex(clientData.recipients, { email: recipient.email });

      if (index !== -1) {
        if (remove) {
          clientData.recipients = clientData.recipients?.filter((item: any) => item.email !== recipient.email);
        } else {
          clientData.recipients[index] = recipient;
        }
      } else {
        clientData.recipients.push(recipient);
      }
      return clientData;
    };

    /**
     * Met à jour la liste des destinataires du client.
     *
     * @param recipient - Le destinataire à ajouter ou supprimer.
     * @param remove - Indique si le destinataire doit être supprimé de la liste (par défaut: false).
     * @returns Une Promise qui se résout avec la liste mise à jour des destinataires du client.
     */
    const updateClientRecipientList = async (recipient: any, remove = false) => {
      if (recipient.email === undefined) return;
      const clientApplication = await metadataOptions.fetchClientApplicationOption(orgName.value);
      const _clientApplication = clientApplication && (_updateOrAddToRecipientList(clientApplication.clientData, recipient, remove) as {});

      const response = await API.postData("configs/updateCosmosData", _clientApplication);
      return await metadataOptions.fetchClientApplicationOption(orgName.value);
    };

    const removeRecipientList = async (recipient: any) => {
      return await updateClientRecipientList(recipient, true);
    };

    /**
     * Récupère la liste des destinataires.
     * Cette fonction effectue une requête asynchrone pour récupérer la liste des destinataires en fonction de l'organisation spécifiée.
     * Si le nom de l'organisation est fourni, la fonction effectue une requête pour récupérer les options d'application client correspondantes,
     * puis met à jour la liste des destinataires avec les données récupérées.
     */
    const fetchRecipientList = async () => {
      metadata.fetchMasterConfigurations();
      if (orgName.value) {
        const resp = await metadataOptions.fetchClientApplicationOption(orgName.value);
        recipients.value = resp?.clientData?.recipients;
      }
    };

    watch(
      () => metadataOptions.clientApplication?.clientData?.recipients,
      (newValue, oldValue) => {
        recipients.value = metadataOptions.clientApplication?.clientData?.recipients;
      }
    );

    onMounted(async () => {
      await fetchRecipientList();
    });

    return {
      recipients,
      updateClientRecipientList,
      removeRecipientList,
      fetchRecipientList,
    };
  },
  {
    persist: true,
  }
);
