<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script lang="js">
import { useUiStore } from "@use/uiStore";
import { mapStores } from "pinia";
import AdminLayout from "./@core/layouts/AdminLayout.vue";
import HomeLayout from "./@core/layouts/HomeLayout.vue";

const defaultLayout = "default-layout";

import axios from "axios";

export default {
  components: {
    HomeLayout,
    AdminLayout,
  },
  computed: {
    layout() {
      return this.$route?.meta?.layout ? HomeLayout : AdminLayout;
    },
    ...mapStores(useUiStore),
  },
  beforeMount() {
    if (axios) {

      this.uiStore.setColdStartState(true);
      axios.get(`${import.meta.env.VITE_BASE_URL}/auth/start`, {
            headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
            },
          }).then((res) => {
        if (res.status === 200) {
          this.uiStore.setColdStartState(false);
        }
      });
    }
  },
};
</script>
