import * as _ from "lodash";

export const splitDs = (d: string, asType = undefined) => {
  const ds = (d || "").split("|");
  return asType ? ds.map(asType) : ds;
};

/**
 * Formate une liste de flux en un objet.
 *
 * @param list - La liste de flux à formater.
 * @returns L'objet formaté.
 */
export const formatStreamListToObject = (list = []) => {
  return list.filter(el => !_.isEmpty(el)).reduce((obj: any, item: any) => {
    const { time, ...rest } = item;
    return {
      time,
      ...obj,
      ...rest,
    };
  }, {});
};

/**
 * Convertit une liste en un objet en utilisant les identifiants des éléments comme clés.
 * @param list - La liste à convertir en objet.
 * @returns L'objet résultant de la conversion.
 */
export const listToObject = (list = []) => {
  return list.reduce((obj: any, item: any) => {
    const { id, ...rest } = item;
    return {
      ...obj,
      [id]: item,
    };
  }, {});
};


export const createGenericDataName = (el: string, dataName:string) => `${el}__${dataName}`;
